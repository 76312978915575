import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuClass } from 'src/app/object/headMenuClass';
import { FontAwesomeService } from 'src/app/services/font-awesome.service';
import { GeneralTemplateService } from 'src/app/services/general-template.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-predefined-mobile-menu',
  templateUrl: './predefined-mobile-menu.component.html',
  styleUrls: ['./predefined-mobile-menu.component.scss']
})
export class PredefinedMobileMenuComponent implements OnInit {
  @Input() template:string;
  @Input() top:number;
  public topMenu: Array<MenuClass> = [];
  constructor(
    public _fontAwesome: FontAwesomeService,
    private _generalTemplateService:GeneralTemplateService,
    private _generalService: GeneralService,
    private _router:Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this._generalTemplateService.returnMenu().then((value: Array<MenuClass>) => {
      this.topMenu = value;
    })
  }
  public menuState = false;
  public menuStateDelay = false;
  toggleMenu() {
    this.menuState = !this.menuState;
  }

  public clickedElement(id): void {
    const element = this._generalTemplateService.returnMenuElementWithID(id);
    if(element.children.length > 0){
      return
    }
    switch (element.mode) {
      case 'href':
        this.document.location.href = element.menuModeValue;
        break;
      case 'download':
        break;
      case 'subPage':
        this._router.navigate(['/'+this.template+'/subPage',element.menuModeValue]);
        break;
      case 'article':
        this._router.navigate(['/'+this.template+'/article',element.menuModeValue]);
        break;

      default:
        break;
    }
  }
private returnMenuElementWithID(id): MenuClass {
    var tmp;
    this._generalService.headerMenu.forEach((head: MenuClass) => {
      if (head.id == id) {
        tmp = head;
      }
      head.children.forEach((children: MenuClass) => {
        if (children.id == id) {
          tmp = children;
        }
      });
    });
    return tmp;
  }
  public length(a){
    return a.length;
  }
  public FakeMouseClick(event){
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
          document.getElementById(event.target.id).click();
        break;
      }
    } //end switch
    return true;
  }

}
