
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchInList"
})
export class searchInList implements PipeTransform {
  transform(items: any[], searchText: string, valueName: string): any[] {
    if(!items) {
      return [];
    }
    if(!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return it[valueName].toLowerCase().includes(searchText);
    });
   }
}
