import { Pipe, PipeTransform } from '@angular/core';
import { PipeService } from './pipe.service';

@Pipe({
  name: 'safeImage'
})
export class SafeImagePipe implements PipeTransform {
  constructor(private _pipeService: PipeService){}
  transform(value: any) {
   value += '?' + '0' + this._pipeService.randomStaticNumber.value;
    return value;
}


}

