import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';

@Component({
  selector: 'app-gallery-image-big-view',
  templateUrl: './gallery-image-big-view.component.html',
  styleUrls: ['./gallery-image-big-view.component.scss']
})
export class GalleryImageBigViewComponent implements OnInit {

  constructor(
    public _activeModal :NgbActiveModal,
    public _galleryService: ImagesStorageService
  ) { }

  ngOnInit(): void {
  }

}
