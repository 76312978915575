
<img src="assets/icon/Ribbon.png" class="Ribbon" *ngIf="greyScale&& adminPanelActive == false" aria-label="Czarna kokarda - symbol żałoby">
 <div class="loadPagePanel" *ngIf="loader == true || color != true" role="presentation">


  <div class="loader"></div>
</div>

<div aria-hidden="true" class="pageDisableAlert" *ngIf="PageDisable && PageDisableRouter">
Strona wyłączona, dostępna tylko dla osób zalogowach !!!
</div>


<!--<div class="WCAGPanel_button" (mouseenter)="wheelChariMouseenter(true)" (mouseleave)="wheelChariMouseenter(false)" role="button" aria-label="Przełącznik wyswietlania panelu opcji wspomagających"-->
<!--   [tabindex]="12"-->
<!--  id="WCAG_button_init" (keypress)="FakeMouseClick($event)"  (click)=" WCAGPanelSwitch()" *ngIf="WCAGPanelDisplay">-->
<!--  <a role="presentation">-->
<!--    <fa-icon class="Wheelchair" [icon]="_fontAwesome.faWheelchair" [ngClass]="{'WheelchairHide':ArrowSwitch}"></fa-icon>-->
<!--  </a>-->

<!--</div>-->

<!--<div class="WCAGPanel WCAG_c_width_minContent" *ngIf="WCAGPanelDisplay"-->
<!--  [ngClass]="{'WCAGPanel_on' :WCAGPanelStatus == 'true','WCAGPanel_off' :WCAGPanelStatus == 'false'}">-->
<!--  <p><b class="WCAG_c_text  WCAG">Dostępność:</b></p>-->
<!--  <hr>-->

<!--  <table class="WCAG_c_text"   >-->
<!--    <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faFont" class="font1"></fa-icon>&nbsp;&nbsp;-->
<!--      </td>-->
<!--      <td  >-->
<!--        <a [tabindex]="13" id="WCAG_button_1" (keypress)="FakeMouseClick($event)"  (click)="wcag('font-')" class="WCAG">Pomniejsz czcionkę</a>-->
<!--      </td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faFont" class="font2"></fa-icon>-->
<!--      </td>-->
<!--      <td   >-->
<!--        <a [tabindex]="14" id="WCAG_button_2"  (keypress)="FakeMouseClick($event)"  (click)="wcag('font+')" class="WCAG">Powiększ czcionkę</a>-->
<!--      </td>-->
<!--    </tr>-->
<!--   &lt;!&ndash;  <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faFont" class="font2"></fa-icon>-->
<!--      </td>-->
<!--      <td   [tabindex]="5" id="WCAG_button_3" (focus)="GetFocus()" (focusout)="LostFocus()"  (keypress)="FakeMouseClick($event)" (mouseup)="wcag('customizedfont')"><span class="WCAG" >Czcniosnka dostosowana</span></td>-->
<!--    </tr> &ndash;&gt;-->
<!--    <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faFont" class="font2"></fa-icon>-->
<!--      </td>-->
<!--      <td >-->
<!--        <a [tabindex]="16" id="WCAG_button_4"   (keypress)="FakeMouseClick($event)" (click)="wcag('linksUnderlined')" class="WCAG">Podkreślone linki</a>-->
<!--      </td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faAdjust" class="contrastIcon"></fa-icon>-->
<!--      </td>-->
<!--      <td >-->
<!--        <a [tabindex]="17" id="WCAG_button_5"   (keypress)="FakeMouseClick($event)" (click)="wcag('highContrast')" class="WCAG">Zmiana kontrastu</a>-->
<!--      </td>-->
<!--    </tr>-->
<!--    &lt;!&ndash; <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faAdjust"></fa-icon>-->
<!--      </td>-->
<!--      <td  [tabindex]="8" id="WCAG_button_6" (focus)="GetFocus()" (focusout)="LostFocus()"  (keypress)="FakeMouseClick($event)" (mouseup)="wcag('blackWhite')"><span  class="WCAG">Tryb czarno-biały</span></td>-->
<!--    </tr> &ndash;&gt;-->
<!--    <tr>-->
<!--      <td>-->
<!--        <fa-icon [icon]="_fontAwesome.faSync"></fa-icon>-->
<!--      </td>-->
<!--      <td >-->
<!--        <a  [tabindex]="19" id="WCAG_button_7"    (keypress)="FakeMouseClick($event)" (click)="wcag()" class="WCAG" >Domyślne ust.</a>-->
<!--        </td>-->
<!--    </tr>-->
<!--  </table>-->
<!--</div>-->

<div class="cookiesAlert WCAG_con_black WCAG_con_Title WCAG_con_Border" [ngClass]="{'cookisHidden':cookiesSwitchHidden}" [ngStyle]="{'display':cookiesSwitch}">
  <span class="WCAG">Ta strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza, że zgadzasz się z ich użyciem&nbsp;&nbsp;&nbsp;</span>
  <input type=button class="btn btn-dark" value="Akceptuję" (click)="AcceptCookies()" aria-label="Zgoda na korzystanie z ciasteczek przez stronę internetową">
</div>

<div class="mobilePageTitle" *ngIf="titleEnabled">
  <div *ngIf="greyScale" style="width:150px;float: left;height: 60px"></div>
  <img
  *ngIf="ImageStorageService.galleryStorage.value.returnImage(_clientDataService.settings_.herbImage.value) != null && !greyScale"
  [src]="ImageStorageService.galleryStorage.value.returnImage(_clientDataService.settings_.herbImage.value).Qsrc(40)"
  [alt]="ImageStorageService.galleryStorage.value.returnImage(_clientDataService.settings_.herbImage.value).alt"
  [title]="ImageStorageService.galleryStorage.value.returnImage(_clientDataService.settings_.herbImage.value).title" class="herb">
<h1 [innerHTML]="_clientDataService.settings_.schoolName.value"></h1>

</div>


<!--<div class="link_tiles_panel grey" *ngIf="LinkTilesPanelDisplay && LinkTilesPanelInit && loader == false">-->
<!--  <button-->
<!--    tabindex="0"-->
<!--    (click)="toggleLinkTiles()"-->
<!--    aria-label="Wysuń kafelki boczne"-->
<!--    class="displayButton btn"-->
<!--  >-->
<!--    <fa-icon [icon]="_fontAwesome.faBars"></fa-icon>-->
<!--  </button>-->
<!--    <p class="link_tiles_panel_text">-->
<!--      Kafelki:-->
<!--    </p>-->

<!--  <ng-container *ngFor="let data of linkTiles | sort: 'position' let i = index" class="WCAG_con_black">-->
<!--      <section class="link_tiles WCAG_con_black"  [ngClass]="{'linkTilesHidden':LinkTilesSwitch}">-->
<!--        <a [href]="data.href" class="title_text WCAG_con_black WCAG_con_white " [id]="'linkTiles_'+i" >-->
<!--          <p class="WCAG WCAG_con_white" [innerHTML]="data.title"></p>-->
<!--        </a>-->
<!--        <div class="title_image WCAG_con_black" (click)="setFocus('linkTiles_'+i)">-->
<!--          <img-->

<!--          [src]="ImageStorageService.galleryStorage.value.returnImage(data.image_id).src"-->
<!--           [alt]="ImageStorageService.galleryStorage.value.returnImage(data.image_id).alt"-->
<!--           [title]="ImageStorageService.galleryStorage.value.returnImage(data.image_id).title">-->
<!--        </div>-->
<!--      </section>-->
<!--  </ng-container>-->
<!--</div>-->


<div class="WCAG_con_black grey" style="float: left; width: 100%; height:auto;">
  <router-outlet></router-outlet>
</div>
