import { NgModule } from '@angular/core';
import { ArticlePipe } from './pipes/article.pipe';
import { ArticleSortPipe } from './pipes/articleSort.pipe';
import { ImageNamePipe } from './pipes/imageName.pipe';
import { ImagePathPipe } from './pipes/imagePath.pipe';
import { ImageSplitPipe } from './pipes/imagesSplit.pipe';
import { LinkRepairPipe } from './pipes/linkRepair.pipe';
import { MathPipe } from './pipes/math.pipe';
import { MaxWordsSizePipe } from './pipes/maxWordsSize';
import { SafeImagePipe } from './pipes/safe_image.pipe';
import { SafeVariablePipe } from './pipes/safe_variable.pipe';
import { saveBehaviorSubjectPipe } from './pipes/saveBehaviorSubject.pipe';
import { SizePipe } from './pipes/size.pipe';
import { ArraySortPipe } from './pipes/sort.pipe';
import { TimePipe } from './pipes/time.pipe';
import { RepairLastCharPipe } from './pipes/repairLastChar.pipe';
@NgModule({
  declarations: [
    ArticleSortPipe,
    MathPipe,
    SizePipe,
    ArraySortPipe,
    SafeVariablePipe,
    ArticlePipe,
    MaxWordsSizePipe,
    saveBehaviorSubjectPipe,
    ImagePathPipe,
    SafeImagePipe,
    ImageNamePipe,
    LinkRepairPipe,
    TimePipe,
    ImageSplitPipe,
    RepairLastCharPipe
  ],
  imports: [
  ],
  entryComponents: [
  ],
  exports: [
    RepairLastCharPipe,
    ArticleSortPipe,
    MathPipe,
    SizePipe,
    ArraySortPipe,
    SafeVariablePipe,
    ArticlePipe,
    MaxWordsSizePipe,
    saveBehaviorSubjectPipe,
    ImagePathPipe,
    SafeImagePipe,
    ImageNamePipe,
    LinkRepairPipe,
    TimePipe,
    ImageSplitPipe,
  ],
  providers: []
})
export class PipeSharedModule {



}
