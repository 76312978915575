export class TemplateRouterClass {
  public template:string;
  public article:string;
  public pageMap:string;
  public articleCategory:string;
  public galleryList:string;
  public gallery:string;
  public subPage:string;
  public fastScrollID:string = "fastScrool";
  ReturnArticleCategory(){
    return this.template+'/'+this.articleCategory;
  }
  ReturnArticle(){
    return this.template+'/'+this.article;
  }
  ReturnGalleryList(){
    return this.template+'/'+this.galleryList;
  }
  ReturnGallery(){
    return this.template+'/'+this.gallery;
  }
  ReturnSubPage(){
    return this.template+'/'+this.subPage;
  }
  ReturnPageMap(){
    return this.template+'/'+this.pageMap;
  }
}
