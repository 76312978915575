// tslint:disable: variable-name
import { SecurityService } from 'src/app/services/security/security.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { FennecApiClass } from 'src/app/object/fennecApiClass';
import Swal from 'sweetalert2';
import { ClassTree } from 'src/app/object/treeClass';
import { IActionMapping, ITreeOptions, TREE_ACTIONS } from '@circlon/angular-tree-component';
import { ImagesClass } from 'src/app/object/gallery.class';
import { dynamicTreeClass } from 'src/app/object/dynamicTreeStructureClass';
import { rejects } from 'assert';

const actionMapping: IActionMapping = {
  mouse: {
    dblClick: (tree, node, $event) => {
      if (node.hasChildren) {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
    }
  }
};



@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  //galery settings
  public imageSettingDataBaseID = 0;


  static instance: GalleryService;
  public images = new BehaviorSubject<Array<ImagesClass>>(null);
  public images_size = new BehaviorSubject<number>(null);
  public selectedFolderPath_ = new BehaviorSubject<string>('');
  // tslint:disable-next-line: max-line-length
  public multiImagesUploadStatus_ = new BehaviorSubject<boolean>(false); // status powodzenia ostatniego pakietu zdjęć, resetowane przy dopdawniau nowych zdjęć. Cały progressbar na zielono
  public galleryWorkMode = 'default'; // getID
  public galleryLastSelectedImage_ = new BehaviorSubject<ImagesClass>(null); // id wybranego zdjęcia w trybie getID
  public galleryLastSelectedMultiImageArray_ = new BehaviorSubject<Array<ImagesClass>>([]); // obiekty wybranych zdjęć
  public galleryLastSelectedMultiImageIDInitValue: Array<any> = []; // id wybranych zdjęć
  public galleryLastSelectedMultiImageIDArrayENDBUTTTON_ = new BehaviorSubject<boolean>(false); // zmiana na true powoduje zamkniecie okna
  private imagesInit = false;
  public imageArray = [];
  file: File = null;

  public tree = new dynamicTreeClass('image');


  constructor(
    private _apiService: ApiService,
    private _securityService: SecurityService
  ) {

    this.images.subscribe((data: Array<ImagesClass>) => {
      let tmpsize = 0;
      if (data != null) {
        data.forEach((element: ImagesClass) => {
          tmpsize += Number(element.image_size);
        });
        this.images_size.next(tmpsize);
      }
    });
    GalleryService.instance = this;
  }
  public node2 = new BehaviorSubject([]);

  //! posiada wyciek pamięcie, zdjęcia się dodają w nieskończonosć gdy jest włączony podgląd
  public nodes = [];

  // multi image UPLOAD
  public ImageUploadArray_ = new BehaviorSubject<Array<File>>([]);
  public ImageUploadArrayLastUploadSize_ = new BehaviorSubject<number>(0);
  private ImageUploadArrayPromiseArray = [];
  private firstImage = true; // wskażnik służący do jednorazowego wyświetlenie komunikatu o rozpoczęciu wysyłania zdjęć


  public MultiImageButtonStartUplaod_ = new BehaviorSubject<boolean>(true);
  public MultiImageButtonReturnUpload_ = new BehaviorSubject<boolean>(false);
  public MultiImageButtonPauseUpload_ = new BehaviorSubject<boolean>(false);
  private MultiImageStopUpload = false;
  public MultiImageButtonResetUpload_ = new BehaviorSubject<boolean>(false);



  public onFileChangedMulti(event): void { // funkcja wywowływana przez inpout type file
    // wyzerowanie zaznaczoncyh zdjęć
    this.MultiImageStopUpload = true; // zablokowanie funckji uplaod
    this.ImageUploadArray_.next([]);
    let tmpArray = this.ImageUploadArray_.value; // pobranie tablicy zdjęc do wysłania
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < event.target.files.length; i++) { // w pętli dodanie pojedyńczo do tabeli z zdjęciami każego zdjęcia
      tmpArray.push(event.target.files[i]);
    }
    this.ImageUploadArrayLastUploadSize_.next(event.target.files.length); // wstawienie wielkośći danych potrzebne dla progressbar
    this.ImageUploadArray_.next(tmpArray); // wstawienie nowych danych do behaviorSubject
    this.firstImage = true; // wyzerowanie wskażnika służącego za jednorazowe wyświetlenie komunikatu o rozpoczęciu wysyłania zdjęć


    this.multiImagesUploadStatus_.next(false);
    // * ustawienia przycisków
    this.MultiImageButtonStartUplaod_.next(true);
    this.MultiImageButtonPauseUpload_.next(false);
    this.MultiImageButtonReturnUpload_.next(false);
    this.MultiImageButtonResetUpload_.next(true);
  }


  public ResetMultiImageArray(): void {
    // * ustawienia przycisków
    this.MultiImageButtonStartUplaod_.next(true);
    this.MultiImageButtonPauseUpload_.next(false);
    this.MultiImageButtonReturnUpload_.next(false);
    this.MultiImageButtonResetUpload_.next(false);
    this.ImageUploadArrayPromiseArray = []; // wyzerowanie tablice obietnic
    this.ImageUploadArray_.next([]); // wyzerowanie tablice zdjęć do wysłania
    this.multiImagesUploadStatus_.next(true);
  }

  public PauseMultiImageUpload(): void { // awaryjne zatrzymywanie wysyłania zdjęć
    // * ustawienia przycisków
    this.MultiImageButtonStartUplaod_.next(false);
    this.MultiImageButtonPauseUpload_.next(false);
    this.MultiImageButtonReturnUpload_.next(true);
    this.MultiImageButtonResetUpload_.next(true);
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: 'Wysyłanie wstrzymane (⓿_⓿) !!!',
      showConfirmButton: false,
      timer: 3000
    });
    this.MultiImageStopUpload = true; // zablokowanie funckji uplaod

    this.returnImagesFromDatabase()
      .then(data => {
      }).catch(data => {

      });
  }

  public ReturnMultiImageUpload(): void {
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'info',
      title: 'Wysyłanie wznowione !!!',
      showConfirmButton: false,
      timer: 3000
    });
    // * ustawienia przycisków
    this.MultiImageButtonStartUplaod_.next(false);
    this.MultiImageButtonPauseUpload_.next(true);
    this.MultiImageButtonReturnUpload_.next(false);
    this.MultiImageButtonResetUpload_.next(true);
    this.MultiImageStopUpload = false; // odblokowanie funckji uplaod
    this.MultiImageUpload(); // wzznowienie funkcji upload
  }
  public StartMultiImageUpload(): void {
    this.errorArray = []
    this.MultiImageStopUpload = false; // zablokowanie funckji uplaod
    this.MultiImageUpload();

  }
  errorArray = [];
  private MultiImageUpload(): void {

    if (this.MultiImageStopUpload) {
      return;
    }

    // * sekcja odpoweidzialna za wysyłanie rekurencyjne zdjęć
    if (this.firstImage) { // jeżeli jest to pierwsze zdjęcie z pakietu
      Swal.close();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'info',
        title: 'Wysyłanie rozpoczęte...',
        showConfirmButton: false,
        timer: 6000
      });
      // * ustawienia przycisków
      this.MultiImageButtonStartUplaod_.next(false);
      this.MultiImageButtonPauseUpload_.next(true);
      this.MultiImageButtonReturnUpload_.next(false);
      this.MultiImageButtonResetUpload_.next(true);
    }

    // wywoływanie rekurencyjne obietnic wysyłających zdjęcia
    if (this.ImageUploadArray_.value.length > 0) {  // jeżeli jest jakieś zdjęcie w tablicy zdjęć do wysłania
      this.ImageUploadArrayPromiseArray.push(new Promise((resolve) => { // obietnica zwracająca status wysłąnia zdjęcai
        let tmpArray = this.ImageUploadArray_.value; // pobranie tablicy zdjęć
        let ImageToSend = tmpArray[0];  // pobranie pierszego elementu tablicy z zdjęciami do wysałania
        let uploadData = new FormData();
        let tmp = this.selectedFolderPath_.value;    // * Tworzenie ścieżki dostępu obsłuygiwanej przez bazę danych
        let tmpar = tmp.split("");
        let pathtmp = this.selectedFolder.value;
        const path = pathtmp + '*' + ImageToSend.name; // stworzenie gotowej nazwy skłądającej się z ścieżki i nazwy pliku
        uploadData.append('myFile', ImageToSend, path); // zapakowanie pliku
        // tslint:disable-next-line: max-line-length
        // tslint:disable-next-line: max-line-length
        this._apiService.UploadFile(this._securityService.safeReturnTokenObjectTokenValue(), uploadData, '/uploadImage').then((data: FennecApiClass) => { // po kolei wysyłanie zdjęc do api
          // tslint:disable-next-line: max-line-length
          if (this.MultiImageStopUpload) { // jeżeli funckja została zatrzymana, ale zdjęcie było w trakcie wysyłania, zostanie odświerzona baza zdjęć
            this.returnImagesFromDatabase();
          }
          this.errorArray.push("<b>"+ImageToSend.name+'</b> == '+ JSON.stringify(data));
          if (typeof data.data['imageID'] == 'undefined') {
            tmpArray.shift(); // usunięcie wysłanego zdjęcia z tablicy
            this.ImageUploadArray_.next(tmpArray) // wysłanie tablicy do behaviorSubject
            resolve(false); // ustawienie flagi true dla zdjęcia
            this.MultiImageUpload(); // wywołanie rekurencyjne funckji
          }
          tmpArray.shift(); // usunięcie wysłanego zdjęcia z tablicy
          this.ImageUploadArray_.next(tmpArray) // wysłanie tablicy do behaviorSubject
          resolve(true); // ustawienie flagi true dla zdjęcia
          this.MultiImageUpload(); // wywołanie rekurencyjne funckji
        }).catch(data => {
          this.errorArray.push("<b>"+ImageToSend.name+'</b> == '+ JSON.stringify(data));
          tmpArray.shift(); // usunięcie wysłanego zdjęcia z tablicy
          this.ImageUploadArray_.next(tmpArray) // wysłanie tablicy do behaviorSubject
          resolve(false); // ustawienie flagi false dla zdjęcia
          this.MultiImageUpload(); // wywołanie rekurencyjne funckji
        });
      })
      );
    }
    // kiedy tablica zdjęc zostaje pusta, a wysłanie się zakończyło
    if (this.ImageUploadArray_.value.length == 0) {
      Promise.all(this.ImageUploadArrayPromiseArray).then(data => {
        let allTrue = true;
        let i = this.ImageUploadArrayLastUploadSize_.value;
        data.forEach(element => {
          if (element == false) {
            allTrue = false;
            i--;
          }
        });
        if (allTrue) {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Wszystkie zdjęcia zostały dodane poprawnie',
            showConfirmButton: false,
            timer: 3000
          });
        } else {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'info',
            title: 'Nie wszystkie zdjęcia zostały dodane poprawnie (' + i + '/' + this.ImageUploadArrayLastUploadSize_.value + ')',
            showConfirmButton: false,
            timer: 4900
          });
          setTimeout(()=>{
           let html2 = '';
            this.errorArray.forEach(a=>{
              html2 += a + '<br><br><br>';
            })
            Swal.fire({
              title: 'Error list',
              icon: 'info',
              html:  html2,
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              cancelButtonText:
                  'Zamknij',
            })

          },5000);
        }
        this.ImageUploadArrayLastUploadSize_.next(0); // wyzerowanie ilośći zdjęć w ostatnim wysłaniu
        this.returnImagesFromDatabase();
      })
      this.ImageUploadArrayPromiseArray = []; // wyzerowanie tablicy obietnic, statusy wysłań zdjęć
      this.multiImagesUploadStatus_.next(true); // resetowanie wyswietlanie pełengo progressbara
      this.ImageUploadArrayPromiseArray = [];
      this.ImageUploadArray_.next([]);


      // * ustawienia przycisków
      this.MultiImageButtonStartUplaod_.next(true);
      this.MultiImageButtonPauseUpload_.next(false);
      this.MultiImageButtonReturnUpload_.next(false);
      this.MultiImageButtonResetUpload_.next(false);


      return;
    }
  }




  public getImage(id): string {
    if (String(this.imageArray[id]).length < 10) {
      this._apiService.GetImage(id, null).then((data: ImagesClass) => {
        this.imageArray[id] = data['data'];
        return this.imageArray[id];
      });
    } else {
      return this.imageArray[id];
    }
  }
  public getImageSafe(id): string {
    return this.imageArray[id];
  }

  public copyToClipboard(val: string): void {
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: 'Adres zdjęcia został skopiowany do schowka',
      showConfirmButton: false,
      timer: 2000
    });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public PromiseGetLastSelectedImage(): Promise<ImagesClass> {
    return new Promise((resolve) => {
      const sub = this.galleryLastSelectedImage_.subscribe(image => {
        if (image != null) {
          resolve(image);
          sub.unsubscribe();
        }
      });
    });
  }

  public PromiseGetLastSelectedMultiImageIDArray(): Promise<Array<ImagesClass>> {
    return new Promise((resolve) => {
      const sub = this.galleryLastSelectedMultiImageArray_.subscribe(imageArray => {
        if (imageArray.length != 0) {
          resolve(imageArray);
          sub.unsubscribe();
        }
      });
    });
  }

  public returnImagesFromDatabase(): Promise<boolean> {
    let tmpArray: Array<ImagesClass> = [];
    var prom = [];
    prom.push(new Promise((resolve, reject) => {
      this.tree.init=false;
      this.tree.returnDynamicTreeElements().then(() => {
        resolve(true);
      });
    })
    )
    prom.push(new Promise((resolve, reject) => {
      this._apiService.GetFromDataBase(null, 'images', [], [], [])
        .then((data: Array<ImagesClass>) => {
          resolve(data);
        }).catch(data => {
          reject(false);
        });
    })
    );
    return new Promise((resolve, reject) => {
      Promise.all(prom).then(data => {
        data[1].forEach((element: ImagesClass) => {
          let tmpImage = new ImagesClass(element.id);
          tmpImage.LoadAllData(element.id, element.image_name, element.image_size,
            element.patch, element.patch_id, element.uploader_id, element.create_time, element.alt, element.title);
          tmpArray.push(tmpImage);
        });
        this.images.next(tmpArray);
        this.node2.next(this.convertArrayToTree(this.images));
        resolve(true);
      }).catch(a => {
        console.log(a);
        reject(false);
      });
    });
  }




  public selectedFolder = new BehaviorSubject<number>(0);
  public SelectImageFolder(event): void  {
    if (event.parent.data.id < 700000000000) {
      //  console.log(event.parent.data.id)
    } else {
      //  console.log("null")
    }
    // console.log()
    this.selectedFolder.next(event.data.id)
  }
  public DeleteImage(DataBaseImageID: number): any {
    Swal.close();
    Swal.fire({
      title: 'Jesteś pewny?',
      text: 'Nie bedziesz mógł tego cofnąć!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Tak, usuwam to!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._apiService.deleteFile(this._securityService.safeReturnTokenObjectTokenValue(), DataBaseImageID, '/deleteImage/').then(data => {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Zdjęcie zostało usunięte',
            showConfirmButton: false,
            timer: 2000
          });
          this.returnImagesFromDatabase();
        }).catch(data => {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: 'Błąd usuwania',
            showConfirmButton: false,
            timer: 2000
          });
          this.returnImagesFromDatabase();
        });
      }
    });

  }
  ChangeNameFolder(id): void {
    this.tree.FolderChangeName(id).then(() => {
      this.node2.next(this.convertArrayToTree(this.images));
    });
  }

  addFolder(parent_id): void  {
    this.tree.addFolder(parent_id).then(() => {
      this.node2.next(this.convertArrayToTree(this.images));
    })
  }
  deleteFolder(id): void  {
    this.tree.deleteFolder(id).then(() => {
      this.node2.next(this.convertArrayToTree(this.images));
    })
  }

  convertArrayToTree(images): any {
    images.value.forEach((element: ImagesClass) => {
      var c = new treeElementClass;
      c.id = element.id;
      c.image_size = element.image_size;
      c.create_time = element.create_time;
      c.alt = element.alt;
      c.image_name = element.image_name;
      c.name = element.image_name;
      c.title = element.title;
      c.type = 'image';
      c.patch_id = element.patch_id;
      this.tree.addFileToFolder(c, element.patch_id)
    });
    return this.tree.tree;
  }

  onMoveNode(event): Promise<void> {
    console.log(event)
    if (event.to.parent.id == event.from.parent.id) {
      console.log("the same")
      return;
    }
    var a = new Promise<void>((resolve, reject) => {
      if (event.node.type =='image') {

        delete JSON['parent_id'];
        if (event.to.parent.id > 10000000000) {
          JSON['patch_id'] = '';
        } else {
          JSON['patch_id'] = event.to.parent.id;
        }
        this._apiService.updateInDatabase(this._securityService.safeReturnTokenObjectTokenValue(), JSON, 'images', ['id'], [event.node.id])
          .then(data => {
            this.moveSuccess()
            resolve();
            console.log(data)
          }).catch(data => {
            this.moveFalse();
            reject();

          })
        delete JSON['patch_id'];
      } else if (event.node.type !='image') {

        delete JSON['patch_id'];
        if (event.to.parent.id > 10000000000) {
          JSON['parent_id'] = 'null@';
        } else {
          JSON['parent_id'] = event.to.parent.id;
        }
        this._apiService.updateInDatabase(this._securityService.safeReturnTokenObjectTokenValue(), JSON, 'files_tree', ['id'], [event.node.id])
          .then(data => {
            this.moveSuccess()
            resolve();
          }).catch(data => {
            this.moveFalse();
            reject();
          })
        delete JSON['patch_id'];
      }
    })
  }
  public Treeoptions: ITreeOptions = {
    actionMapping,
    allowDrag: (node) => {
      return true;
    },
    allowDrop: (node, to) => {

      if (to.parent.data.image_name == undefined) { // blokada upuszczania zdjęć na inne zdjęcie. Pozwala tylko na przekazanie do folderu
        return true;
      } else {
        return false;

      }
    }
  };

  moveSuccess(): void {
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: 'Operacja udana',
      showConfirmButton: false,
      timer: 2000
    });
    this.returnImagesFromDatabase();
  }
  moveFalse(): void  {
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: 'Przesuwanie nie udane',
      showConfirmButton: false,
      timer: 2000
    });
    this.returnImagesFromDatabase();
  }
}
// tslint:disable-next-line:class-name
class treeElementClass {
  name;
  true_id;
  id;
  type;
  image_size;
  image_name;
  patch;
  alt;
  size;
  create_time;
  title;
  children;
  patch_id;
}
