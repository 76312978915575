import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagePathPipe'
})
export class ImagePathPipe implements PipeTransform {
  constructor() { }
  transform(value: string) {
    value = value.replace('@',String.fromCharCode(92));
    value = value.replace(String.fromCharCode(47),String.fromCharCode(92));
   return value;
  }


}
