<script src="../../../object/subPageClass.ts"></script>
<nav role="navigation" id="nav">
  <ng-container *ngIf="topMenu.length > 0">
    <ul [ngClass]="{'justifyCenter': justifyCenter}">
      <li class="WCAG_con_black  WCAG_c_width_maxContent" *ngIf="home != null">
        <a

          class="bigLink focus WCAG_con_Title_hover heightSimpleMenu"
          tabindex="0"
          focus="true"
          [routerLink]="['/' + _clientDataService.TemplateRouterObject.template + '']"
          [attr.aria-label]="'Przycisk przekierowywujący stronę główną'"
          role="link"
        >
          <span
            class="WCAG"
            *ngIf="home == 'text'"
          >
            Strona główna
          </span>
          <span
            class="WCAG_con_Title WCAG_con_Title_hover"
            *ngIf="home == 'image'"
          >
            <fa-icon class="home " [icon]="_fontService.faHome"></fa-icon>
          </span>
        </a>
      </li>

      <li
        aria-label="menu"
        class="focused WCAG_con_black WCAG_c_width_maxContent"
        focus="true"
        *ngFor="let element of topMenu | sort: 'position'; let i = index"
      >
        <a
          class="WCAG_c_width_maxContent bigLink focus WCAG_con_Title_hover"
          (click)="_clientDataService.elementAction(element)"
          [attr.aria-label]="_clientDataService.elementAriaLabel(element)"
          *ngIf="length(element.children) == 0"
          tabindex="0"
        >
          <span class="topMenu WCAG">
            {{ element.name }}
          </span>
        </a>

        <span class="bigLink" *ngIf="length(element.children) > 0">
          <span
            class="span2 WCAG_con_Title WCAG_con_Title_hover focus WCAG  heightSimpleMenu"

            role="button"
            [attr.aria-label]="'Przycisk rozwijający menu:' + element.name"
            tabindex="0"
          >
            {{ element.name }}&nbsp;&nbsp;&nbsp;
            <fa-icon class="triangle" [icon]="_fontService.faCaretDown">
            </fa-icon>
          </span>
        </span>

        <ol>
          <li
            aria-label="Podmenu elementu"
            class="focused WCAG_con_black WCAG_con_Title WCAG_c_width_maxContent"
            *ngFor="
              let subElement of element.children | sort: 'position ';
              let x = index
            "
          >
            <a
              (click)="_clientDataService.elementAction(subElement)"
              [attr.aria-label]="_clientDataService.elementAriaLabel(subElement)
              "
              class="bigLink focus WCAG_con_Title_hover"
              tabindex="0"
            >
              <span class="WCAG">
                {{ subElement.name }}
              </span>
            </a>
          </li>
        </ol>
      </li>
    </ul>
  </ng-container>
</nav>
