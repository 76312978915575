import { GeneralService } from './../services/general.service';
import { AppComponent } from './../app.component';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor( private _router : Router,private _appComponent: AppComponent,private _generalService : GeneralService) { }

  ngOnInit(): void {
    this._generalService.loadDataFromDatabaseComplete.next('PageNotFound init');
    this._appComponent.LinkTilesPanelDisplay = false;
  }
  public BackToHome(): void{
    this._router.navigate(['./']);
  }
}
