<div class='image' >

  <img class="bigImage"

    [src]="_ImageStorage.galleryStorage.value.returnImage(NowImage).src"
    [alt]="_ImageStorage.galleryStorage.value.returnImage(NowImage).alt"
    [title]="_ImageStorage.galleryStorage.value.returnImage(NowImage).title">
  <div class="con">
    <input class="XButton" type="button" (click)="_activeModal.dismiss('Cross click')" tabindex="0"
      aria-label="Przycisk wyłączający podgląd zdjęcia" value="X">
      <div class="con2">
    <input type="button" value="<" class="nButton btn btn-success" (click)="prevImage()" style="border-radius: 0;"
      aria-label="Przycisk przełączający zdjęcie na poprzednie">
    <input type="text" [disabled]="true" [value]="(nowImage() + 1)+'/' +imagesArray.length  " class="putton btn btn-info"  style="border-radius: 0;"
      role="presentation">
    <input type="button" value=">" class="nButton btn btn-success" (click)="nextImage()"  style="border-radius: 0;"
      aria-label="Przycisk przełączający zdjęcie na następne">
      </div>
  </div>
</div>
