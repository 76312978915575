
<ng-container *ngIf="imageReady ">
  <ng-container *ngIf="_ClientDataService.galleryObject.seletedGallery.images.length > 1;then zdj; else brak "></ng-container>
  <ng-template #brak><p style="width: 100%;text-align: center;font-size: 25px"><span class="WCAG">Brak zdjęć w galerii</span></p></ng-template>
  <ng-template #zdj>
<div
  class="imageGrid"
  *ngIf="_ClientDataService.galleryObject.seletedGallery != null "
>
  <section
    class="imageBlock"
    *ngFor=" let img of _ClientDataService.galleryObject.seletedGallery.images.split(',') let i of index
    "
  >
  <button style="background-color: transparent;" class="image"
  aria-label="Przycisk powiększający zdjęcie"
  tabindex="0"
  [id]="i == 0? firstElementID:''"
  (click)="OpenAdvancedGalleryView(_ClientDataService.galleryObject.seletedGallery.images, img)">
    <img
      [src]="_ImageStorage.galleryStorage.value.returnImage(img).src"


      [alt]="_ImageStorage.galleryStorage.value.returnImage(img).alt"
      [title]="_ImageStorage.galleryStorage.value.returnImage(img).title"
    />
  </button>
  </section>

</div>
  </ng-template>
