import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageSplitPipe'
})
export class ImageSplitPipe implements PipeTransform {
  constructor() { }
  transform(value: string) {
   return value.split(',');
  }


}
