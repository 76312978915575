import { SwitchThemeService } from './switch-theme.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WcagService {

  constructor(
    private _themeService:SwitchThemeService
  ) { }
 private  highContrast = false;
  highContrastSwitch(): void{
    if(this.highContrast == false){
      this.highContrast = true;
      this._themeService.loadTheme('highContrast','highContrast');
    } else {
      this.highContrast = false;
      this._themeService.removeTheme('highContrast');
    }
 }
}
