<div class="modal-header">
  <h4 class="modal-title">Ustawienia zdjęcia</h4>
  <button type="button" class="close" aria-label="Close" (click)="_activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<table>
  <tr>
    <td colspan="2">
      <div class="admin-input-inline">
        <label class="w200">Nazwa zdjęcia: </label>
        <input class="form-control w270" type="text" [disabled]="true" [value]="image.image_name">
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <div class="admin-input-inline">
        <label class="w200">Rozmiar zdjęcia: </label>
        <input class="form-control w270" type="text" [disabled]="true" [value]="image.image_size">
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <div class="admin-input-inline">
        <label class="w200">Tytuł: </label>
        <textarea class="form-control w270" style="height: 70px; font-weight: normal;" type="text"
          [value]="image.title" (keyup)="image.title = $event.target.value" placeholder="Brak tytułu"> </textarea>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <div class="admin-input-inline">
        <label class="w200">Tekst alternatywny: </label>
        <textarea class="form-control w270" style="height: 170px; font-weight: normal;" type="text" [value]="image.alt"
          (keyup)="image.alt = $event.target.value" placeholder="Brak tekstu alternatywnego. Wymagane przez WCAG 2.1"> </textarea>
      </div>
    </td>
  </tr>
  <tr>
    <td style="float: right;"><input type="button" class="btn btn-warning" value="Cofnij zmiany">&nbsp;&nbsp;<input
        type="submit" class="btn btn-success" value="Zapisz" (click)="save()"></td>
  </tr>
</table>

<div class="image">
  <h5 style="width: 100%;">Podgląd zdjęcia:</h5>
  <content class="option">
    <table>
      <tr>
        <td colspan="2">
          <div class="admin-input-inline">
            <label class="w100">Obracanie:</label>
            <input class="form-control w100" type="text" [disabled]="true" >
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="admin-input-inline">
            <label class="w100">Czar/biał:</label>
            <input class="form-control w100" type="text" [disabled]="true" >
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="admin-input-inline">
            <label class="w100">Wysokość:</label>
            <input class="form-control w100" type="text" [disabled]="true" >
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="admin-input-inline">
            <label class="w100">Szerokość:</label>
            <input class="form-control w100" type="text" [disabled]="true" >
          </div>
        </td>
      </tr>
    </table>
  </content>
  <img [src]="''" [alt]="'%_BigView_#%eFennec#%#%='+image.id+'=%#eFennec%#%__#%Błąd zdjęcia'" class="ImageIcon">
</div>
<div class="modal-footer">
  <p><br>
    Zapisywanie w tym miejscu, powoduje natychmiastowy zapis w bazie danych.
    <br>
  </p>
</div>
