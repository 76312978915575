import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fast-mini-menu, WCAG_fast_menu',
  templateUrl: './fast-mini-menu.component.html',
  styleUrls: ['./fast-mini-menu.component.scss']
})
export class FastMiniMenuComponent implements OnInit {
  @Input()  PageMap: string = null;
  @Input()  content: string = null;
  constructor(
    private _router: Router
    ) { }

  ngOnInit(): void {
  }
  GoToContent(){
    document.getElementById(this.content).focus();
  }
  public FakeMouseClick(event){
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
          document.getElementById(event.target.id).click();
        break;
      }
    } //end switch
    return true;
  }
  ToPageMap(){
    this._router.navigate([this.PageMap])
  }
}
