import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private nameTag = 'eFennecSchool_';
  constructor(private _CookieService: CookieService) { }

// const end_datetime = new Date( new Date().getTime() + (1000 * this.tokenLifeTime.value));// tslint:disable-line
  private NameTag(name): any {
    return (this.nameTag + name);
  }

  CreateCookies(name, val, date): any {
    this._CookieService.set(this.NameTag(name), val, new Date(date));
  }
  DeleteAllCookis(): any {
    this._CookieService.deleteAll();
  }

  DeleteCookis(name): any  {
    this._CookieService.delete(this.NameTag(name));
  }

  GetCookies(name): any {
    return (this._CookieService.get(this.NameTag(name)));
  }
  GetAllCookis(): any {
 return this._CookieService.getAll();
  }

  CheckCookies(name): any {
    return (this._CookieService.check(this.NameTag(name)));
  }
}
