import { SecurityService } from './../services/security/security.service';
import { ApiService } from './../services/api/api.service';
import Swal from 'sweetalert2';
import { GalleryInitServiceService } from '../administrator/services/gallery-init-service.service';
import { GalleryService } from '../administrator/services/gallery.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
export class galleryPageStorageClass {

  public galleryLength;
  public itemsPerPage = 2;
  public page = 1;
  public pageSize: number;
  public currentPage: number = 1 ;
  public onPageChange(pageNum: number): void {
    //this.currentPage = this.page;
    this.scrollToTop();
    setTimeout(() => { // ładniej wygląda z opóźnieniem
      this.pageSize = this.itemsPerPage * (pageNum - 1);
    }, 400);
  }
  public changePagesize(num: number): void {
    this.itemsPerPage = this.pageSize + num;
  }
  private scrollToTop() {
    //document.getElementById('scrolPoint').scrollIntoView();
      window.scroll(0,0)
  }
  public seletedGallery: galleryPageClass = null;
  SelectGalleryImages(param:number){
      this.returnFormDataBase().then(()=>{})
     this.SelectGalleryImagesRek(param);
  }
  private SelectGalleryImagesRek(param:number){
   if(this.storage.length > 0){
     this.seletedGallery =this.storage.find(el=>{
       return el.id == param;
      })
    }else{
      setTimeout(() => {
        this.SelectGalleryImagesRek(param);
      }, 100);
    }
  }



  storage: Array<galleryPageClass>;
  storageForrmated:Array<galleryPageFormatedClass> =[];


  private LoadDataFirst = false;
  selectedGalleryPage = -1;
  selectedGalleryPageDataBaseId = -1;

  public reset() {
    this.storage = [];
    this.LoadDataFirst = false;
    this.returnFormDataBase();
  }
  public returnFormDataBase() {
    return new Promise((resolve, reject) => {
    if(this.LoadDataFirst == false){
      this.storage = [];
      this.LoadDataFirst = true;
      ApiService.instance.GetFromDataBase(SecurityService.instance.safeReturnTokenObjectTokenValue(), 'gallery_pages', [], [], []).then((data: Array<galleryPageClass>) => {
        data.forEach(imagePage => {
          this.storage.push(new galleryPageClass(imagePage.id, imagePage.title, imagePage.images, imagePage.year, imagePage.publish_date, imagePage.status, imagePage.create_time, imagePage.update_time))
        })
          this.FormatData();
          this.galleryLength = this.storageForrmated.length;

          resolve(true);
        }).catch(data => {
          reject(false);
        })
      } else{
        resolve(true);
      }
    })
  }

  private FormatData(){
    let spr1 = false;
    //console.log(  this.storage)
    this.storage.forEach(element=>{
    this.storageForrmated.forEach(hmm=>{
      if(hmm.year == element.year)
      {
        spr1 = true;
        hmm.array.push(element)

      }
    })
    if(spr1 == false){

      var tmp = new galleryPageFormatedClass()
      tmp.year = element.year;
      tmp.array.push(element)
      this.storageForrmated.push(tmp)
      }
      spr1 = false;
    })
  }


  public deleteInDataBase(id) {
    if (id == -1) {
      return null
    }
    Swal.close();
    Swal.fire({
      title: 'Jesteś pewny?',
      text: 'Nie bedziesz mógł tego cofnąć!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Tak, usuwam to!',
    }).then((result) => {
      if (result.value) {
        ApiService.instance.deleteInDataBase(SecurityService.instance.safeReturnTokenObjectTokenValue(), 'gallery_pages', ['id'], [String(id)]).then(data => {
          ApiService.instance.deleteInDataBase(SecurityService.instance.safeReturnTokenObjectTokenValue(),'general_element_in_table_permission',['table','element_id'],['gallery_pages',String(id)])
          .then(val=>{
            console.log(val)
          })
          this.reset();
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Galeria usunięta poprawnie',
            showConfirmButton: false,
            timer: 3000
          });
        }).catch(data => {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: 'Błąd usuwania galerii',
            showConfirmButton: false,
            timer: 3000
          });
        })
        this.selectedGalleryPage = -1;
        this.selectedGalleryPageDataBaseId = -1;
      } else {
        Swal.fire(
          'Informacja',
          'Operacja anulowana !',
          'info'
        );
      }
    });


  }


  constructor() {
    this.storage = [];
  }


}
export class galleryPageClass {
  id: number;
  title: string;
  images: string;
  year: string;
  status: string;
  create_time: Date;
  update_time: Date;
  publish_date: Date;
  constructor(id: number, title: string, images: string, year: string, publish_date: Date, status: string,
    create_time: Date, update_time: Date) {
    this.id = id;
    this.title = title;
    this.year = year;
    this.images = images;
    this.status = status;
    this.create_time = create_time;
    this.update_time = update_time;
    this.publish_date = publish_date;
  }
  private addCompleateToast(){
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: 'Galeria dodana poprawnie',
      showConfirmButton: false,
      timer: 3000
    });
  }
  public AddToDataBase(): Promise<any> {
    JSON['title'] = this.title;
    JSON['year'] = this.year;
    JSON['images'] = this.images;
    JSON['status'] = this.status;
    JSON['publish_date'] = this.publish_date;
    return new Promise((resolve, reject) => {
      ApiService.instance.addToDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), JSON, 'gallery_pages').then(data => {
        switch (this.status) {
          case 'published':
            this.set_gallery_privileges(data[0].id, 'general_element_in_table_permission', true, false).then(data => {
              this.addCompleateToast();
            });
            break;
            case 'unpublished':
              this.set_gallery_privileges(data[0].id, 'general_element_in_table_permission', false, false).then(data => {
              this.addCompleateToast();
            });
            break;
        }





        resolve(true);

      }).catch(data => {
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Błąd dodawania galerii',
          showConfirmButton: false,
          timer: 3000
        });
        reject(false)
      })
      delete JSON['title'];
      delete JSON['year'];
      delete JSON['images'];
      delete JSON['status'];
      delete JSON['publish_date'];
    });
  }

  private set_gallery_privileges(id: number, privilages_name: string, read: boolean, modification: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.instance.change_privileges(SecurityService.instance.safeReturnTokenObjectTokenValue(), privilages_name,
        'gallery_pages', id, read, null, modification).then(value => {
          resolve(value);
        }).catch(value => {
          console.log('set_artilce_priviliges return error')
          console.log(value)
          reject(false);
        });
    });
  }
  private ModifyCompleateToast(){
    Swal.close();
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: 'Galeria zmodyfikowana poprawnie',
      showConfirmButton: false,
      timer: 3000
    });
  }
  public UpadateInDataBase(): Promise<any> {
    JSON['title'] = this.title;
    JSON['year'] = this.year;
    JSON['images'] = this.images;
    JSON['status'] = this.status;
    JSON['publish_date'] = this.publish_date;
    return new Promise((resolve, reject) => {
      ApiService.instance.updateInDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), JSON, 'gallery_pages', ['id'], [String(this.id)]).then(data => {
        switch (this.status) {
          case 'published':
            this.set_gallery_privileges(this.id, 'general_element_in_table_permission', true, false).then(data => {
              this.ModifyCompleateToast();
            });
            break;
          case 'unpublished':
            this.set_gallery_privileges(this.id, 'general_element_in_table_permission', false, false).then(data => {
              this.ModifyCompleateToast();
            });
            break;
        }


        resolve(true);

      }).catch(data => {
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Błąd modyfikacji galerii',
          showConfirmButton: false,
          timer: 3000
        });
        reject(false)
      })
      delete JSON['title'];
      delete JSON['year'];
      delete JSON['images'];
      delete JSON['status'];
      delete JSON['publish_date'];
    })
  }

  drop(event) {
    let imagesArray = this.images.split(',');
    moveItemInArray(imagesArray, event.previousIndex, event.currentIndex);
    let string = '';
    let i = 0;
    imagesArray.forEach(element => {
      if (i == 0) {
        string = string + element;
      } else {
        string = string + ',' + element;
      }
      i++;
    });
    this.images = string;
    console.log(event)
  }
  updateWithMultiImageGallery(): void {
    if (this.images != null) {
      let selectedGalleryImages = this.images.split(','); // stworzneie tablicy zaweirającej id zdjjęć
      GalleryService.instance.galleryLastSelectedMultiImageIDInitValue = selectedGalleryImages; // wstawienie tablicy zdjęć jako parametr startowy
    } else {
      GalleryService.instance.galleryLastSelectedMultiImageIDInitValue = [];
    }
    GalleryInitServiceService.instance.OpenGalleryPanelWithMultiIDReturnPromise()
      .then(imageIdArray => {
        let string = '';
        let i = 0;

        imageIdArray.forEach(element => {
          if (i == 0) {
            string = string + element.id;
          } else {
            string = string + ',' + element.id;
          }
          i++;
        });
        this.images = string;
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Zdjęcie pobrane poprawnie',
          showConfirmButton: false,
          timer: 3000
        });
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Błąd wyboru zdjęcia',
          showConfirmButton: false,
          timer: 3000
        });
      })
  }
}
export class galleryPageFormatedClass{
  year:string;
  array:Array<galleryPageClass>;
  constructor(){
    this.array = [];
  }
}
