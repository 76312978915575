import { BehaviorSubject } from 'rxjs';
import { GalleryService } from 'src/app/administrator/services/gallery.service';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModalComponent } from '../administrator/subpges/gallery/gallery-modal/gallery-modal.component';
import { ImagesClass } from 'src/app/object/gallery.class';
@Injectable({
  providedIn: 'root'
})
export class GalleryInitServiceService {
  static instance: GalleryInitServiceService;
  public galleryModal: NgbActiveModal;
  constructor(
    private _modal: NgbModal,
    public _galleryService: GalleryService
  ) {
    GalleryInitServiceService.instance = this;
  }
  public stiupidGalleryInit_ = new BehaviorSubject<boolean>(false);
  public OpenGalleryPanel(): void {
    this.galleryModal = this._modal.open(GalleryModalComponent, { size: 'lg', });
  }



  public OpenGalleryPanelWithIDReturnPromise(): Promise<ImagesClass> {
    return new Promise((resolve, reject) => {
      this._galleryService.galleryWorkMode = 'getID'; // ustawienie trybu działania galleri
      this.OpenGalleryPanel();  // włączenie panelu galleri
      const safeTimer = setInterval(() => { // zabezpieczenie
          if(this._modal.hasOpenModals() == false){ // sprawdza czy istnieje otwarte okno modal
            clearTimeout(safeTimer);    // wyłącza timer
            reject(false);          // promise zwraca false
          }
        }, 100);
      this._galleryService.PromiseGetLastSelectedImage().then(image => { // stworzenie obietnicy zwrócenia ID zdjęcia z galleri
        console.log(image)
        this.galleryModal.close(); // zamknięcie panelu galleri po zwróeniu ID zdjęcia
        this._galleryService.galleryLastSelectedImage_.next(null); // wyzerowanie lastSelectedImageID
        resolve(image);
      });
    })
  }
  public OpenGalleryPanelWithMultiIDReturnPromise(): Promise<Array<ImagesClass>> {
    this._galleryService.galleryLastSelectedMultiImageIDArrayENDBUTTTON_.next(false);

    return new Promise((resolve, reject) => {
      this._galleryService.galleryWorkMode = 'getMultiID'; // ustawienie trybu działania galleri
      this.OpenGalleryPanel();  // włączenie panelu galleri
      const safeTimer = setInterval(() => { // zabezpieczenie
          if(this._modal.hasOpenModals() == false){ // sprawdza czy istnieje otwarte okno modal
            clearTimeout(safeTimer);    // wyłącza timer
            reject(false);          // promise zwraca false
          }
        }, 100);
      // tslint:disable-next-line: max-line-length
      let sub = this._galleryService.galleryLastSelectedMultiImageIDArrayENDBUTTTON_.subscribe(bool => { // stworzenie obietnicy zwrócenia ID zdjęcia z galleri
        if(bool == true){
          this.galleryModal.close(); // zamknięcie panelu galleri po zwróeniu ID zdjęcia

          sub.unsubscribe();
          resolve(this._galleryService.galleryLastSelectedMultiImageArray_.value);

        }
      });
    })
  }

}
