import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePipe'
})
export class TimePipe implements PipeTransform {
  constructor() { }
  transform(value: string) {
    
    if(value == "null"){
        return "&nbsp;"
    }
    if(value == "00:00"){
        return "--";
    }
    var time = value.split(':')
    value =time[0] +  "<sup>" + time[1] + "</sup>"

   return value;
  }


}
