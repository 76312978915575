// tslint:disable: triple-equals
// tslint:disable: max-line-length
// tslint:disable: variable-name
export class LinkTilesClass {
  id: number;
  title: string;
  image_id: number;
  alt: string;
  href: string;
  deleted: boolean;
  position: number;
  newRecord: boolean;
  modified: boolean;
  nameModifyMode: boolean;
  constructor(id: number = 0,position: number = 0, title: string  = 'null', image_id: number = 0, href: string = 'null', alt: string = 'Brak opisu') {
    this.title = title;
    this.position = position;
    this.id = id;
    this.alt = alt;
    this.image_id = image_id;
    this.href = href;
    this.newRecord = false;
    this.modified = false;
    this.nameModifyMode = false;
    this.deleted = false;
  }
  setRecordAsModified(): void{
    this.modified = true;
  }
  setNewRecord(): void{
    this.newRecord = true;
  }
  setNameModifyMode(status): void{
    this.nameModifyMode = status;
  }
  setNameModify(name: string): void{
    this.title = name;
  }
  setRecordToDeleteStatus(stat): void{
    this.deleted = stat;
  }
  setImage_id(id): void{
    this.image_id = id;
  }
  setHref(href): void{
    this.href = href;
  }
  setPosition(number: number): void{
    this.position = number;
  }
}


