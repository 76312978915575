import { SecurityService } from './../../services/security/security.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiService } from 'src/app/services/api/api.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { environment } from './../../../environments/environment';
import { ConfigFileService } from 'src/app/services/config-file.service';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit,OnDestroy {
  private sub:Subscription;
  public prod;
  private sub2:Subscription;
  constructor(
    private _router: Router,
     private _api: ApiService,
     private _generalService : GeneralService,
     private _ConfigFileService: ConfigFileService,
     private _securityService:SecurityService
     ) { }
  ngOnDestroy(): void {
 //   this.sub.unsubscribe();
   // this.sub2.unsubscribe();
  }
  private a: Subscription;
  public template = null;

  test(id){
    switch (String(id)) {
      case '1':
        return '/blueSky';
      case '2':
        return '/orangeSky';
      case '3':
        return '/bunny';
      case '4':
        return '/angela';
      case '5':
        return '/patio';
      case '6':
        return '/baks';
      case '7':
        return '/ara';
      case '8':
        return '/leth';
      case '9':
        return '/enif';
      default:
        return 'template error';
      break;
    }
  }
  ngOnInit(): void {
    this.prod = environment.production; // Logs false for default environment
    //* jeżeli wisi prośba o reload
    if(this._generalService.pleaseReloadMeeee == true){
      window.location.reload();
    }



      if(this._ConfigFileService.ConfigObject.pageDisabled == "true"){
          if ( this._securityService.safeReturnTokenObjectTokenValue() == null){
            this._router.navigate(['/pageDisable'])
          } else {
            this.loadPage();

          }
        } else{
         this.loadPage();
        }

  }
loadPage(){
  const patch = this.test(this._ConfigFileService.ConfigObject.template);
  this.retry(1,patch); //! tu jest PROBLEM
}

  retry(i,patch){
    setTimeout(() => {
      if(this._router.url == "/"){
        this._router.navigate([patch])
        this.prod == false ? console.log(i): null; //! !!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // this.retry(i + 4,patch)
      }
    }, i);
  }

}
