import { subPageClass } from './../object/subPageClass';
import { SettingsConfigClass } from './../object/templateClass';
import { ConfigFileService } from './config-file.service';
import { MenuClass } from './../object/headMenuClass';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';
import { BigImageViewComponent } from './../user/modal/big-image-view/big-image-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ArticleObjectClass, ArticlesClass } from '../object/articleClass';
import { galleryPageStorageClass } from '../object/galleryPageClass';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public disablePageStatus_ = new BehaviorSubject<boolean>(null);
  public AdvancedGalleryViewImageID = []
  public AdvancedGalleryViewActualImageID = []
  public selectedTemplate_ = new BehaviorSubject(null);
  public loadDataFromDatabaseComplete = new BehaviorSubject<string>('false');
  public loadImageComplete_ = new BehaviorSubject<Array<Promise<Array<any>>>>([]);
  public loadImageCompleteStatus_ = new BehaviorSubject<Array<string>>([]);
  public apiUrl = null;
  public apiUrlFile = null;
  public form = new FormGroup({});
  static instance: GeneralService;
  public pleaseReloadMeeee = false;

  //public settings_ = new BehaviorSubject<SettingsConfigClass>(new SettingsConfigClass(0));
  public articlesObject = new ArticleObjectClass(this._router,this._ActivatedRoute);
  public galleryObject = new galleryPageStorageClass();


  constructor(

    private _api: ApiService,
    private _modal: NgbModal,
    private _galleryService: ImagesStorageService,
    private _ConfigFileService: ConfigFileService,
    private _ActivatedRoute: ActivatedRoute,
    private _router: Router,

  ) {

    this.apiUrl = this._ConfigFileService.ConfigObject.ApiLinkGetImage;
    this.apiUrlFile = this._ConfigFileService.ConfigObject.ApiLinkGetFile;
    GeneralService.instance = this;
   /* this.settings_.value.ReturnFromDataBase('config', [], []).then(data => {
    }).catch(data => {
    });*/



    this.articlesObject.articleInit();
    this.galleryObject.returnFormDataBase();

  }

  private articles: Array<ArticlesClass> = [];
  private articlesInit = false;
  public selectedArticleID = new BehaviorSubject<number>(null);


  private subPages: Array<subPageClass> = [];
  private subPagesInit = false;




  public returnTempalateName(id): string {

    switch (String(id)) {
      case '1':
        return '/blueSky';
      case '2':
        return '/orangeSky';
      case '3':
        return '/bunny';
      case '4':
        return '/angela';
      case '5':
        return '/patio';
      case '6':
        return '/baks';
      case '7':
        return '/ara';
      case '8':
        return '/leth';
      case '9':
        return '/enif';
      default:
        return 'template error';
        break;
    }
  }
  public FakeMouseClick(event){
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
          document.getElementById(event.target.id).click();
        break;
      }
    }
    return true;
  }
  public returnArticles(): Promise<any> {
    let promise = []
    return new Promise((resolve, reject) => {
      if (this.articlesInit === false) {
        this.articlesInit = true;
        this._api.GetFromDataBase(null, 'articles', ['status'], ['published'], [])
          .then(data => {
            data.forEach((element: ArticlesClass) => {
              if(!this.dataAfterToday(element.publish_date)){
                var tmp = new ArticlesClass(element.id, element.title, element.body, element.status, element.position,
                  element.create_time, element.update_time, element.publish_date, element.category, element.gallery_type, element.gallery_images,element.headImage,element.headImageMode);
                this.articles.push(tmp);
              }

              resolve(this.articles);
            });
          }).catch(data => {
            reject(false);
          });
      } else {
        resolve(this.articles);
      }
    })
  }

  dataAfterToday(date): boolean{
    if(new Date(date).getTime() > new Date().getTime()){
      return true;
    } else{
      return false;
    }
  }
  public returnSubPages() {
    return new Promise((resolve, reject) => {
      if (this.subPagesInit === false) {
        this.subPagesInit = true;
        this._api.GetFromDataBase(null, 'subpages', ['status'/*, 'category'*/], ['published'/*, 'Aktualności'*/], [])
          .then(data => {
            data.forEach((element: ArticlesClass) => {
              var tmp = new subPageClass(element.id, element.title, element.body, element.status, element.position,
                element.create_time, element.update_time, element.publish_date, element.gallery_type, element.gallery_images);
              this.subPages.push(tmp);
            });
            resolve(this.subPages);
          }).catch(data => {

            reject(false);
          });
      } else {
        resolve(this.subPages);
      }
    });
  }

  public returnArticleWithID(id) {
    return new Promise((resolve, reject) => {
      this.returnArticles().then((value: Array<ArticlesClass>) => {
        var tmp: Array<ArticlesClass> = null;
        tmp = value.filter((element: ArticlesClass) => {
          return element.id == id;
        });
        if (tmp.length == 0) {
          reject(false);
        }
        if (tmp.length > 1) {
          reject(false);
        }
        resolve(tmp[0]);
      }).catch(value => {
        reject(false);
      });
    });
  }


  public setArticleID(id) {
    this.selectedArticleID.next(id);
  }


  public backToMainPage() {
    this._router.navigate(['/user']);
  }



  OpenBigImageView(url: string) {
    this._galleryService.bigImageViewSrc = url;
    this._modal.open(BigImageViewComponent, { size: 'xl', windowClass: 'modal_content_image_view' })
  }
  ImageOptionCon;




  private headerMenuInit = false;
  public headerMenu: Array<MenuClass> = [];
  public returnMenu(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.headerMenuInit === false) {
        this.headerMenuInit = true;
        this._api.GetFromDataBase(null, 'header_menu', [], [], [])
          .then((data: Array<MenuClass>) => {
            const database: Array<any> = [];
            data.forEach((element: MenuClass) => {
              const tmp = new MenuClass(element.id, element.name, element.position, element.parent_id, element.mode, element.menuModeValue);
              database.push(tmp);
            });
            database.forEach(element => {   // wpadkowanie submenu do tablic w obiektach menu
              if (element.parent_id == 0) {
                this.headerMenu.push(element);
              }
            });
            database.forEach(element => {
              if (element.parent_id != 0) {
                this.headerMenu.filter(filterElement => {
                  if (filterElement.id == element.parent_id) {
                    filterElement.addchildrenObject(element);
                  }
                });
              }
            });
            resolve(this.headerMenu);
          })
          .catch(value => {
            reject(false);
          });
      } else {
        resolve(this.headerMenu);
      }
    });
  }




}
