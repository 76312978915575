import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ImagePathArrayToSlashPipe'
})
export class ImagePathArrayToSlashPipe implements PipeTransform {
  constructor() { }
  transform(value: Array<string>) {
    let tmp =  '';
    let i = 0;
  value.forEach(el=>{
    if(i == 0){
      tmp = tmp + el

    }else {
      tmp = tmp + '/'+ el
    }
    i++;
  })
   return tmp;
  }


}
