import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizePipe'
})
export class SizePipe implements PipeTransform {
  constructor() { }
  transform(value: any, type: string = 'auto', param: number = 2) {
    switch (type) {
      case 'auto':
        if (value > 1024 * 1024 * 1024 * 1024) {
          return Math.round(value / (1024 * 1024 * 1024 * 1024)) + 'TB';
        } else if (value > 1024 * 1024 * 1024) {
          return Math.round(value / (1024 * 1024 * 1024)) + 'GB';
        } else if (value > 1024 * 1024) {
          return Math.round(value / (1024 * 1024)) +  'MB';
        } else if (value > 1024) {
          return Math.round(value / 1024) + 'KB';
        }
        break;
      case 'double':
        if (value > 1024 * 1024 * 1024 * 1024) {
          return (value / (1024 * 1024 * 1024 * 1024)).toFixed(param) + 'TB';
        } else if (value > 1024 * 1024 * 1024) {
          return (value / (1024 * 1024 * 1024)).toFixed(param) + 'GB';
        } else if (value > 1024 * 1024) {
          return (value / (1024 * 1024)).toFixed(param) +  'MB';
        } else if (value > 1024) {
          return (value / 1024).toFixed(param) + 'KB';
        }
        break;

      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }


}
