import Swal from 'sweetalert2';
import { ApiService } from '../services/api/api.service';
import { SecurityService } from '../services/security/security.service';
// tslint:disable-next-line:class-name
export class dynamicTreeElementClass {
  id: number;
  name: string;
  parent_id: number;
  mode: string;
  deleted: boolean = false;
  children: Array<dynamicTreeElementClass> = [];
  constructor(id, name, parent_id) {
    this.id = id;
    this.name = name;
    this.parent_id = parent_id;
    this.deleted = false;
  }
}
export  class dynamicTreeStructureClass {
  id: number;
  name: string;
  parent_id: number;
  children: Array<dynamicTreeStructureClass> = [];

  constructor(id = 0, name = '', parent_id = 0) {
    this.id = id;
    this.name = name;
    this.parent_id = parent_id;
  }
}


export class dynamicTreeClass {
  FromDatabade: Array<dynamicTreeElementClass> = [];
  tree: Array<any> = [];
  mode: string;
  init = false;
  i = 0;
  s = 0;
  last = 0;
  constructor(mode: string) {
    this.mode = mode;
  }

  public returnDynamicTreeElements(): Promise<boolean> {
    this.FromDatabade = [];
    this.tree = [];
    return new Promise<boolean>((resolve, reject) => {
      if (this.init) {
        resolve(true)
      }
      this.init = true;
      this.FromDatabade = [];
      this.tree = [];
      ApiService.instance.GetFromDataBase('', 'files_tree', ['mode'], [this.mode], []).then((data: Array<dynamicTreeElementClass>) => {

        data.forEach(a => {
          this.FromDatabade.push(new dynamicTreeElementClass(a.id, a.name, a.parent_id));

        })
        this.tree = this.buildFolderTree();

        resolve(true);

      }).catch(() => {
        reject(false);
      })
    });
  }



  public addFileToFolder(object: any, folder_id: number): boolean {
    try {
      const found = this.addFileToFolderRec(object, folder_id);

      // Jeżeli nie znaleziono folderu, wrzuć do folderu głównego
      if (!found) {
        this.tree.push(object);
      }

      return found;
    } catch (error) {
      console.error('Błąd podczas dodawania pliku do folderu:', error);
      return false;
    }
  }

  private addFileToFolderRec(object: any, folder_id: number, tree = this.tree): boolean {
    // Zabezpieczenie przed nieskończoną pętlą
    if (this.i++ > 1000) {
      console.warn('Przekroczono maksymalną liczbę iteracji w rekurencji. Przerywam.');
      return false;
    }

    // Jeśli folder_id to 0 lub null, dodaj do głównego drzewa
    if (folder_id === 0 || folder_id === null) {
      this.tree.push(object);
      return true;
    }

    let found = false;

    // Iteracja przez elementy w bieżącym drzewie
    for (let i = 0; i < tree.length; i++) {
      const currentElement = tree[i];

      // Sprawdzenie, czy element to folder (bez image_name lub name)
      if (typeof currentElement.image_name === 'undefined' || typeof currentElement.name === 'undefined') {

        // Jeśli to odpowiedni folder, dodaj do niego plik
        if (folder_id === currentElement.id) {
          if (!currentElement['children']) {
            currentElement['children'] = [];
          }

          currentElement['children'].push(object);
          this.i = 0; // Zresetuj licznik iteracji
          return true;
        }

        // Rekurencyjne szukanie w podfolderach, jeśli nie znaleziono w bieżącym
        if (currentElement['children'] && currentElement['children'].length > 0) {
          found = this.addFileToFolderRec(object, folder_id, currentElement['children']);
          if (found) {
            return true; // Jeśli znaleziono, przerwij pętlę
          }
        }
      }
    }

    return found;
  }

  private buildFolderTree() {
    var tree: Array<dynamicTreeStructureClass> = [];
    this.FromDatabade.forEach(element => {


      // @ts-ignore
      if ((element.parent_id == null ||element.parent_id =='') && element.deleted == false) {

        element.deleted = true;
        tree.push(new dynamicTreeStructureClass(element.id, element.name, 0))
      }
    })
    var przebiegi = 0, max = 0, whil = 0, whil_check = 0;
    while (this.FromDatabade.length > 0 && max < this.FromDatabade.length && whil_check <= 5) {
      //! tu trzeba uważać na ostatni warunek, bo niby ogranicza ilość pętli ale może powodować uciannie folderów
      whil_check++;
      max++;
      this.FromDatabade.forEach(element => {

        // console.log(this.FromDatabade)
        if (element.deleted == false) {

          tree.forEach(galaz => {
            var wynik = this.findParent(galaz, element);

            if (wynik == true) {
              przebiegi++;
              element.deleted = true;
              whil_check = 0;
            }
          });
        }
      });
      whil++;
    }
       console.log("WYNIK _________________" + przebiegi+ '   -   '+ whil)
    return tree;
  }
  private findParent(tree: dynamicTreeStructureClass, element): boolean {
    if (tree.id == element.parent_id) {
      element.deleted = true;
      tree.children.push(new dynamicTreeStructureClass(element.id, element.name, element.parent_id));
      return true;
    }
    tree.children.forEach(elementTree => {
      var a = this.findParent(elementTree, element);
      if (a == true) {
        return true;
      }
    });
    return false;
  }


  patch(parent_id, patch = [], name = []): Array<string> {
    if (parent_id == null) {
      patch.reverse();
      name.reverse();

    }
    this.FromDatabade.forEach(element => {
      if (element.id == parent_id) {
        patch.push(element);
        name.push(element.name);
        if (parent_id != null) {
          patch = this.patch(element.parent_id, patch, name)
        }
      }
    });
    return patch;
  }
  returnFolderObject(id): dynamicTreeElementClass {
    if (id == 0) {
      return null;
    }
    return this.FromDatabade.find(a => {
      return a.id == id;
    });
  }

  FolderChangeName(id): Promise<boolean>{
    return new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: 'Podaj nową nazwę folderu:',
        input: 'text',
        text: 'Poprzednia nazwa: ' + this.returnFolderObject(id).name,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Zmień',
        showLoaderOnConfirm: true,

        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
          const toSend = {};
          toSend['name'] = result.value;
        // tslint:disable-next-line:max-line-length
          ApiService.instance.updateInDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), toSend , 'files_tree', ['id'], [id])
              .then(() => {
                Swal.close();
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: 'Nazwa folderu zmieniona pomyślnie',
                  showConfirmButton: false,
                  timer: 2000
                });
                this.init = false;
                this.returnDynamicTreeElements().then(data => {
                  resolve(true);
                }).catch(() => {
                  reject(false);
                });
              }).catch(() => {
              Swal.close();
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Błąd zmiany nazwy folderu',
                showConfirmButton: false,
                timer: 2000
              });
              reject(false);
            });
      });
    });
  }
  deleteFolder(id) {
    return new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: 'Jesteś pewny?',
        text: 'Nie będzie można cofnąć tej operacji!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, usuwam!'
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.instance.deleteInDataBase(SecurityService.instance.safeReturnTokenObjectTokenValue(), 'files_tree', ['id'], [id])
            .then(() => {
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Folder usunięty pomyślnie',
              showConfirmButton: false,
              timer: 2000
            });
            this.init = false;
            this.returnDynamicTreeElements().then(() => {
              resolve(true);
            }).catch(() => {
              reject(false);
            });
          }).catch(() => {
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: 'Błąd usuwania folderu',
              showConfirmButton: false,
              timer: 2000
            });
            reject(false);
          });
        }
      })
    });



  }

  // tslint:disable-next-line:variable-name
  addFolder(parent_id: number | string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const name = this.returnFolderObject(parent_id) == null ? 'brak' : this.returnFolderObject(parent_id)?.name;
      Swal.fire({
        title: 'Podaj nazwę folderu:',
        input: 'text',
        text: 'Folder nadrzędny: ' + name,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Utwórz',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isDismissed == false) {
          const toSend = {};
          if (parent_id != 0) {
            toSend['parent_id'] = parent_id;
          }

          toSend['name'] = result.value;
          toSend['mode'] = this.mode;
          ApiService.instance.addToDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), toSend , 'files_tree').then(() => {
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Folder dodany pomyślnie',
              showConfirmButton: false,
              timer: 2000
            });
            this.init = false;
            this.returnDynamicTreeElements().then(() => {
              resolve(true);
            }).catch(() => {
              reject(false);
            });
          }).catch(() => {
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: 'Błąd dodawania folderu',
              showConfirmButton: false,
              timer: 5000
            });
            reject(false);
          });
        }
      });
    });
  }
}
