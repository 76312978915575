import { ImagesStorageService } from './../../../services/ImageStorage.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientDataService } from 'src/app/services/client-data.service';

@Component({
  selector: 'app-welcome-panel',
  templateUrl: './welcome-panel.component.html',
  styleUrls: ['./welcome-panel.component.scss']
})
export class WelcomePanelComponent implements OnInit {

  constructor(
    public _clientDataService: ClientDataService,
    public _activeModal :NgbActiveModal,
    public _ImageStorage: ImagesStorageService,
  ) { }

  ngOnInit(): void {
  }

}
