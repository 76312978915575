import { Pipe, PipeTransform } from '@angular/core';
import { PipeService } from './pipe.service';

@Pipe({
  name: 'articlePipe'
})
export class ArticlePipe implements PipeTransform {
  constructor(private _pipeService: PipeService){}

  public transform(value: string, type: string) {
    let tmp;
    if(value == null){
      return null;
    }
    switch (type) {
      case 'splitArticle':
      tmp = value.split('<hr />')
      return tmp[0];
      case 'deleteSplitArticle':
      tmp = value.split('<hr />')
      if(tmp[1] == undefined){
        tmp[1] = '';
      }
      return tmp[0] + tmp[1];

      default: throw new Error(`Invalid safe type specified: ${type}`);
      }
  }

}

