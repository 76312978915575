import { galleryPageClass } from 'src/app/object/galleryPageClass';
import { element } from 'protractor';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArticlesClass } from '../object/articleClass';
import { MenuClass } from '../object/headMenuClass';
import { ApiService } from './api/api.service';
@Injectable({
  providedIn: 'root'
})
export class GeneralTemplateService {
  private headerMenuInit = false;
  public headerMenu: Array<MenuClass> = [];
  private articles: Array<ArticlesClass> = [];
  public galleryList_ =  new BehaviorSubject<Array<galleryPageClass>> ([]);
  private articlesInit = false;
  public footer_panel_google_maps_id = null;
  public selectedArticleID = new BehaviorSubject<number>(null);
  constructor(
    private _api: ApiService,
  ) { }
  public resetData(): void {
    this.articlesInit = false;
    this.headerMenu = [];
    this.headerMenuInit = false;
    this.articles = [];
  }
  public returnArticles() {
    return new Promise((resolve, reject) => {
      if (this.articlesInit === false) {
        this.articlesInit = true;
        this._api.GetFromDataBase(null, 'articles', ['status'/*, 'category'*/], ['published'/*, 'Aktualności'*/], [])
          .then(data => {
            data.forEach((element: ArticlesClass) => {
              var tmp = new ArticlesClass(element.id, element.title, element.body, element.status, element.position,
                element.create_time, element.update_time, element.publish_date, element.category,element.gallery_type,
                 element.gallery_images,element.headImage,element.headImageMode);
              this.articles.push(tmp);
            });
            resolve(this.articles);
          }).catch(data => {

            reject(false);
          });
      } else {
        resolve(this.articles);
      }
    });
  }
  public returnGalleryList() {
    return new Promise((resolve, reject) => {
      if (this.articlesInit === false) {
        this.articlesInit = true;
        this._api.GetFromDataBase(null, 'gallery_pages', ['status'/*, 'category'*/], ['published'/*, 'Aktualności'*/], [])
          .then((data:Array<galleryPageClass>) => {
              var tmp = [];
            data.forEach(element=>{
             tmp.push(new galleryPageClass(element.id,element.title,element.images,element.year,element.publish_date,element.status,element.create_time,element.update_time))
            })
            this.galleryList_.next(tmp)
            resolve(true);
          }).catch(data => {

            reject(false);
          });
      } else {
        resolve(false);
      }
    });
  }



  public returnArticleWithID(id) {
    return new Promise((resolve, reject) => {
      this.returnArticles().then((value: Array<ArticlesClass>) => {
        var tmp: Array<ArticlesClass> = null;
        tmp = value.filter((element: ArticlesClass) => {
          return element.id == id;
        });
        if (tmp.length == 0) {
          reject(false);
        }
        if (tmp.length > 1) {
          reject(false);
        }
        resolve(tmp[0]);
      }).catch(value => {
        reject(false);
      });
    });
  }


  public setArticleID(id) {
    this.selectedArticleID.next(id);
  }

  public returnMenu(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.headerMenuInit === false) {
        this.headerMenuInit = true;
        this._api.GetFromDataBase(null, 'header_menu', [], [], [])
          .then((data: Array<MenuClass>) => {
            const database: Array<any> = [];
            data.forEach((element: MenuClass) => {
              const tmp = new MenuClass(element.id, element.name, element.position, element.parent_id, element.mode, element.menuModeValue);
              database.push(tmp);
            });
            database.forEach(element => {   // wpadkowanie submenu do tablic w obiektach menu
              if (element.parent_id == 0) {
                this.headerMenu.push(element);
              }
            });
            database.forEach(element => {
              if (element.parent_id != 0) {
                this.headerMenu.filter(filterElement => {
                  if (filterElement.id == element.parent_id) {
                    filterElement.addchildrenObject(element);
                  }
                });
              }
            });
            resolve(this.headerMenu);
          })
          .catch(value => {
            reject(false);
          });
      } else {
        resolve(this.headerMenu);
      }
    });
  }
  public returnMenuElementWithID(id): MenuClass {
    var tmp;
    this.headerMenu.forEach((head: MenuClass) => {
      if (head.id == id) {
        tmp = head;
      }
      head.children.forEach((children: MenuClass) => {
        if (children.id == id) {
          tmp = children;
        }
      });
    });
    return tmp;
  }
}
