import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
  constructor(private _router: Router, private _security: SecurityService) { }

  canActivate(): boolean {
    if (this._security.returnTokenObject().value == null){ // sptrawdzenie czy token istnieje, jeżeli nie to sprawdzane są ciasteczka
      this._security.checkCookies();
      console.log('from cookies')
    }
    if (this._security.returnTokenObject().value != null && String(this._security.returnTokenObject().value.access) === 'true'){
      const nowDate = new Date().getTime();
      var offset = new Date().getTimezoneOffset();
      let EndTokenLifeDate;
      if(offset == -120){
        EndTokenLifeDate = new Date(this._security.returnTokenObject().value.endTokenLifeDate  + 'GMT-01:00').getTime();
      } else{
        EndTokenLifeDate = new Date(this._security.returnTokenObject().value.endTokenLifeDate).getTime();
      }
      const difference = (EndTokenLifeDate - nowDate) / 1000;
      console.log(difference)
      if (difference < 0){ // sprawdzenie czy token jest ważny, Jeżeli nie następuje wylogowanie
        console.log("Token stracił ważnośćs")
        console.log(difference)
        console.log(this._security.returnTokenObject().value)
       // this._security.logOut();
      }
      return true;
    } else {
      this._router.navigate(['/']);
      return false;
    }
  }


}
