<content class='image'>
  <div class="cross">
    <button type="button" class="close" aria-label="Zamknij podgląd zdjęcia" tabindex="0" (click)="_activeModal.dismiss('Cross click')">
      <span >x</span>
    </button>
  </div>
<img (mouseup)="_activeModal.dismiss('Cross click')"
[src]="_galleryService.galleryStorage.value.returnImage(_galleryService.bigImageViewSrc).src"
    [alt]="_galleryService.galleryStorage.value.returnImage(_galleryService.bigImageViewSrc).alt"
    [title]="_galleryService.galleryStorage.value.returnImage(_galleryService.bigImageViewSrc).title"
[src]="">
</content>
