  <h2 class="category">
   {{categoryTitle}}
    </h2>

<ng-container *ngIf="_ClientDataService.articlesObject.selectedCategoryArticles.length > 0">

  <article class="articles WCAG_con_black "
  *ngFor="let element of  _ClientDataService.articlesObject.selectedCategoryArticles

  | slice: _ClientDataService.articlesObject.category_pageSize
  | slice: 0:_ClientDataService.articlesObject.category_itemsPerPage
  let i = index">



  <section class="top2">
    <h3>
      <a [routerLink]="['/ara/article',element.id]" class="WCAG WCAG_bw_text_black WCAG_con_Title"
      [id]="i == 0? firstElementID:''">
        {{element.title}}</a>
      </h3>
    </section>
    <div class="left" [ngStyle]="{'order':i % 2 == 0 ? 1: 2 }">
      <img   *ngIf="element.headImage != null"
      [src]="_imageStorage.galleryStorage.value.returnImage(element.headImage).src"
      [alt]="_imageStorage.galleryStorage.value.returnImage(element.headImage).alt"
      [title]="_imageStorage.galleryStorage.value.returnImage(element.headImage).title">
      <img class="img2"   *ngIf="element.headImage == null"
      src='../../../../../../assets/orangeSky/info.png'
      alt="Zdjęcie przedstawia biały wykrzyknik, w niebieskim kole"
      style="width:260px">
    </div>
    <div class="rigth WCAG_con_black " [ngStyle]="{'order':i % 2 == 0 ? 2: 1 }">
      <app-predefined-article-information
      [categoryLink]="'/ara/categorieArticles/'"
      [article]=" element"
      [skipToCategory]="'true'">
    </app-predefined-article-information>
    <section class="middle">
      <p><span class="WCAG WCAG_bw_text_black WCAG_con_text"
        [innerHTML]="element.body   | articlePipe:'splitArticle'     | linkRepair : 'fromDataBase'| safe_variable:'html' "></span></p>
      </section>

      <section class="bottom2">
        <a [routerLink]="['/ara/article',element.id]"><span class="WCAG WCAG_bw_text_hover_black WCAG_con_Title" [attr.aria-label]="'Czytaj więcej: '+element.title">
          Czytaj więcej...</span></a>
        </section>
      </div>
    </article>
  </ng-container>

  <div class="navigateArticleCategory">
    <ngb-pagination
    [collectionSize]="_ClientDataService.articlesObject.category_articlesLength"
    [pageSize]="_ClientDataService.articlesObject.category_itemsPerPage"
    [(page)]="_ClientDataService.articlesObject.category_currentPage"
    (pageChange)="
      _ClientDataService.articlesObject.category_onPageChange(
      _ClientDataService.articlesObject.category_currentPage
      )
      "
    ></ngb-pagination>
  </div>
