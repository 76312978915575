import { ImagesClass } from 'src/app/object/gallery.class';
import { ClassTree } from './../../../../../object/treeClass';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, } from '@angular/core';
import { GalleryService } from '../../../../services/gallery.service';
import { ImageSettingsModalComponent } from '../image-settings-modal/image-settings-modal.component';
import { FontAwesomeService } from 'src/app/services/font-awesome.service';


@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss', '../gallery.component.scss']
})
export class GalleryModalComponent implements OnInit {
  selectedImage: Array<ImagesClass> = [];
  constructor(
    public _galleryService: GalleryService,
    public _activeModal: NgbActiveModal,
    public _modal: NgbModal,
    public _fontAwesome: FontAwesomeService
  ) { }

  ngOnInit(): void {
    this._galleryService.returnImagesFromDatabase().then().then(data => {
      // tslint:disable-next-line: max-line-length
      this._galleryService.galleryLastSelectedMultiImageIDInitValue.forEach(id => { // po kolei wyszukiwanie obiejtów zdjęć po id bazy danych
        this.setInitCheckBox(this._galleryService.node2.value, id);
      })
    })
  }

  public openImageSettings(imageId){
    this._galleryService.imageSettingDataBaseID = imageId;
    this._modal.open(ImageSettingsModalComponent)
  }
  setInitCheckBox(data: Array<ClassTree>, dataBaseId) { // wyszukiwarka po idbazy danych objektów zdjęć i wstawianie do zaznaczoncyh zdjęć
    data.forEach(object => {
      if (object.type == undefined) { // kiedy folder
        this.setInitCheckBox(object.children, dataBaseId)
      } else {
        if (object.id == dataBaseId) {
          let tmp = new ImagesClass(object.id);
          tmp.name = object.name;
          this.selectedImage.push(tmp);
        }
      }
    })
    this._galleryService.galleryLastSelectedMultiImageArray_.next(this.selectedImage);
  }
  public CheckBox(mode, data: ClassTree) {
    if (mode == true) { // kiedy dodajemy
      if (data.type == undefined) { // kiedy folder
        data.children.forEach(child => {
          this.CheckBox(mode, child)
        })
      } else { // kiedy zdjecie
        let tmp = new ImagesClass(data.id);
        tmp.name = data.name;
        this.selectedImage.push(tmp);
      }
    } else { // kiedy usuwamy
      if (data.type == undefined) { // kiedy folder
        data.children.forEach(child => {
          this.selectedImage = this.selectedImage.filter(element => {
            return element.id != child.id;
          })
        })
      } else { // kiedy zdjecie
        this.selectedImage = this.selectedImage.filter(element => {
          return element.id != data.id;
        })
      }
    }

    // usuwanie powtarzających się elementów
    this.selectedImage= this.selectedImage.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    })


    this._galleryService.galleryLastSelectedMultiImageArray_.next(this.selectedImage);
  }
  ImCheckedHmm(id): boolean {
    let tmp = this.selectedImage.find(element => {
      return element.id == id;
    })
    if (tmp == undefined) {
      return false;
    } else {
      return true;
    }
  }
  public isFinite(a): boolean {
    return isFinite(a);
  }
  public Round(e): number {
    return Math.round(e);
  }
  public DeleteFromCheckBox(mode ,id){
    this.selectedImage = this.selectedImage.filter(element => {
      return element.id != id;
    })
    this._galleryService.galleryLastSelectedMultiImageArray_.next(this.selectedImage);

  }
}
