import { SecurityService } from 'src/app/services/security/security.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GeneralService } from './../../services/general.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigFileService } from 'src/app/services/config-file.service';

@Component({
  selector: 'app-page-disabled',
  templateUrl: './page-disable.component.html',
  styleUrls: ['./page-disable.component.scss']
})
export class PageDisableComponent implements OnInit,OnDestroy {

  constructor(
    private _generalService: GeneralService,
    private _router: Router,
    private  _configFile : ConfigFileService,
    private _securityService: SecurityService
  ) { }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    if(this._securityService.safeReturnTokenObjectTokenValue() != null){
      console.log("To nie miejsce dla ciebie")
      this._router.navigate(['/']);
    }

    this._generalService.loadDataFromDatabaseComplete.next('pageDisable init');
    //zabezpieczenie bez nie uprawnionym uruchomieniem panelu
      if(this._configFile.ConfigObject.pageDisabled ==  "false"){
         this._router.navigate(['/']);
       }
      }

}
