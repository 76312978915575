import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { CookiesService } from './cookies/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class VisitCounterService {

  constructor(
    private _api: ApiService,
    private _cookies: CookiesService

    ) { }

  returnVisitDate(): Promise<visitClass>
  {
    return new Promise<visitClass>((resolve, reject) => {
      this._api.GetVisits().then(data=>{
     resolve(data['visits'][0]);
      }).catch(()=>{
        reject(-1);
      });
    });
  }

  CookiesVisitCounterAdd(){
    let  a  =this._cookies.CheckCookies("VisitDelay");
        if(a == false){
        const end_datetime = new Date( new Date().getTime() + (1000 * 3600 * 1));// tslint:disable-line
        this._cookies.CreateCookies("VisitDelay",'true',end_datetime)
        if(this._cookies.CheckCookies("VisitDelay")){
          this._api.AddVisits();

        }
      }
  }

  addVisitsToArticle(id: number): void {
      this._api.short('', 'addVisitToArticle/' + id).then(data => {

      }).catch(data => {
          console.log(data);
      });
    }



}


export class visitClass{
  today : number;
  yesterday : number;
  week : number;
  month : number;
  year : number;
  all_ : number;
  constructor(){
    this.today = 0;
    this.year = 0;
    this.month = 0;
    this.week = 0;
    this.yesterday = 0;
  }
  setData(today:number,yesterday:number,week:number,month:number,year:number,all_:number = 0){
    this.today = today;
    this.year = year;
    this.month = month;
    this.week = week;
    this.yesterday = yesterday;
    this.all_ = all_;
  }
}
