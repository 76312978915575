<section  role="main" class="bigPanel WCAG_bw_backGround_black WCAG_bw_border_white WCAG_con_black WCAG_con_Border" >
          <h1 ><span class="WCAG WCAG_c_text WCAG_bw_text_white WCAG_con_text" >Strona wyłączona, prosimy czekać</span></h1>
          <img src="../../assets/icon/sadFennec.png" alt="Zdjęcie przedstawia płaczącego lisa rasy fenek siedząc w kałuży płaczu">
  </section>
  <section class="text"   role="footer"><span  class="WCAG WCAG_c_text WCAG_bw_text_white  "  >Strona stworzona przez
          <a href="https://www.efennec.pl" class="WCAG_bw_text_white WCAG_con_Title" style="color:white"  aria-label="Link kierujący na strone autora strony" >eFennec</a>
          . Wszelkie prawa zastrzeżone &copy;</span>
          <a style="text-align: right;float:right;" [routerLink]="['/admin']" tabindex="-1" aria-label="Link do panelu administratora">
                <span  class="WCAG WCAG_c_text WCAG_bw_text_white  "  >Admin</span></a></section>
  <div class="box_top"></div>
  <div class="box_bottom"></div>
