
<ng-container *ngIf="_clientDataService.articlesObject.articles.length > 0">

  <article class="articles WCAG_con_black "
  *ngFor="let element of  _clientDataService.articlesObject.articles
  | ArticleSort: 'publish_date'
  | slice: _clientDataService.articlesObject.pageSize
  | slice: 0:_clientDataService.articlesObject.itemsPerPage
  let i = index">



  <section class="top2">
    <h3>
      <a [routerLink]="['/'+template+'/article',element.id]" class="WCAG WCAG_bw_text_black WCAG_con_Title">
        {{element.title}}</a>
      </h3>
    </section>
    <div class="left" [ngStyle]="{'order':i % 2 == 0 ? 1: 2 }" *ngIf="element.headImageMode == 1">
      <img  class="img bigImageView" *ngIf="element.headImage != null "
      [src]="_imageStorage.galleryStorage.value.returnImage(element.headImage).Qsrc(40)"
      [alt]="_imageStorage.galleryStorage.value.returnImage(element.headImage).alt"
      [title]="_imageStorage.galleryStorage.value.returnImage(element.headImage).title"
      role="button"
      aria-label="Powiększenie zdjęcia"
      (keypress)="_generalService.FakeMouseClick($event)"
      [id]="'imageInArticle='+i"
      loading="lazy"
      (click)="_generalService.OpenBigImageView(element.headImage)">
      <img class="img2"   *ngIf="element.headImage == null  "
      src='../../../../../../assets/orangeSky/info.png'
      alt="Zdjęcie przedstawia biały wykrzyknik, w niebieskim kole"
      style="width:260px">
    </div>
    <div class="rigth WCAG_con_black " [ngStyle]="{'order':i % 2 == 0 ? 2: 1,'width':element.headImageMode == 0?'100%':'' }">
      <app-predefined-article-information
      [categoryLink]="'/'+template+'/categorieArticles/'"
      [article]=" element"
      [skipToCategory]="'true'">
    </app-predefined-article-information>
    <section class="middle">
      <p><span class="WCAG WCAG_bw_text_black WCAG_con_text "
        [innerHTML]="element.body   | articlePipe:'splitArticle'     | linkRepair : 'fromDataBase'| safe_variable:'html' "></span></p>
      </section>

      <section class="bottom2">
        <a [routerLink]="['/'+template+'/article',element.id]"><span class="WCAG WCAG_bw_text_hover_black WCAG_con_Title" [attr.aria-label]="'Czytaj więcej: '+element.title">
          Czytaj więcej...</span></a>
        </section>
      </div>
    </article>
  </ng-container>

  <section class="navigate">
    <ngb-pagination
    [collectionSize]="_clientDataService.articlesObject.articlesLength"
    [pageSize]="_clientDataService.articlesObject.itemsPerPage"
    [(page)]="_clientDataService.articlesObject.currentPage"
    (pageChange)="
    _clientDataService.articlesObject.onPageChange(
      _clientDataService.articlesObject.currentPage
      )
      "
    ></ngb-pagination>
  </section>
