import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { FennecApiClass } from 'src/app/object/fennecApiClass';
@Injectable({
  providedIn: 'root'
})
export class UpdateSecurityService{
 public update = new BehaviorSubject<boolean>(false);
 public securityLogOut = new BehaviorSubject<boolean>(false);
 public securityTokenObject = new BehaviorSubject<FennecApiClass>(null);

  constructor(

  ) { }

}
