<section class="gallery" >
  <ng-container *ngIf="galleryType == 'grid'">
    <section class="grid">
      <h3 class="WCAG_bw_text_white WCAG_con_Title WCAG">Galeria:</h3>
      <ng-container *ngFor="let id of  galleryImages.split(','); let i = index">
        <img [src]="_galleryService.galleryStorage.value.returnImage(id).Qsrc(30)"
          [alt]="_galleryService.galleryStorage.value.returnImage(id).alt"
          [title]="_galleryService.galleryStorage.value.returnImage(id).title"
          (click)="OpenAdvancedGalleryView(galleryImages, id)"
          (keypress)="FakeMouseClick($event)"
          [id]="'image='+i"
          role="button"
          tabindex="0"
          aria-label="Powiększenie zdjęcia">
      </ng-container>
    </section>
  </ng-container>




  <ng-container *ngIf="galleryType == 'slajder'">
    <section class="carousel ">
   <h3 class="WCAG_bw_text_white  WCAG_con_Title ">   <span class="WCAG" >Galeria:</span></h3>
      <ngb-carousel #carouselGallery class="carouselSmall WCAG_bw_backGround_black WCAG_con_black"
      [interval]="false">
        <ng-template ngbSlide *ngFor="let imageID of galleryImages.split(',') let i = index">
          <div class="picsum-img-wrapper">
            <img class="tets"
              tabindex="0"
              role="button"
              aria-label="Powiększenie zdjęcia"
              (keypress)="_generalService.FakeMouseClick($event)"
              [id]="'image='+i"
              [src]="_galleryService.galleryStorage.value.returnImage(imageID).Qsrc(50)"
              [alt]="_galleryService.galleryStorage.value.returnImage(imageID).alt"
              [title]="_galleryService.galleryStorage.value.returnImage(imageID).title"
              (click)="_generalService.OpenBigImageView(imageID)"



              >


          </div>
          <!--   <div class="carousel-caption">
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>-->
        </ng-template>
      </ngb-carousel>
      <input type="button"  tabindex="0" class=" btn btn-info btn-sm  WCAG_con_black WCAG_con_Border WCAG_con_Title WCAG_con_Title_hover stopSlider focus"
      [value]="carouselStopText" [attr.aria-label]="carouselStopText + ' przewijanie zdjęć'" (click)="carouselStop()">
    </section>
  </ng-container>
</section>
