import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientDataService } from 'src/app/services/client-data.service';
import { GeneralService } from 'src/app/services/general.service';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';

@Component({
  selector: 'app-predefined-article-category-list',
  templateUrl: './predefined-article-category-list.component.html',
  styleUrls: ['./predefined-article-category-list.component.scss']
})
export class PredefinedArticleCategoryListComponent implements OnInit {
 @Input() firstElementID =null;
  public categoryTitle: string;
  private sub: Subscription;
  constructor(
    public _generalService : GeneralService,
    public _ClientDataService:ClientDataService,
    public _imageStorage: ImagesStorageService,
    private _route :ActivatedRoute,
  ) { }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  ngOnInit(): void {
    this._ClientDataService.articlesObject.articleInit().then(() => {
    this.sub = this._route.params.subscribe(params => {
    this._ClientDataService.articlesObject.returnArticleCategory(params['category'])
    this.categoryTitle =this.capitalizeFirstLetter(params['category']);
    });
  });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    }
}

