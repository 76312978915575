<section role="navigation" class="mobileMenuContener"  ><!--  [ngStyle]="{'top':top+'px'}" -->
  <p class="text">
    Menu:

  </p>
  <button

    tabindex="0"
    (click)="toggleMenu()"
    aria-label="Wysuń mobilne menu"
    class="displayButton btn"
  >
    <fa-icon [icon]="_fontAwesome.faBars"></fa-icon>
  </button>
  <div class="menuBlock"  [ngClass]="{ menuBlockToggle: menuState}">
    <span class="menuName">Menu</span>
    <ng-container *ngIf="menuState">
    <a
      class="homeBack WCAG"
      aria-label="Przycisk kierujący na główną stronę"
      [routerLink]="['/']"
      tabindex="0"
      [id]="'menuhome'"
      (keypress)="FakeMouseClick($event)"
    >
      Stona główna
    </a>

    <ul class="menu WCAG_c_hight_minContent">
      <li *ngFor="let element of topMenu | sort: 'position'">
        <a
          *ngIf="length(element.children) == 0"
          tabindex="0"
          [attr.aria-label]="'Przycisk przekierowywujący na: ' + element.name"
          (click)="clickedElement(element.id);element.childOpenToggle()"
          [id]="'menu' + element.id"
          (keypress)="FakeMouseClick($event)"
          class="WCAG "
        >
          <span>
            {{ element.name }}
          </span>
        </a>
        <span
          *ngIf="length(element.children) > 0"
          class="WCAG"
        >
          <a
          type="button"
          tabindex="0"
          aria-label="Przycisk rozwijający menu"
          (click)="element.childOpen = !element.childOpen "
          [id]="'menu' + element.id"
          (keypress)="FakeMouseClick($event)"

          >
            {{ element.name }}
            <fa-icon
              *ngIf="length(element.children) > 0"
              class="triangle"
              [icon]="_fontAwesome.faCaretDown"
            >
            </fa-icon>
          </a>
        </span>
        <div class="childClose"[ngClass]="{'childOpen':element.childOpen}">
        <ol >
          <li
            *ngFor="let subElement of element.children | sort: 'position'"
            class="WCAG"

            >
            <a
              tabindex="0"
              [attr.aria-label]="
                'Przycisk przekierowywujący na: ' + subElement.name
              "
              (click)="clickedElement(subElement.id)"
              [id]="'menu' + subElement.id"
              (keypress)="FakeMouseClick($event)"
            >
              {{ subElement.name }}
            </a>
          </li>
        </ol>
        </div>
      </li>
    </ul>
  </ng-container>
  </div>
</section>
