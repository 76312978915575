import { Injectable, SystemJsNgModuleLoader } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterOutletService {
  private  last = 99999;
  constructor() { }
  onActivate(event){
    this.scrollToTop();
    }



    scrollToTop(a = 10) { // fajny skrypt zapewnia że ekran zawsze przewinie się na górę NAJS
      setTimeout(() => {

        var pos = window.pageYOffset;
        var dif = this.last-pos;
        var i = a;
        if(dif < 0){
       //   console.log("stop")
          this.last = 99999;
          return;
        }

        if(Number(pos) >= 40){
          if(i < 500){
            i+=50;
          }
          this.last = pos;
          window.scroll(0,0)
          this.scrollToTop(i);
          }
        },a);
    }


}
