import { Component, OnDestroy, OnInit } from '@angular/core';
import { FontAwesomeService } from 'src/app/services/font-awesome.service';

@Component({
  selector: 'app-up-arrow-symbol',
  templateUrl: './up-arrow.component.html',
  styleUrls: ['./up-arrow.component.scss']
})
export class UpArrowSumbolComponent implements OnInit, OnDestroy {
  public AngryArrow = false
  constructor(
    public _fontAwesome: FontAwesomeService,
  ) { }
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }
  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }
  scroll = (event): void => {
   // console.log('sda')
    if (window.scrollY > 300) {
      this.AngryArrow = true;
      document.getElementById('arrow').classList.add('WCAGPanel_test')
    } else {
      this.AngryArrow = false;
      document.getElementById('arrow').classList.remove('WCAGPanel_test')
    }
  };
  scrollToTop() {
    //document.getElementById('scrolPoint').scrollIntoView();
      window.scroll(0,0)
  }
}
