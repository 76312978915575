import {WelcomePanelComponent} from './user/modal/welcome-panel/welcome-panel.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImagesStorageService} from 'src/app/services/ImageStorage.service';
import {ApiService} from './services/api/api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SecurityService} from 'src/app/services/security/security.service';
import {Component, OnInit, AfterViewInit, Inject, OnDestroy} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {GeneralService} from './services/general.service';
import {FontAwesomeService} from './services/font-awesome.service';
import {filter} from 'rxjs/operators';
import {LinkTilesClass} from './object/linkTilesClass';
import {DOCUMENT} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {ConfigFileService} from './services/config-file.service';
import {themeVariableClass, ThemeVariablesObjectClass} from './object/colorClass';
import {VisitCounterService} from './services/visit-counter.service';
import {ClientDataService} from './services/client-data.service';

// tslint:disable: triple-equals
// tslint:disable: max-line-length
// tslint:disable: variable-name
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../focus.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(
    private _cookies: CookieService,
    public _generalService: GeneralService,
    private _securityService: SecurityService,
    public _fontAwesome: FontAwesomeService,
    public _clientDataService: ClientDataService,
    private router: Router,
    private _api: ApiService,
    public ImageStorageService: ImagesStorageService,
    private _ConfigFileService: ConfigFileService,
    private _modal: NgbModal,
    private _routerActive: ActivatedRoute,
    private _visit: VisitCounterService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  title = 'schoolWebsite';
  public cookiesSwitch = 'true';
  public cookiesSwitchSecond = 'true';
  public cookiesSwitchHidden = false;
  public WCAGPanelStatus = 'null';
  public loader = false;  // !wyłączam opcje ładowania
  private generalsub: Subscription;
  public leftArrowSwitch = null;
  public ArrowSwitch = false;
  public WCAGPanelDisplay = true;
  public LinkTilesPanelInit = false;
  public LinkTilesPanelInitViewInit = false;
  public rightArrowSwitch = null;
  public LinkTilesPanelDisplay = true;
  public linkTiles: Array<LinkTilesClass> = [];
  private subB: Subscription;
  private FontColorBackUp: Array<EleSaveClass> = [];
  private IntervalC;
  public PageDisable = false;
  public PageDisableRouter = false;
  public titleEnabled = true;
  insertedElement: HTMLElement;
  public color = true;


  points: Array<{ x: number; y: number; }> = [];

  public template = null;
  public templateNameArray = ['', '/blueSky', '/orangeSky', '/bunny', '/angela', '/patio', '/baks', '/ara', '/leth', '/enif'];

  prod;


  public DefaultThemeVariablesObject: ThemeVariablesObjectClass;
  public SelectedThemeVariablesObject: ThemeVariablesObjectClass;

  private fonstSize = 0;
  private customizedfont = false;
  private linksUnderlined = false;
  private WCAGDeleteColor = false;
  private HighContrast = false;
  private WCAGfontChange = false;
  public LinkTilesSwitch = false;

  popUpDisplay = false;

  public BigImageViewFunction = function(imageID, param1, event) {
    GeneralService.instance.OpenBigImageView(imageID);
  };
  public BigImageViewFunctionKey = function(imageID, param1, event2) {
    console.log(event2);
    const KEY_ENTER = 13;
    switch (event2.charCode) {
      case KEY_ENTER:
        GeneralService.instance.OpenBigImageView(imageID);
        break;
    }
  };


  public adminPanelActive = false;

  getRandomInRange(from, to, fixed) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  }

  ngOnDestroy(): void {
    this.generalsub.unsubscribe();
    this.subB.unsubscribe();
    clearInterval(this.IntervalC);

  }

  changeCSSVariable(name: string, value: string) {
    document.documentElement.style.setProperty(name, value);
  }
a;
  ngOnInit(): void {





    if (window.screen.width < 1240) {
      this.LinkTilesSwitch = true;
    }


    this._generalService.loadDataFromDatabaseComplete.next('blueSky init');

    if (this._ConfigFileService.ConfigObject.pageDisabled == 'true') {
      this._generalService.disablePageStatus_.next(true);
      this.PageDisable = true;
    } else {
      this.PageDisable = false;
      this._generalService.disablePageStatus_.next(false);
      this._visit.CookiesVisitCounterAdd();
    }


    this.templateThemeFunction();

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)) // Strażnik ścieżki URL
    .subscribe((s: NavigationEnd) => {
        this.setGreyPage(s.url); // sprawdzenie potrzeby włączenia tryby szarego
        // ustawienia zółtego komunikatu o wyłączonej stronie
        if (s.url.indexOf('pageDisable') >= 1 || s.url.indexOf('admin') >= 1 || s.url.indexOf('pageNotFound') >= 1) {
          this.PageDisableRouter = false;
        } else {
          // okno pop-up
          this._clientDataService.settings_.ReadyDataObservable.subscribe(a => {
            if (this._clientDataService.settings_.welcome_panel_status.Bvalue == true) {
              this.popUp();
            }
          });
          this.PageDisableRouter = true;
        }
        // wyłączenie tytułu na urządzenia mobilne
        if (s.url.indexOf('admin') >= 1) {
          this.adminPanelActive = true;
          this.titleEnabled = false;
        } else {
          this.adminPanelActive = false;
        }

        // * ustawiania panelów LinkTiles
        if (
          s.url.indexOf('pageDisable') >= 1 ||
          s.url.indexOf('patio') >= 1 ||
          s.url.indexOf('angela') >= 1 ||
          s.url.indexOf('admin') >= 1 ||
          s.url.indexOf('pageNotFound') >= 1 ||
          s.url.indexOf('baks') >= 1) {
          this.LinkTilesPanelDisplay = false;
        } else {
          this.LinkTilesPanelDisplay = true;
          // this.LinkTilesPanelDisplay = false;
        }

        // ustawiania panelów WCAG
        if (s.url.indexOf('admin') >= 1 || s.url.indexOf('baks') >= 1) {
          this.WCAGPanelDisplay = false;
        } else {
          this.WCAGPanelDisplay = true;
        }
        // *ochrona panelu administratora przed otwarciem pustej karty
        if (s.url == '/admin/panel') {

          this.router.navigate(['/admin/panel/headpage/' + this.templateNameArray[this._ConfigFileService.ConfigObject.template]]);
        }
      });
    this._cookies.check('cookies') ? this.cookiesSwitch = 'none' : this.cookiesSwitch = 'false';


    this._securityService.dataBaseTokenReturnAnalizerInit(); // inicjalizacja automatycznego kontrolowania życia tokenu, automatycznego wylogowywania
    // inicjalizacja automatycznego  automatycznego wylogowywania jeżeli token straci ważność
    this._securityService.AutoLogOutInit();
    this._securityService.logOutInit(); // inicjalizacja automatycznego  automatycznego wylogowywania jeżeli błąd zapytania


    let i = 0;
    this.IntervalC = setInterval(() => {
      if (window.screen.width > 1280) {
        this.LinkTilesSwitch = false;
      }
      // ! usuwanie stylu color kiedy tryb kontrastu lub czarno biały
      if (this.WCAGDeleteColor == true) {
        const arr = ['p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'a', 'td', 'div'];
        arr.forEach((aaa: string) => {
          const elementsF = document.getElementsByTagName(aaa);
          // tslint:disable-next-line:no-shadowed-variable
          const arrayOfElements = Array.from(elementsF);
          arrayOfElements.forEach((element: HTMLTitleElement) => {
            element.style.color = '';
          });
        });
      }

      if (this.WCAGfontChange == true) {
        const arr = ['p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'a', 'td', 'div'];
        arr.forEach(aaa => {
          const elementsF = document.getElementsByTagName(aaa);
          // tslint:disable-next-line:no-shadowed-variable
          const arrayOfElements = Array.from(elementsF);
          arrayOfElements.forEach((element: HTMLTitleElement) => {
            if (element.style.fontSize != '') {
              element.id = 'Automate_' + i;
              i++;
              this.FontColorBackUp.push(new EleSaveClass(element.id, element.style.fontSize));
              // @ts-ignore
              element.style.fontSize = '';
            }
          });
        });
      }
      if (this.WCAGfontChange == false) {
        const arr = ['p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'a', 'td', 'div'];
        if (this.FontColorBackUp.length > 0) {
          arr.forEach(aaa => {
            const elementsF = document.getElementsByTagName(aaa);
            const arrayOfElements = Array.from(elementsF);
            arrayOfElements.forEach(element => {
              this.FontColorBackUp.forEach(saveElement => {
                if (String(element.id) == String(saveElement.id)) {
                  // @ts-ignore
                  element.style.fontSize = saveElement.last;
                  this.FontColorBackUp = this.FontColorBackUp.filter(el => {
                    return el.id != element.id;
                  });
                }
              });
            });
          });
        }
      }


      // ! naprawienie aria-label w teksie !!!!!!!!!!!!!!!!!!!!!!!!!
      const Fileselements = document.getElementsByTagName('a');
      const FileseArrayOfElements = Array.from(Fileselements);
      FileseArrayOfElements.forEach(element => {
        const href = element.getAttribute('href');
        if (href != null) {
          const link = href.match(/getFile\/[0-9]{1,}/);

          if (link != null) {
            const id = link[0].match(/[0-9]{1,}/)[0];
            const aria = element.getAttribute('aria-label');
            if (aria == null) {

              element.setAttribute('aria-label', 'pobieranie');
              this._api.short('', 'GetFileInformation/' + id).then(data => {
                // @ts-ignore
                element.setAttribute('aria-label', 'Pobierz plik o nazwie: ' + data.data.name);
              });
            }
          }
        }
      });
      // ! PODSTAWIANIE SRC W ZDJĘCIACH !!!!!!!!!!!!!!!!!!!!!!!!!
      const elements = document.getElementsByTagName('img');
      const arrayOfElements = Array.from(elements);
      // if(arrayOfElements.length != 0 )
      arrayOfElements.forEach(element => {
        const x1 = Number(element.alt.indexOf('%__#%eFennec#%#%='));
        const x1_bigView = Number(element.alt.indexOf('%_BigView_#%eFennec#%#%='));
        const x2 = Number(element.alt.indexOf('=%#eFennec%#%__#%'));
        if ((x1_bigView >= 0 || x1 >= 0) && x2 >= 0) {
          this._generalService.loadImageComplete_.value.push(new Promise((resolve, reject) => {
          }));
          const tmp1 = element.alt.split('=%#eFennec%#%__#%')[0];
          const altTxt = element.alt.split('=%#eFennec%#%__#%')[1];
          // wyczyszenie alt
          element.alt = '';
          const idBigView = tmp1.split('%_BigView_#%eFennec#%#%=')[1];
          const id_ = tmp1.split('%__#%eFennec#%#%=')[1];
          let id;
          if (idBigView != undefined) {
            id = idBigView;
          } else if (id_ != undefined) {
            id = id_;
          }
          if (id != 'undefined') {

            // uzupełnienie parametru SRC
            element.src = this._ConfigFileService.ConfigObject.ApiLinkGetImage + id;

            // Jeżeli zdjęcie posiada już pobrane dane
            if (this.ImageStorageService.galleryStorage.value.returnImage(id).dataReady_.value == true) {
              element.alt = this.ImageStorageService.galleryStorage.value.returnImage(id).alt;
              element.title = this.ImageStorageService.galleryStorage.value.returnImage(id).title;
            } else { // jeżeli zdjęcie nie posiada pobranych danych, i te dane muszą zostać zaciągnięte z bazy danych
              const a = this.ImageStorageService.galleryStorage.value.returnImage(id).dataReady_.subscribe(ready => {


                if (ready == true && element.alt.length == 0) {
                  element.alt = this.ImageStorageService.galleryStorage.value.returnImage(id).alt;
                  element.title = this.ImageStorageService.galleryStorage.value.returnImage(id).title;
                  a.unsubscribe();
                }
              });
            }


            if (idBigView != undefined) {
              element.classList.add('bigImageView');
              element.classList.add('maxImageSizeInArticle');
              // element.setAttribute("role","button")
              element.classList.add('articleImageBorder');
              element.setAttribute('tabindex', '0');
              element.addEventListener('keypress', this.BigImageViewFunctionKey.bind(event, id, 'none'), true);
              element.addEventListener('click', this.BigImageViewFunction.bind(event, id, 'none'), true);
              // element.focus(t)
            }
          }
        }
      });

    }, 200);

    this.returnLinkTiles();
  }

  templateThemeFunction(): void {
    const promises = [];
    this._generalService.selectedTemplate_.next(this._ConfigFileService.ConfigObject.template);
    this.template = this.templateNameArray[this._ConfigFileService.ConfigObject.template]; // ustawnie nazwy szablonu
    this.DefaultThemeVariablesObject = new ThemeVariablesObjectClass(Number(this._ConfigFileService.ConfigObject.template), Number(this._ConfigFileService.ConfigObject.defaultTheme));
    this.SelectedThemeVariablesObject = new ThemeVariablesObjectClass(Number(this._ConfigFileService.ConfigObject.template), Number(this._ConfigFileService.ConfigObject.theme));
    // pobranie wartości do podstawych zmiennych kolorystycznych
    promises.push(new Promise<boolean>((resolve, reject) => {
        this.DefaultThemeVariablesObject.returnColorForTheme().then(data => {
          resolve(true);
        }).catch(() => {
          reject(false);
        });
      })
    );
    // pobranie wartości do dodatkowo wybranego szablonu
    promises.push(new Promise<boolean>((resolve, reject) => {
        this.SelectedThemeVariablesObject.returnColorForTheme().then(data => {
          resolve(true);
        }).catch(() => {
          reject(false);
        });
      })
    );
    // ustawianie kolorów
    Promise.all(promises).then(() => {
      let i = 0;
      this.color = true; // ustawienie kolorów
      this.DefaultThemeVariablesObject.array.forEach((element: themeVariableClass) => {
        const tmp = this.SelectedThemeVariablesObject.array.find(secondElement => {
          return element.variable_id == secondElement.variable_id;
        });
        if (tmp.variablevalue.length > 0) { // jeśli istnieje alternatywna wersja w dodatkowym template
          // console.log(element.variableName +" / "+tmp.variableName +" = "+ tmp.variablevalue)
          this.changeCSSVariable(element.variableName, tmp.variablevalue);
        } else {// podstawowa wartość zmiennej
          this.changeCSSVariable(element.variableName, element.variablevalue);
        }
        i++;
      });
    });
  }


  loadStyle(styleName: string, styleID: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById(
      styleID
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = styleID;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;
      head.appendChild(style);
    }
  }

  public img(a) {
    console.log(a);
  }

  WCAGPanelSwitch(): void {
    if (this.WCAGPanelStatus == 'true') {
      this.WCAGPanelStatus = 'false';
    } else {
      this.WCAGPanelStatus = 'true';
    }

  }

  wheelChariMouseenter(value): void {
    this.ArrowSwitch = value;
  }

  ngAfterViewInit(): void {
    this.LinkTilesPanelInitViewInit = false;
  }

  wcag(style) {

    switch (style) {
      case 'font-':
        if (this.fonstSize > 1) {
          this.fonstSize -= 1;
          this.loadStyle('WCAGFont_' + this.fonstSize + '.css', 'wcag_D');
          this.WCAGfontChange = true;
        } else {
          this.WCAGfontChange = false;
          this.loadStyle('empty.css', 'wcag_D');
        }
        break;
      case 'font+':
        if (this.fonstSize < 5) {
          this.fonstSize += 1;
        }
        this.WCAGfontChange = true;
        this.loadStyle('WCAGFont_' + this.fonstSize + '.css', 'wcag_D');
        break;
      case 'customizedfont':
        if (this.customizedfont == false) {
          this.loadStyle('WCAGCustomizedFont.css', 'wcag_B');
          this.customizedfont = true;
        } else {
          this.loadStyle('empty.css', 'wcag_B');
          this.customizedfont = false;
        }
        break;
      case 'highContrast':
        this.WCAGDeleteColor = true;

        if (this.HighContrast == false) {
          this.loadStyle('WCAGHighContrast.css', 'wcag_C');
          this.HighContrast = true;
        } else {
          this.loadStyle('empty.css', 'wcag_C');
          this.HighContrast = false;
        }
        break;
      case 'linksUnderlined':
        if (this.linksUnderlined == false) {
          this.loadStyle('WCAGLinksUnderlined.css', 'wcag_A');
          this.linksUnderlined = true;
        } else {
          this.loadStyle('empty.css', 'wcag_A');
          this.linksUnderlined = false;
        }

        break;
      case 'blackWhite':
        this.WCAGDeleteColor = true;
        this.loadStyle('WCAGBlackWhite.css', 'wcag_C');
        break;
      default:
        this.fonstSize = 0;
        this.loadStyle('empty.css', 'wcag_A');
        this.loadStyle('empty.css', 'wcag_B');
        this.loadStyle('empty.css', 'wcag_C');
        this.loadStyle('empty.css', 'wcag_D');
        this.WCAGDeleteColor = false;
        this.WCAGfontChange = false;
        break;
    }

    if (this.fonstSize > 0) {
      this.loadStyle('WCAGFont_general.css', 'wcag_H');
    } else {
      this.loadStyle('empty.css', 'wcag_H');
    }
    if (this.customizedfont == true || this.HighContrast == true) {
      this.WCAGuse = true;
    } else {
      this.WCAGuse = false;
    }
    this.setGreyPage(null);
  }

  public WCAGuse = false;

  AcceptCookies(): void {
    const endDatetime = new Date(new Date().getTime() + (1000 * 15768000));
    this._cookies.set('cookies', 'true', endDatetime);
    this.cookiesSwitchSecond = 'none';
    this.cookiesSwitchHidden = true;
  }


  setFocus(id) {
    document.getElementById(id).focus();
  }

  toggleLinkTiles() {
    this.LinkTilesSwitch = !this.LinkTilesSwitch;
  }


  public sheet;
  public greyScale = false;

  setGreyPage(isAdmin = null): void {
    this.sheet = document.createElement('style');
    if (isAdmin != null) {
      if (isAdmin.indexOf('admin') >= 1) { // wyłączenie trybu żałoby w panelu administratora
        this.sheet.innerHTML = '.grey{ filter: grayscale(0) !important;}';
        document.body.appendChild(this.sheet);
        return;
      }
    }
    if (this.WCAGuse) {
      if (this.greyScale == true) { // wyłączenie trybu szarego w przypadku włączenia trybu kontrastu
        this.sheet.innerHTML = '.grey{ filter: grayscale(0) !important;}';
        document.body.appendChild(this.sheet);
        return;
      }
    }
    this._clientDataService.settings_.ReadyDataObservable.subscribe(a => { // gdy są dane z bazy danych
      if (a == true) {
        if (this._clientDataService.settings_.greyScale.value == 1) { // gdy data pasuje
          if (this._clientDataService.settings_.greyScale_start.value * 1000 - new Date().getTime() < 0 &&
            this._clientDataService.settings_.greyScale_stop.value * 1000 - new Date().getTime() > 0
          ) {
            this.greyScale = true;
            this.sheet.innerHTML = '.grey{ filter: grayscale(1) !important;}';
            document.body.appendChild(this.sheet);
          }
        }
      }
    });
  }

  public returnLinkTiles(): Promise<boolean> {
    this.linkTiles = [];
    return new Promise((resolve, reject) => {
      this._api.GetFromDataBase(null, 'link_tiles', [], [], []).then(data => {
        data.forEach((element: LinkTilesClass) => {
          this.linkTiles.push(new LinkTilesClass(element.id, element.position, element.title, element.image_id, element.href, element.alt));
        });
        this.LinkTilesPanelInit = true;
        resolve(true);
      }).catch(data => {
        console.log('Link tiles get data error');
        reject(false);
      });
    });
  }

  public FakeMouseClick(event) {
    const KEY_ENTER = 13;
    const KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
        document.getElementById(event.target.id).click();
        break;
      }
    } // end switch
    return true;
  }

  private popUp(): void {
    if (this.popUpDisplay) {
      return;
    }
    this.popUpDisplay = true;
    // sprawdzanie czy okno pop jest aktywne w tym czasie
    let tm = [];
    const time_1 = this._clientDataService.settings_.welcome_panel_date_from_1.value.replace('-', ',').replace('-', ',') + ',' + this._clientDataService.settings_.welcome_panel_date_from_2.value.replace(':', ',');
    tm = time_1.split(',');
    const t1 = new Date(tm[0], tm[1] - 1, tm[2], tm[3], tm[4]).getTime();
    const time_2 = this._clientDataService.settings_.welcome_panel_date_to_1.value.replace('-', ',').replace('-', ',') + ',' + this._clientDataService.settings_.welcome_panel_date_to_2.value.replace(':', ',');

    tm = time_2.split(',');
    const t2 = new Date(tm[0], tm[1] - 1, tm[2], tm[3], tm[4]).getTime();
    // czy czsasu po terażniejszym
    const now = new Date().getTime();
    if (t1 - new Date().getTime() > 0 || t2 - new Date().getTime() < 0) {
      return;
    }
    switch (this._clientDataService.settings_.welcome_panel_mode.value) {
      case 'one':
        const stat = this._cookies.check('popUpPanel_size');
        if (stat == false) { // jeżeli ciasteczko nie istnieje
          this._modal.dismissAll();
          this._modal.open(WelcomePanelComponent, {size: 'lg'});
          const data = new Date().getTime() + (1000 * 60 * 60 * 24 * 7);
          this._cookies.set('popUpPanel_size', this._clientDataService.settings_.welcome_panel.value.length, new Date(data));
        }
        if (stat == true) { // jeśli ciasteczko isnieje
          const cookiesSize = this._cookies.get('popUpPanel_size');
          if (cookiesSize == this._clientDataService.settings_.welcome_panel.value.length) { // jeżeli to jest to samo okno pop_up
          } else {
            this._modal.dismissAll();
            this._modal.open(WelcomePanelComponent, {size: 'lg'});
            const data = new Date().getTime() + (1000 * 60 * 60 * 8);
            this._cookies.set('popUpPanel_size', this._clientDataService.settings_.welcome_panel.value.length, new Date(data));
          }
        }
        break;
      case 'more':
        this._modal.dismissAll();
        this._modal.open(WelcomePanelComponent, {size: 'lg'});
        break;
    }
  }
}

export class EleSaveClass {
  id: string;
  last: string;

  constructor(id: string, last: string) {
    this.id = id;
    this.last = last;
  }
}
