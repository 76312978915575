import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClientDataService } from 'src/app/services/client-data.service';
import { GeneralService } from 'src/app/services/general.service';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';

@Component({
  selector: 'app-predefined-simple-article-component',
  templateUrl: './predefined-article-component.component.html',
  styleUrls: ['./predefined-article-component.component.scss']
})
export class PredefinedArticleComponent implements OnInit,OnDestroy {
  @Input() template = "ara";
  constructor(
    public _clientDataService: ClientDataService,
    public _imageStorage: ImagesStorageService,
    public _generalService:GeneralService
  ) { }


  ngOnInit(): void {
    this._clientDataService.ArticleInit();
  }
  ngOnDestroy(): void {
    }
}
