import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ConfigFileService } from '../config-file.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private _HttpService: HttpClient,
    private _ConfigFileService: ConfigFileService) { }

   GetFromHome(httpOptions, param = ''): Observable<any> {
      return this._HttpService.get(this._ConfigFileService.ConfigObject.homeApiUrl+ param, httpOptions);
    }

  Get(httpOptions, param = ''): Observable<any> {
    return this._HttpService.get(this._ConfigFileService.ConfigObject.ApiLink + param, httpOptions);
  }

   Post(httpOptions, JSON, notRequiredUrl = ''): Observable<any> {
     return this._HttpService.post(this._ConfigFileService.ConfigObject.ApiLink +'/'+ notRequiredUrl, JSON, httpOptions);
   }
   Patch(httpOptions,param = '', JSON): Observable<any> {
     return this._HttpService.patch(this._ConfigFileService.ConfigObject.ApiLink + param, JSON, httpOptions);
   }
   Put(httpOptions, JSON): Observable<any> {
     return this._HttpService.put(this._ConfigFileService.ConfigObject.ApiLink, JSON, httpOptions);
   }
   Delete(httpOptions, param): Observable<any> {
     return this._HttpService.delete(this._ConfigFileService.ConfigObject.ApiLink + '/' + param, httpOptions);
   }
   UploadFile(httpOptions, uploadData, functionMode): Observable<any> {
    return this._HttpService.post(this._ConfigFileService.ConfigObject.ApiLink + functionMode,  uploadData, httpOptions);
  }
  short(httpOptions, param = ''): Observable<any> {
    return this._HttpService.get(this._ConfigFileService.ConfigObject.ApiLink + param, httpOptions);
  }
  shortFromHome(httpOptions, param = ''): Observable<any> {
    return this._HttpService.get(this._ConfigFileService.ConfigObject.homeApiUrl + param, httpOptions);
  }
}
