import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'RepairLastCharPipe'
})
export class RepairLastCharPipe implements PipeTransform {
  constructor(){}
  transform(value: any) {
   value= value.replace(/\s([a-z])\s/g, ' $1&nbsp;');
   return value;
}


}

