import { GeneralService } from './../general.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TemplateGuardService implements CanActivate {
  constructor(private _generalService: GeneralService, private _router: Router) {
  }
  public prod;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.prod = environment.production; // Logs false for default environment
    let id = route.data["templateID"][0];
    if (this._generalService.selectedTemplate_.value == null) {
      //if (this.prod == true) {
        console.log("backl")
        this._router.navigate(['/']);
        return false;
     // } else {
      //  console.log("Wejście w wersji produkcyjnej")
     //   return true;
   //   }

    }
    if (id == this._generalService.selectedTemplate_.value) {
      return true
    } else {

    //  if (this.prod == true) {
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Bład wybranego szablonu 2',
          showConfirmButton: false,
          timer: 3000
        });
        this._router.navigate(['/']);
        return false;
   /*   } else {
        console.log("Wejście w wersji produkcyjnej")
        return true;
      }*/

    }
    return false;
  }

}
