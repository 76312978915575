import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { galleryPageClass } from 'src/app/object/galleryPageClass';
import { ClientDataService } from 'src/app/services/client-data.service';
import { GeneralService } from 'src/app/services/general.service';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';
import { GalleryAdvancedViewComponent } from '../../modal/gallery-advanced-view/gallery-advanced-view.component';

@Component({
  selector: 'app-predefined-gallery',
  templateUrl: './predefined-gallery.component.html',
  styleUrls: ['./predefined-gallery.component.scss'],
  encapsulation: ViewEncapsulation. ShadowDom
})
export class PredefinedGalleryComponent implements OnInit {
  @Input() firstElementID =null;
  private sub: Subscription;
  public gallery: galleryPageClass = null;
  public imageReady = false;
  constructor(
     private _route: ActivatedRoute,
     public _ClientDataService: ClientDataService,
     public _generalService: GeneralService,
     private _modal: NgbModal,
     public _ImageStorage: ImagesStorageService) { }

  ngOnInit(): void {
    this._ClientDataService.galleryObject.returnFormDataBase().then(()=>{
      console.log( this._ClientDataService.galleryObject)
      this.sub = this._route.params.subscribe(params => {
        this._ClientDataService.galleryObject.SelectGalleryImages(params['id'])
        this.imageReady = true;
      });
    })
  }
  ngOnDestroy(): void{
    this.sub.unsubscribe();
  }
  OpenAdvancedGalleryView(imagesID: string, selectedImageID:any){
    this._generalService.AdvancedGalleryViewActualImageID = selectedImageID;
    this._generalService.AdvancedGalleryViewImageID = imagesID.split(',');
    this._modal.open(GalleryAdvancedViewComponent,{windowClass:'center-modal'})
  }
}
