import { Pipe, PipeTransform } from '@angular/core';
import { PipeService } from './pipe.service';

@Pipe({
  name: 'MaxWordsSize'
})
export class MaxWordsSizePipe implements PipeTransform {
  constructor(private _pipeService: PipeService) { }
  transform(value: string, mode: string, max?: number, elem?: Element) {
    switch (mode) {
      case 'letter':
        var tmp = value.slice(0, max);
        if (tmp.length < max) {
          return tmp;
        } else {
          tmp = tmp + '...';
          return tmp;
        }
        case'width':
        console.log(document.getElementById(String(elem)).clientWidth)
        return value;

        default:
      throw new Error(('Pipe Error(MaxWordsSizePipe), bad mode'));
      break;
    }
  }


}

