import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-author-s-signature',
  templateUrl: './author-s-signature.component.html',
  styleUrls: ['./author-s-signature.component.scss']
})
export class AuthorSSignatureComponent implements OnInit {
  @Input()  PageMap: string;
  constructor(
    private _router: Router
  ) { }
    public year;
  ngOnInit(): void {
this.year = new Date().getFullYear();
  }
  ToAdmin(){
    this._router.navigate(['/admin'])
  }
  ToPageMap(){
    this._router.navigate([this.PageMap])
  }
  public FakeMouseClick(event){
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
          document.getElementById(event.target.id).click();
        break;
      }
    } //end switch
    return true;
  }
}
