import Swal from 'sweetalert2';
import { ApiService } from '../services/api/api.service';
// tslint:disable:variable-name
export class subPageClass {
  id: number;
  title: string;
  body: string;
  status: string;
  position: number;
  create_time: Date;
  update_time: Date;
  publish_date: Date;
  gallery_type: string;
  gallery_images: string;
  constructor(id: number, title: string, body: string, status: string, position: number,
              create_time: Date, update_time: Date, publish_date: Date,
              gallery_type_: string, gallery_images: string) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.status = status;
    this.position = position;
    this.create_time = create_time;
    this.update_time = update_time;
    this.publish_date = publish_date;
    this.gallery_type = gallery_type_;
    this.gallery_images = gallery_images;
  }

}
export class subPageObjectClass {
  public subPages: Array<subPageClass> =  [];
  public SelectedSubPages = new subPageClass(0, '', '', '', 0, new Date(), new Date(), new Date(), null, null);
  private subPagesInit;
  constructor(){
    this.subPagesInit = false;
  }

  public SubPagesInit(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.subPagesInit){
        resolve(true);
        return null;
      }
      this.subPagesInit = true;
      ApiService.instance.GetFromDataBase(null, 'subpages', ['status'], ['published'], [])
          .then(data => {

            data.forEach((element: subPageClass) => {
              let tmp = new subPageClass(element.id, element.title, element.body, element.status, element.position,
                element.create_time, element.update_time, element.publish_date, element.gallery_images, element.gallery_images);
              this.subPages.push(tmp);
            });
            resolve(true);

          }).catch(data => {
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: 'Ładowanie podston zwróciło błąd połączenia z bazą danych',
              showConfirmButton: false,
              timer: 3000
            });
            reject(false);
          });
    });

  }
  returnSubPagesWithID(id): Promise<boolean>{
    return new Promise((resolve, reject) => {
    ApiService.instance.GetFromDataBase(null, 'subpages', ['id'], [id], [])
    .then((data: Array<subPageClass>) => {
      if (data.length  != 0 && data[0].status == 'published'){
      } else {
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'info',
          title: 'Podstrona nie istnieje, lub nie jest publiczna',
          showConfirmButton: false,
          timer: 4000
        });
      }

      this.SelectedSubPages = new subPageClass(data[0].id, data[0].title, data[0].body, data[0].status, data[0].position, data[0].create_time, data[0].update_time, data[0].publish_date, data[0].gallery_type, data[0].gallery_images);

      resolve(true);
    });
  });
  }

}
