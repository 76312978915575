<div class="modal-header">
  <h4 class="modal-title">Galeria zdjęć</h4>
  <button type="button" class="close" aria-label="Close" (click)="_activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<content class="MenudGalleryPanel">
  <table>
    <tr>
      <th class="menuBlock">
        Dodawanie zdjęcia do galerii
      </th>
      <th class="menuBlock">
        Lista zdjęc do wysłania:
      </th>
    </tr>
    <tr>
      <td class="menuBlock">
        <table>
          <tr>
            <td colspan="2">
              <div class="custom-file" style="width: 356px;margin-bottom: 7px;">
                <input type="file"  class="custom-file-input" (change)="_galleryService.onFileChangedMulti($event)" multiple>
                <label class="custom-file-label" for="validatedCustomFile">Wybierz pliki...</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="admin-input-inline">
                <label class=""  style="width: 356px;"> Mksymalny rozmiar 4MB	</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="admin-input-inline">
                <label class="w200">Ilość wybranych zdjęć</label>
                <input type="text" [disabled]="true" value="{{_galleryService.ImageUploadArray_.value.length}}" class="w150 form-control btn btn-sm btn-info btn-group-sm" >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="admin-input-inline">
                <label class="w200">Resetuj listę zdjęć: 	</label>
                <input type="button" value="Reset" class="w150 form-control btn btn-sm btn-info btn-group-sm"
                (mouseup)="_galleryService.ResetMultiImageArray()"
                [disabled]="!_galleryService.MultiImageButtonResetUpload_.value">
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <div class="admin-input-inline">
                <label class="w200">Zarządzanie:</label>
                <input class="w150 form-control  btn btn-sm btn-success btn-group-sm"type="button" value="Wyślij zdjęcie" (mouseup)="_galleryService.StartMultiImageUpload()"
                *ngIf="_galleryService.MultiImageButtonStartUplaod_.value">
                <input class="w150 form-control  btn btn-sm btn-info btn-group-sm" type="button" value="Anuluj wysyłanie" (mouseup)="_galleryService.PauseMultiImageUpload()"
                *ngIf="_galleryService.MultiImageButtonPauseUpload_.value">
                <input type="button" value="Wznów" class="btn btn-sm btn-warning btn-group-sm"
                (mouseup)="_galleryService.ReturnMultiImageUpload()"
                *ngIf="_galleryService.MultiImageButtonReturnUpload_.value">


              </div>
            </td>
          </tr>
        </table>
      </td>
      <td class="menuBlock ImagesList">
        <ol>
          <li *ngFor="let img of _galleryService.ImageUploadArray_.value">{{img.name | MaxWordsSize: 'letter' : 17}}</li>
        </ol>
      </td>
    </tr>
  </table>
</content>

<content class="galleryPanel">
  <div>

  <h4>Lista zdjęć</h4>
  <p>Wybrany folder:
    <span *ngFor="let item of _galleryService.tree.patch(_galleryService.selectedFolder.value); let last = last">

      <b [ngStyle]="{'color':last?'green':'black'}">{{item.name}}</b>

      &nbsp;
      <fa-icon *ngIf="!last "[icon]="_fontAwesome.faRightArrow"></fa-icon>
      &nbsp;

  </span> <input type="button" value="Reset" (click)="_galleryService.selectedFolder.next(0);"></p>
    <tree-root   [nodes]="_galleryService.node2.value"  [options]="_galleryService.Treeoptions">
      <ng-template #treeNodeTemplate let-node let-index="index">
        <ng-template #popContent>
          <img  [src]="''" [alt]="'%__#%eFennec#%#%='+node.data.id+'=%#eFennec%#%__#%Błąd zdjęcia'"
            style="width: 260px;max-height: 200px">
          <span style="width: 200px;">
            {{ node.data.create_time}}
          </span>
        </ng-template>
        <!-- Tryb:
galleryWorkMode='defualt' = kopiuje adres url do zdjęcia




galleryWorkMode='detID' = zwraca ID zdjęcia do galleryLastSelectedImageID w galklery serivce
-->
        <div class="tree-element" appendToBody=true [ngbPopover]="popContent" [autoClose]="'outside'" placement="top"
          container="body" triggers="mouseenter:mouseleave" [disablePopover]="node.data.type != 'image'"
          popoverTitle="Podgląd zdjęcia: {{ node.data.size | sizePipe:'auto' }}">
          <div [ngClass]="{'folder':node.data.type != 'image', 'selectedFolder':node?.data.id == _galleryService.selectedFolder.value}" class="test"
            (mouseup)="node.data.type == 'image' && _galleryService.galleryWorkMode == 'default' ?_galleryService.copyToClipboard('https://elearningapi.efennec.pl/index.php/getImage/'+ node.data.id):''"
            (mouseup)="node.data.type != 'image' ?_galleryService.SelectImageFolder(node):''"
            (mouseup)="node.data.type == 'image' && _galleryService.galleryWorkMode == 'getID' ? _galleryService.galleryLastSelectedImage_.next(node.data) :null">
            <ng-container *ngIf="_galleryService.galleryWorkMode == 'getMultiID'">
              &nbsp;&nbsp;&nbsp;<input type="checkbox" (change)="CheckBox($event.target.checked,node.data)" [checked]="ImCheckedHmm(node.data.id)">&nbsp;
            </ng-container>
            <img *ngIf="node.data.type == 'image'"  [src]="''" [alt]="'%__#%eFennec#%#%='+node.data.id+'=%#eFennec%#%__#%Błąd zdjęcia'" class="ImageIcon">
            <ng-container *ngIf="node.data.type != 'image'">
              <span style="padding-left: 9px;">
              {{ node.data.name | MaxWordsSize:'letter':40 }}
                <button type="button" (click)="_galleryService.ChangeNameFolder(node.data.id)" style="background:transparent ;border: 0;">
                   <fa-icon [icon]="_fontAwesome.faPen"></fa-icon>
                  </button>
            </span> </ng-container>
            <ng-container *ngIf="node.data.type == 'image'">
              {{ node.data.name | MaxWordsSize:'letter':40 }}</ng-container>
          </div>
          <input *ngIf="node.data.type == 'image'" (click)="openImageSettings(node.data.id)" style="display: block;position: absolute; right:35px ;top:0px; height: 30px;" type="button" value="opcje" class="btn btn-sm btn-outline-info ">
          <span *ngIf="node.data.type == 'image'" class="deleteImageIcon"
            style="display: block;position: relative; top:-30px"
            (mouseup)="_galleryService.DeleteImage(node.data.id)">x</span>
        </div>
        <span *ngIf="node.data.type != 'image'" class="deleteImageIcon"
        style="display: block;position: relative; top:-30px"
        (click)="_galleryService.addFolder(node.data.id)"
       >+</span>
       <span *ngIf="node.data.type != 'image'" class="deleteImageIcon"
       style="display: block;position: relative; top:-30px"
       (click)="_galleryService.deleteFolder(node.data.id)"
      >-</span>
      </ng-template>
    </tree-root>
  </div>
</content>
<content class="SelectedlistFileBlock"  *ngIf="_galleryService.galleryWorkMode == 'getMultiID'">
    <h5>Lista wybranych zdjęć</h5>
  <content class="list">


    <ol cdkDropList>
      <li    cdkDrag *ngFor="let image of selectedImage" class="selectedImageList">

        <img  [src]="''" [alt]="'%__#%eFennec#%#%='+image.id+'=%#eFennec%#%__#%Błąd zdjęcia'" class="ImageIcon" >
        <span style="padding-left: 9px;">
          {{ image.name | MaxWordsSize:'letter':40 }}
          <input (click)="openImageSettings(image.id)"  type="button" value="opcje" class=" optionButton btn btn-sm btn-outline-info ">
          <span  class="deleteImageIcon"
          style="display: block;position: relative; right: 0;"
          (mouseup)="DeleteFromCheckBox(false,image.id)">x</span>
        </span>

      </li>
    </ol>
  </content>
    <h5>Statystyki</h5>
    <p>Liczba wybranych zdjęć: {{selectedImage.length}}</p>
    <input type="button" value="Zapisz" class="btn btn-success" (click)="_galleryService.galleryLastSelectedMultiImageIDArrayENDBUTTTON_.next(true)">
</content>

<div class="modal-footer">

</div>
