import { GeneralService } from 'src/app/services/general.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SecurityService } from './security.service';
import { ConfigFileService } from '../config-file.service';

@Injectable({
  providedIn: 'root'
})
export class PageDisableGuardService implements CanActivate {
  constructor(
    private _router: Router,
     private _securityService: SecurityService,
     private _configFile: ConfigFileService,
     private _generalService : GeneralService) { }

  canActivate(): boolean {
   //return true

    if(this._securityService.safeReturnTokenObjectTokenValue() != null){
      //console.log("Wpusc bo admin")
      return true;
    }



    if(this._configFile.ConfigObject.pageDisabled  == "true"){
  //   console.log("niew Wolno")
      this._router.navigate(['/pageDisable'])
      return false;
    } else if(this._configFile.ConfigObject.pageDisabled == "false"){
 //   console.log("Wpusc")
      return true;
    }
  }
}
