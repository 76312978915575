export class MenuClass {
  id: number;
  name: string;
  position: number;
  parent_id: number;
  children: Array<MenuClass> = [];
  mode: string;
  menuModeValue: string;
  nameModifyMode = false;
  nameModifyModeChanged;
  newRecord;
  deleted;
  childOpen;
  childOpenDelay;
  constructor(id: number, name: string, position: number, parent_id: number, mode: string,menuModeValue: string) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.parent_id = parent_id;
    this.nameModifyModeChanged = false;
    this.mode = mode;
    this.menuModeValue = menuModeValue;
    this.newRecord = false;
    this.deleted = false;
    this.childOpenDelay = false;
  }
  public childOpenToggle(){
    this.childOpen = true;
    setTimeout(() => {
      this.childOpenDelay = true;
    }, 1);
  }
  public setNewRecordAsTrue() {
    this.newRecord = true;
  }
  public changeName(name) {
    this.nameModifyModeChanged = true;
    this.name = name;
  }
  public nameModifyModeSetChange() {
    this.nameModifyMode === true ? this.nameModifyMode = false : this.nameModifyMode = true;
  }

  public addchildrenObject(tmp): void {
    this.children.push(tmp);
  }
  public addchildren(id: number, name: string, position: number, parent_id: number, menuModeValue: string): void {
    const tmp = new MenuClass(id, name, position, parent_id, 'default', menuModeValue,);
    this.children.push(tmp);
  }
}
