import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArticlesClass } from 'src/app/object/articleClass';
import { GeneralService } from 'src/app/services/general.service';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';
import { GalleryAdvancedViewComponent } from 'src/app/user/modal/gallery-advanced-view/gallery-advanced-view.component';

@Component({
  selector: 'predefinied-simple-article-gallery',
  templateUrl: './article-gallery.component.html',
  styleUrls: ['./article-gallery.component.scss']
})
export class PredefiniedSimpleArticleGalleryComponent implements OnInit {
  @Input()  galleryImages: String;
  @Input()  galleryType:   String;
  constructor(
    public _galleryService: ImagesStorageService,
    public _generalService: GeneralService,
    private _modal: NgbModal,
  ) { }
  @ViewChild("carouselGallery") carousel: NgbCarousel;
  ngOnInit(): void {
  }
  carouselArticleLeft():void{
    this.carousel.prev();
  }
  carouselArticleRight():void{
    this.carousel.next()
  }

  public FakeMouseClick(event){
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
          document.getElementById(event.target.id).click();
        break;
      }
    }
    return true;
  }
  private carouselStopBool = true;
  public carouselStopText = "zatrzymaj";
  public carouselStop(){
    if (this.carouselStopBool){
      this.carousel.pause();
      this.carouselStopBool = false;
      this.carouselStopText = "wznów";
    } else {
      this.carousel.cycle();
      this.carouselStopText = "zatrzymaj";
      this.carouselStopBool = true;
    }
  }


  OpenAdvancedGalleryView(imagesID, selectedImageID:any){
    this._generalService.AdvancedGalleryViewActualImageID = selectedImageID;
    this._generalService.AdvancedGalleryViewImageID = imagesID;
    this._modal.open(GalleryAdvancedViewComponent,{windowClass:'center-modal'})
  }
}
