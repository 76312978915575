import { PredefinedArticleInformationComponent } from './user/components/predefined-article-information/predefined-article-information.component';
import { PredefinedArticleComponent } from './user/components/predefined-article/predefined-article.component';
import { FastMiniMenuComponent } from './user/components/fast-mini-menu/fast-mini-menu.component';
import { PredefineSimpleMenuComponent } from './user/components/predefine-simple-menu/predefine-simple-menu.component';
import { PredefinedMobileMenuComponent } from './user/components/predefined-mobile-menu/predefined-mobile-menu.component';
import { PredefiniedSimpleArticleGalleryComponent } from './user/components/article-simple/article-gallery/article-gallery.component';
import { PredefinedArticleCategoryListComponent } from './user/components/predefined-article-category-list/predefined-article-category-list.component';
import { PredefinedWidgetVerticalPanelV1Component } from './user/components/predefined-widget-vertical-panel-v1/predefined-widget-vertical-panel-v1.component';
import { PredefinedGalleryComponent } from './user/components/predefined-gallery/predefined-gallery.component';
import { PredefinedGalleryListComponent } from './user/components/predefined-gallery-list/predefined-gallery-list.component';
import { GalleryListFormaterPipe } from './pipes/galleryListFormater.pipe';
import { ImagePathArrayToSlashPipe } from './pipes/imagePatchArrayToSlash.pipe';
import { searchInList } from './pipes/searchInList.pipe';
import { saveBehaviorSubjectPipe } from './pipes/saveBehaviorSubject.pipe';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LinkRepairPipe } from './pipes/linkRepair.pipe';
import { UpArrowSumbolComponent } from './user/components/up-arrow/up-arrow.component';
import { AuthorSSignatureComponent } from './user/components/author-s-signature/author-s-signature.component';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipeSharedModule } from './PipeShared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
@NgModule({
  declarations: [


    searchInList,
    ImagePathArrayToSlashPipe,
    UpArrowSumbolComponent,
    AuthorSSignatureComponent,
    GalleryListFormaterPipe,
    PredefinedArticleComponent,
    PredefinedArticleInformationComponent,
    PredefinedGalleryComponent,
    PredefineSimpleMenuComponent,
    PredefinedMobileMenuComponent,
    PredefiniedSimpleArticleGalleryComponent,
    PredefinedArticleCategoryListComponent,
    PredefinedGalleryListComponent,
    FastMiniMenuComponent,
    PredefinedWidgetVerticalPanelV1Component,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    CommonModule ,
    RouterModule,
    LazyLoadImageModule,
    NgbModule,
    AngularOpenlayersModule,
    FontAwesomeModule,
    PipeSharedModule
  ],
  entryComponents:[
  ],
  exports: [
    PipeSharedModule,
    PredefinedWidgetVerticalPanelV1Component,
    RouterModule,
    LinkRepairPipe,
    CommonModule,
    PredefineSimpleMenuComponent,
    PredefinedMobileMenuComponent,
    PredefiniedSimpleArticleGalleryComponent,
    PredefinedGalleryListComponent,
    PredefinedArticleComponent,
    PredefinedGalleryComponent,
    PredefinedArticleInformationComponent,
    PredefinedArticleCategoryListComponent,
     FontAwesomeModule,
    AngularOpenlayersModule,

    GalleryListFormaterPipe,
    UpArrowSumbolComponent,
    saveBehaviorSubjectPipe,
    AuthorSSignatureComponent,
    FormsModule,
      ReactiveFormsModule,
    EditorModule,
    NgbModule,
    FastMiniMenuComponent,
    searchInList,
    ImagePathArrayToSlashPipe,
    PredefinedGalleryListComponent ,
    //NgbPaginationModule,
  ],
  providers:[]
})
export class SharedModule {



}
