<section
  class="autor WCAG_con_text WCAG_con_black WCAG_con_Border"
  role="contentinfo"
>
  <p class="WCAG_con_black">
    <span class="WCAG">
      Strona stworzona przez
      <a
        class="link WCAG_con_Title_hover"
        href="https://www.efennec.pl"
        aria-label="Link do strony autora www.efennec.pl"
        >eFennec</a
      >. Wszelkie prawa zastrzeżone Copyright &copy; {{year}}
      <a
        class="link WCAG_con_Title_hover"
        role="link"
        tabindex="0"
        aria-label="Link do mapy strony"
        (keypress)="ToPageMap()"
        (click)="ToPageMap()"
        aria-label="Link do mapy strony"
        >Mapa Strony</a
      >
      <a
        class="link WCAG_con_Title_hover"
        role="link"
        style="text-align: right; float: right"
        aria-hidden="true"
        (keypress)="ToAdmin()"
        (click)="ToAdmin()"
        tabindex="-1"
        aria-label="Link do panelu administratora"
        >Panel administratora&nbsp;&nbsp;</a
      >
    </span>
  </p>
</section>
