import { BehaviorSubject } from 'rxjs';
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "saveBehaviorSubject"
})
export class saveBehaviorSubjectPipe implements PipeTransform {
  transform(behaviorSubject: BehaviorSubject<any>, type: string = 'default'): any {
    if (behaviorSubject.value == null) {
      console.log(0)
      return null;
    }
    console.log(1)
    switch (type) {
      case 'default':
        return behaviorSubject.value;
      case 'length':
        return behaviorSubject.value;
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
