import { Injectable } from '@angular/core';
// tslint:disable-next-line: max-line-length
import {
  faTimesCircle, faUserFriends, faUser, faUsers, faCity, faCalendarAlt,
  faTag, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faNewspaper,
  faImages, faHome, faCogs, faFileAlt, faAdjust, faFont, faMinus, faPlus, faWheelchair, faPlusSquare, faMinusSquare,faPhoneSquareAlt,
  faTools, faPen, faArrowLeft, faArrowRight, faSync, faListUl, faFolderOpen, faPalette, faArrowUp, faArrowAltCircleLeft, faArrowCircleUp,
   faCaretUp, faCaretDown, faEnvelopeOpenText, faEye, faTh, faMousePointer, faBullhorn, faPhoneAlt, faBars, faHeadset, faShoppingBasket, faChalkboardTeacher, faClock, faFingerprint, faAppleAlt
} from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faYoutube, faInstagram, faFacebook, faTwitch } from '@fortawesome/free-brands-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class FontAwesomeService {
  public iconList = [  'faTimesCircle', 'faUserFriends', 'faUser', 'faUsers', 'faCity', 'faCalendarAlt',
    'faTag', 'faAngleLeft', 'faAngleRight', 'faAngleDoubleLeft', 'faAngleDoubleRight', 'faNewspaper',
    'faImages', 'faHome', 'faCogs', 'faFileAlt', 'faAdjust', 'faFont', 'faMinus', 'faPlus', 'faWheelchair', 'faPlusSquare', 'faMinusSquare','faPhoneSquareAlt',
    'faTools', 'faPen', 'faArrowLeft', 'faArrowRight', 'faSync', 'faListUl', 'faFolderOpen', 'faPalette', 'faArrowUp', 'faArrowAltCircleLeft', 'faArrowCircleUp',
     'faCaretUp', 'faCaretDown', 'faEnvelopeOpenText', 'faTh', 'faMousePointer', 'faBullhorn', 'faPhoneAlt', 'faBars', 'faHeadset', 'faShoppingBasket', 'faChalkboardTeacher',
     'faTwitter', 'faYoutube', 'faInstagram', 'faFacebook', 'faTwitch','faClock', 'faFingerprint', 'faAppleAlt', 'faEye'];
  static instance: FontAwesomeService;
  faSchoppingBasket=faShoppingBasket
  faTimesCircle = faTimesCircle;
  faUserFriends = faUserFriends;
  faUser = faUser;
  faUsers = faUsers;
  faCity = faCity;
  faCalendarAlt = faCalendarAlt;
  faTag = faTag;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  faNewspaper = faNewspaper;
  faImages = faImages;
  faHome = faHome;
  faCogs = faCogs;
  faFileAlt = faFileAlt;
  faAdjust = faAdjust;
  faFont = faFont;
  faPlus = faPlus;
  faMinus = faMinus;
  faWheelchair = faWheelchair;
  faPlusSquare = faPlusSquare;
  faMinusSquare = faMinusSquare;
  faTools = faTools;
  faPen = faPen;
  faLeftArrow = faArrowLeft;
  faRightArrow = faArrowRight;
  faSync = faSync;
  faListUl = faListUl;
  faFolderOpen = faFolderOpen;
  faPalette = faPalette;
  faYoutube = faYoutube
  faTwitter = faTwitter
  faInstagram = faInstagram
  faFacebook = faFacebook
  faTwitch = faTwitch
  faArrowUp = faArrowUp
  faArrowAltCircleLeft = faArrowAltCircleLeft
  faArrowCircleUp = faArrowCircleUp
  faCaretUp = faCaretUp
  faCaretDown = faCaretDown
  faEnvelopeOpenText = faEnvelopeOpenText
  faPhoneSquareAlt = faPhoneSquareAlt
  faTh = faTh;
  faMousePointer = faMousePointer;
  faBullhorn = faBullhorn
  faPhoneAlt =  faPhoneAlt
  faBars=faBars
  faHeadSet = faHeadset
  faChalkboardTeacher= faChalkboardTeacher;
  faClock=faClock;
  faFingerprint=faFingerprint; 
  faAppleAlt=faAppleAlt;
  faEye = faEye;




  constructor() {
    FontAwesomeService.instance = this;}
}
//<fa-icon [icon]="_fontAwesome.faUsers"></fa-icon>
