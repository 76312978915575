import { GeneralService } from 'src/app/services/general.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';

@Component({
  selector: 'app-gallery-advanced-view',
  templateUrl: './gallery-advanced-view.component.html',
  styleUrls: ['./gallery-advanced-view.component.scss']
})
export class GalleryAdvancedViewComponent implements OnInit {
  public imagesArray = [];
  public NowImage = null;
  constructor(
    public _activeModal :NgbActiveModal,
    public _generalService: GeneralService,
    public _ImageStorage:ImagesStorageService

  ) { }

  ngOnInit(): void {
    this.NowImage = this._generalService.AdvancedGalleryViewActualImageID;
    this.imagesArray =(this._generalService.AdvancedGalleryViewImageID+"").split(",");
  }
  nextImage(){
    let i =  this.imagesArray.indexOf(this.NowImage);
    if( i + 1 >= this.imagesArray.length){
      this.NowImage =  this.imagesArray[0];
    } else{
      this.NowImage = this.imagesArray[ i + 1]
    }


  }
  prevImage(){
    let i= -1;
    this.imagesArray.find(element=>{
      i++;
      return element == this.NowImage
    })
    if( i -1 <  0){
      this.NowImage = this.imagesArray[this.imagesArray.length-1];
    } else{
      this.NowImage = this.imagesArray[ i - 1]
    }
  }
  nowImage(): number {
    let i= -1;
    this.imagesArray.find(element=>{
      i++;
      return element == this.NowImage
    })
    return i

  }
}
