import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { ApiService } from "../services/api/api.service";
import { ConfigFileService } from "../services/config-file.service";
export class ArticlesClass {     // tslint:disable: variable-name
  id: number;
  title: string;
  body: string;
  status: string;
  position: number;
  category: string;
  headImage: string;
  headImageMode: number;
  create_time: Date;
  update_time: Date;
  publish_date: Date;
  gallery_type: string;
  gallery_images: string;
  imagesGalleryArray: Array<string>;
  visits: number;
  createImageGalleryArray(){
    this.imagesGalleryArray = this.gallery_images.split(',');
  }
  constructor(id: number, title: string, body: string, status: string, position: number,
              create_time: Date, update_time: Date, publish_date: Date, category: string,
              gallery_type_: string, gallery_images: string,headImage:string,headImageMode:number) {
    this.id = id;
    //this.imagesGalleryArray = [];
    this.title = title;
    this.body = body;
    this.status = status;
    this.position = position;
    this.headImage = headImage;
    this.headImageMode = headImageMode;
    if(headImage == ''){
      this.headImage = null;
    }
    this.category = category;
    this.create_time = create_time;
    this.update_time = update_time;
    this.publish_date = publish_date;
    this.gallery_type = gallery_type_;
    this.gallery_images = gallery_images;
  }
}

export class ArticleObjectClass {
  private articlesInit = false;
  public articles: Array<ArticlesClass> = [];
  public selectedArticle:ArticlesClass;
  public selectedCategoryArticles:Array<ArticlesClass> = [];

  constructor(
   private Router : Router,
    private ActivatedRoute: ActivatedRoute
    ){
    this.selectedArticle = new ArticlesClass(null,null,null,null,null,null,null,null,null,null,null,null,null);
    this.selectedCategoryArticles = [new ArticlesClass(null,null,null,null,null,null,null,null,null,null,null,null,null)];
  }
  articleInit():Promise<boolean> {
    return new Promise((resolve, reject) => {
      if(this.articlesInit){
        resolve(true)
        return null;
      }
        this.articlesInit = true;
        ApiService.instance.GetFromDataBase(null, 'articles', ['status'], ['published'], [])
          .then(data => {
            data.forEach((element: ArticlesClass) => {
              if(!this.dataAfterToday(element.publish_date)){
                var tmp = new ArticlesClass(element.id, element.title, element.body, element.status, element.position,
                  element.create_time, element.update_time, element.publish_date, element.category, element.gallery_type, element.gallery_images,element.headImage,element.headImageMode);

                  tmp.createImageGalleryArray();
                  this.articles.push(tmp);
              }
            });
            this.articlesLength = this.articles.length;
            resolve(true);
    }).catch(data=>{
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Ładowanie artykułów zwróciło błąd połączenia z bazą danych',
          showConfirmButton: false,
          timer: 3000
        });
        reject(false);
    })
  });
  }
  private capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  returnArticleCategory(category){
    var value: Array<ArticlesClass> = null;
    value = this.articles.filter(element=>{
        return element.category == category;
      })
      this.category_articlesLength = value.length;
      this.category_title = this.capitalizeFirstLetter(category);
      this.selectedCategoryArticles = value;

  }

  private dataAfterToday(date): boolean{
    if(new Date(date).getTime() > new Date().getTime()){
      return true;
    } else{
      return false;
    }
  }
  public returnArticleWithID(id):ArticlesClass {

        var tmp: Array<ArticlesClass> = null;
        tmp = this.articles.filter((element: ArticlesClass) => {
          return element.id == id;
        });
        if (tmp.length == 0) {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'info',
            title: 'Artykuł nie istnieje, lub nie jest publiczny',
            showConfirmButton: false,
            timer: 4000
          });
          this.Router.navigate(['/']);
          return (null);
        }
        if (tmp.length > 1) {
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'info',
            title: 'Artykuł nie istnieje, lub nie jest publiczny',
            showConfirmButton: false,
            timer: 4000
          });
          this.Router.navigate(['/']);
          return(null);
        }
        this.selectedArticle = tmp[0];
        return(tmp[0]);

  }
  public returnGalleryArrayArticleWithID(id) {
    return this.returnArticleWithID(id).gallery_images.split(',');
}
//article param
  public articlesLength;
  public itemsPerPage = 3;
  public page = 1;
  public pageSize: number;
  public currentPage: number = 1;
  public onPageChange(pageNum: number): void {
  //  this.currentPage = this.page;
  this.scrollToTop();
  setTimeout(() => { // ładniej wygląda z opóźnieniem
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    }, 400);
  }

  public changePagesize(num: number): void {
    this.itemsPerPage = this.pageSize + num;
  }
//article category param

public category_title="...";

public category_articlesLength;
public category_itemsPerPage = 4;
public category_page = 1;
public category_pageSize: number;
public category_currentPage: number  = 1;
public category_onPageChange(category_pageNum: number): void {
  this.category_currentPage = this.category_page;
  this.scrollToTop();
  setTimeout(() => { // ładniej wygląda z opóźnieniem
    this.category_pageSize = this.category_itemsPerPage * (category_pageNum - 1);
  }, 400);
}
public category_changePagesize(num: number): void {
  this.category_itemsPerPage = this.category_pageSize + num;
}





  scrollToTop() {
    //document.getElementById('scrolPoint').scrollIntoView();
      window.scroll(0,0)
  }
}

export class articleFunctionCLass{
 public static text_image_converter(OrginalText: string, mode: string): string {
    // console.log(OrginalText)
    let outPutText = OrginalText;
    switch (mode) {
      case 'toDataBase':
        //* wersja z powiększaniem
        const regex = /<img[a-zA-Z0-9\ \!\@\#\$\%\^\&\*\(\)\_\-\=\+\'\"\;\:\,\.\/\\\n]{1,}id=.efennec[a-zA-Z0-9\ \!\@\#\$\%\^\&\*\(\)\_\-\=\+\'\"\;\:\,\.\/\\\n]{1,}\/\>/g;
        var tablicaZdjec = OrginalText.match(regex)
        // console.log(tablicaZdjec)
        if (tablicaZdjec == null) {
          return OrginalText
        }
        tablicaZdjec.forEach(image => {
          // wyciecie img id
          const regex3 = /src=[a-zA-Z0-9\!\@\#\$\%\^\&\*\\"(\)\-\=\;\:\,\/\\\.\n\_]{1,}"/g;
          let samoSrc = image.match(regex3)[0]
          const regex4 = /\/[0-9]{1,}/g;
          let samoIdZdjecia = samoSrc.match(regex4)[0].slice(1)
          // nowe parametry
          const newSrc = 'src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="  alt="%_BigView_#%eFennec#%#%=' + samoIdZdjecia + '=%#eFennec%#%__#%"';



          // nowy img
          let newImage = image;
          newImage = newImage.replace(/alt="[a-zA-Z\\'\:\/\.0-9\n\!\@\#\$\%\^\&\*\(\)\=\_\n]{1,}"/g, '');
          newImage = newImage.replace(/src="[a-zA-Z\\'\:\/\.0-9\n]{1,}"/g, newSrc)
          outPutText = outPutText.replace(image, newImage)

        })
        return outPutText
        break;
      case 'fromDataBase':
        const regex_ = /<img[a-zA-Z0-9\ \!\@\#\$\%\^\&\*\(\)\_\-\=\+\'\"\;\:\,\.\/\\\n]{1,}id=.efennec[a-zA-Z0-9\ \!\@\#\$\%\^\&\*\(\)\_\-\=\+\'\"\;\:\,\.\/\\\n]{1,}\/\>/g;
        var tablicaZdjec = OrginalText.match(regex_)
        //console.log(tablicaZdjec)
        if (tablicaZdjec == null) {

          return OrginalText
        }
        tablicaZdjec.forEach(image => {

          const regex_1 = /#%eFennec#%#%=[0-9]{1,}/g;
          const regex_2 = /[0-9]{1,}/g;
          var id_ = image.match(regex_1)[0].match(regex_2)[0]
          let newImage = image;
          // zamiana src
          newImage = newImage.replace(/data[a-zA-Z\:\/\;0-9\,\=]{1,}/g, ConfigFileService.instance.ConfigObject.ApiLinkGetImage + id_);
          outPutText = outPutText.replace(image, newImage);
        });
        return outPutText
      default:
        return OrginalText
    }
  }


  public static  text_files_converter(OrginalText: string, mode: string): string {
    if(OrginalText == undefined){
      return '';
    }
    // console.log(OrginalText)
    let outPutText = OrginalText;
    switch (mode) {
      case 'toDataBase':
        const regex_ = /<a[a-zA-Z\ =\"\:\/\.\-\_]{0,}id=.efennec[0-9a-zA-Z\ =\"\:\/\.\-\_]{0,}getFile[0-9a-zA-Z\"\=\+\_\"\ \-\/\\]{0,}>/g;

        var tablicalinkow = OrginalText.match(regex_);

        if (tablicalinkow == null) {
          return OrginalText
        }

        tablicalinkow.forEach(link => {
          const regex_1 = /getFile[\/][0-9]{0,}/g;
          const regex_2 = /[0-9]{1,}/g;
          var id_ = link.match(regex_1)[0].match(regex_2)[0]
          var newLink = link;
          newLink = newLink.replace(/href="[^"']{0,}"/g, 'href="' + "api%_" + id_ + "_%" + '"')
          outPutText = outPutText.replace(link, newLink)
        });
        return outPutText
      case 'fromDataBase':

       // const regex_2 = /<a[a-zA-Z\ =\"\:\/\'\!\@\#\$\%\^\&\*\(\)\_]{0,}id=.efennec[a-zA-Z0-9\ =\"\:\_\/\'\!\@\#\$\%\^\&\*\(\)\.\-\n]{0,}>/g;
        const regex_2 = /<a[^>]{0,}id=.efennec[^>]{0,}>/g;
        var tablicalinkow = OrginalText.match(regex_2);
        if (tablicalinkow == null) {
          return OrginalText
        }

        tablicalinkow.forEach(link => {
          const regex_1 = /href="[^"']{0,}"/g;
          const regex_2 = /[0-9]{1,}/g;
          var id_ = link.match(regex_1)[0].match(regex_2)[0]
          var newLink = link;
          //newLink = newLink.replace(/href="[^"']{0,}"/g, 'href="' + ConfigFileService.instance.ConfigObject.ApiLinkGetFile + id_ + " ")
          newLink = newLink.replace(/href="[^"']{0,}"/g, 'href="' + ConfigFileService.instance.ConfigObject.ApiLinkGetFile + id_ + '"')
          outPutText = outPutText.replace(link, newLink)
        });

        return outPutText
      default:
        return OrginalText
    }
  }

//<a[^>]{0,}id=.efennec[^>]{0,}>


}
