<div class="information">
  <div class="tag WCAG_con_text WCAG_bw_text_white">
    <fa-icon [icon]="_fontAwesome.faTag"></fa-icon>
    <a
    [id]="CategoryID"
    [tabIndex]="0"
    [attr.aria-label]="'Link do listy kategorii: ' + article.category"
    [routerLink]="[categoryLink+article.category]"
     role="link"
    >{{article.category}}</a>
  </div>
  <div class="published WCAG_con_text WCAG_bw_text_white">
    <fa-icon [icon]="_fontAwesome.faCalendarAlt"></fa-icon>
    <span>Opublikowano: <time>{{article.publish_date}}</time></span>
  </div>
</div>
