import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuClass } from 'src/app/object/headMenuClass';
import { ClientDataService } from 'src/app/services/client-data.service';
import { FontAwesomeService } from 'src/app/services/font-awesome.service';
import { GeneralTemplateService } from 'src/app/services/general-template.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-predefine-simple-menu_0_1',
  templateUrl: './predefine-simple-menu.component.html',
  styleUrls: ['./predefine-simple-menu.component.scss']
})
export class PredefineSimpleMenuComponent implements OnInit {
  constructor(
    public _fontService: FontAwesomeService,
    private _generalTemplateService: GeneralTemplateService,
    private _generalService: GeneralService,
    private _clientDataService: ClientDataService,
    private _router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }
  @Input() template: string;
  @Input() home: string;
  @Input() top: number;
  @Input() justifyCenter = false;
  @Input() galleryList:string;
  @Input() height: number;
  public topMenu: Array<MenuClass> = [];
  public menuState = false;
  public menuStateDelay = false;

  ngOnInit(): void {
    document.documentElement.style.setProperty('--menuHeight', String(this.height + 'px'));

    this._generalTemplateService.returnMenu().then((value: Array<MenuClass>) => {
      this.topMenu = value;
    });
  }
  toggleMenu() {
    this.menuState = !this.menuState;
  }


  public length(a){
    return a.length;
  }
  public FakeMouseClick(event){
    const KEY_ENTER = 13;
    const KEY_SPACE = 32;
    switch (event.which) {
      case KEY_ENTER: {
          document.getElementById(event.target.id).click();
          break;
      }
    } // end switch
    return true;
  }

}
