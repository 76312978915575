<section class="ListGalleryTop">
  <h2 class="WCAG_con_black WCAG_con_Title">Lista galerii:</h2>
</section>
<section class="ListGalleryBottom ">
  <section
    class="gallery WCAG_con_text"
    *ngFor="
      let x of _generalService.galleryObject.storageForrmated
        | ArticleSort: 'year'
        | slice: _generalService.galleryObject.pageSize
        | slice: 0:_generalService.galleryObject.itemsPerPage;
      let i = index
    "
  >
    <section class="top WCAG_con_black">
      <h3>{{ x.year }} - {{ number(x.year) + 1 }}</h3>
    </section>
    <section class="bottom WCAG_con_black">
      <ul>
        <li *ngFor="let y of x.array">
          <a
            [attr.aria-label]="'Link to galleri zdjęć o tytule: ' + y.title"
            [routerLink]="[galleryPath + y.id]"
            ><span class="WCAG WCAG_con_Title">- {{ y.title }}</span></a
          >
        </li>
      </ul>
    </section>
  </section>
</section>
<div class="navigate">
  <ngb-pagination
    [collectionSize]="_generalService.galleryObject.galleryLength"
    [pageSize]="_generalService.galleryObject.itemsPerPage"
    [(page)]="_generalService.galleryObject.currentPage"
    (pageChange)="
      _generalService.galleryObject.onPageChange(
        _generalService.galleryObject.currentPage
      )
    "
  ></ngb-pagination>
</div>
