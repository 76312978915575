import { SecurityService } from './../../../../../services/security/security.service';
import { ApiService } from './../../../../../services/api/api.service';
import { GalleryService } from './../../../../services/gallery.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImagePathPipe } from 'src/app/pipes/imagePath.pipe';
import Swal from 'sweetalert2';
import { ImagesClass } from 'src/app/object/gallery.class';

@Component({
  selector: 'app-image-settings-modal',
  templateUrl: './image-settings-modal.component.html',
  styleUrls: ['./image-settings-modal.component.scss']
})
export class ImageSettingsModalComponent implements OnInit, OnDestroy {
  image: ImagesClass
  constructor(
    public _activeModal: NgbActiveModal,
    private _galleryService: GalleryService,
    private _api: ApiService,
    private _security: SecurityService
  ) { }

  ngOnInit(): void {
    this.image = this._galleryService.images.value.find(img => {
      return img.id == this._galleryService.imageSettingDataBaseID
    });
    let tmp = '';
    let i = 0;
    this.image.patchArray.forEach(el => {
      if (i == 0) {
        tmp = tmp + el

      } else {
        tmp = tmp + '/' + el
      }
      i++;
    })
    this.image.patch = tmp;
  }

  ngOnDestroy(): void {
    this.image = null;
  }
  changePatch(patch){
    this.image.patch = new ImagePathPipe().transform(patch);
  }
  save():void{
    let tmp = this.image.patch;
    let tmpar = tmp.split("");
    let pathtmp = '';
    tmpar.forEach(element => {
      if (Number(element.charCodeAt(0)) == 92 || Number(element.charCodeAt(0)) == 47) {
        pathtmp = pathtmp + '@';
      } else {
        pathtmp = pathtmp + element;
      }
    });
    JSON['alt'] = this.image.alt;
    JSON['title'] = this.image.title;
    this._api.updateInDatabase(this._security.safeReturnTokenObjectTokenValue(),JSON,'images',['id'],[String(this._galleryService.imageSettingDataBaseID)]).then(data=>{
      Swal.close();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: 'Zdjęcie zmodyfikowane',
        showConfirmButton: false,
        timer: 3000
      });
     // this._galleryService.returnImagesFromDatabase().then();
      this._activeModal.close();
    }).catch(data=>{
      Swal.close();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: 'Modyfikacja nie udana',
        showConfirmButton: false,
        timer: 3000
      });

    })
    delete JSON['alt'];
    delete JSON['title'];

  }
}
