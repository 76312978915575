import { Pipe, PipeTransform } from '@angular/core';
import { galleryPageClass, galleryPageFormatedClass } from '../object/galleryPageClass';
@Pipe({
  name: 'galleryListFormaterPipe'
})
export class GalleryListFormaterPipe implements PipeTransform {
  constructor(){}
  spr1 = false;

  public transform(value: Array<galleryPageClass>) {
    console.log(value)
    if(value.length > 0)
    {
      var outPut:Array<galleryPageFormatedClass> =[];
      value.forEach(element=>{
        this.spr1 = false;
        outPut.forEach(hmm=>{
          if(hmm.year == element.year)
          {
            this.spr1 = true;
            hmm.array.push(element)

          }
        })
        if(this.spr1 == false){
          var tmp = new galleryPageFormatedClass()
          tmp.year = element.year;
          tmp.array.push(element)

           outPut.push(tmp)
          }

        })
      return outPut;
    }
    return null;

  }

}

