<div class="contener">
  <ul class="menu">
    <li *ngIf="content != null">
      <input
        type="button"
        class="WCAG"
        aria-label="Przycisk przenoszący do treści"
        value="Przewiń do treści"
        tabindex="1"
        (keypress)="FakeMouseClick($event)"
        id="miniButton1"
        (click)="GoToContent()"
      />
    </li>
    <li>
      <input
        type="button"
        class="WCAG"
        aria-label="Przycisk przenoszący na stronę główną"
        value="Strona główna"
        tabindex="2"
        id="miniButton2"
        [routerLink]="['/']"
      />
    </li>
    <li  *ngIf="PageMap != null">
      <input
        type="button"
        class="WCAG"
        aria-label="Przycisk przenoszący do mapy strony"
        value="Mapa strony"
        tabindex="3"
        (keypress)="FakeMouseClick($event)"
        id="miniButton3"
        (click)="ToPageMap()"
      />
    </li>
  </ul>
</div>
