/* tslint:disable:variable-name */
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../services/api/api.service';
// tslint:disable: variable_name
export class OrangeSkytemplateConfigClass {
  private templateID: number;
  bannerImage = new valueClass('bannerImage');
  bannerImageAlt = new valueClass('bannerImageAlt');
  herbImage = new valueClass('herbImage');
  herbImageAlt = new valueClass('herbImageAlt');
  articleDisplayLimit = new valueClass('articleDisplayLimit');
  footer_panel_1_status = new valueClass('footer_panel_1_status');
  footer_panel_2_status = new valueClass('footer_panel_2_status');
  footer_panel_3_status = new valueClass('footer_panel_3_status');
  footer_panel_4_status = new valueClass('footer_panel_4_status');
  footer_panel_1 = new valueClass('footer_panel_1');
  footer_panel_2 = new valueClass('footer_panel_2');
  footer_panel_3 = new valueClass('footer_panel_3');
  footer_panel_4 = new valueClass('footer_panel_4');
  footer_panel_google_maps_status = new valueClass('footfooter_panel_google_maps_statuser_panel_1');
  map_height = new valueClass('map_height');
  footer_panel_twitter = new valueClass('footer_panel_twitter');
  footer_panel_youtube = new valueClass('footer_panel_youtube');
  footer_panel_instagram = new valueClass('footer_panel_instagram');
  footer_panel_facebook = new valueClass('footer_panel_facebook');
  footer_panel_twitch = new valueClass('footer_panel_twitch');
  header_elearning_button = new valueClass('header_elearning_button');

  information_panel_1_value = new valueClass('information_panel_1_value');
  information_panel_2_value = new valueClass('information_panel_2_value');
  information_panel_3_value = new valueClass('information_panel_3_value');
  information_panel_4_value = new valueClass('information_panel_4_value');



schoolStats_1_title = new valueClass('schoolStats_1_title');
schoolStats_2_title = new valueClass('schoolStats_2_title');
schoolStats_3_title = new valueClass('schoolStats_3_title');
schoolStats_4_title = new valueClass('schoolStats_4_title');


schoolStats_1_value = new valueClass('schoolStats_1_value');
schoolStats_2_value = new valueClass('schoolStats_2_value');
schoolStats_3_value = new valueClass('schoolStats_3_value');
schoolStats_4_value = new valueClass('schoolStats_4_value');


  public ReadyDataObservable: BehaviorSubject<boolean>;
  ReturnValue: (name) => valueClass;
  ReturnFromDataBase: (table, whereName, where) => Promise<boolean>;
  ReturnFooterPanels: () => Array<any>;

  constructor(templateID: number) {
    this.ReadyDataObservable = new BehaviorSubject(false);
    this.templateID = templateID;
    OrangeSkytemplateConfigClass.prototype.ReturnFromDataBase = returnFromDataBase;
    OrangeSkytemplateConfigClass.prototype.ReturnFooterPanels = ReturnFooterPanelsSimpleFunction;
    OrangeSkytemplateConfigClass.prototype.ReturnValue = returnValueFunction;
  }

}


export class AngelatemplateConfigClass {
  private templateID: number;
  information_panel_1_value = new valueClass('information_panel_1_value');
  information_panel_2_value = new valueClass('information_panel_2_value');
  information_panel_3_value = new valueClass('information_panel_3_value');
  information_panel_4_value = new valueClass('information_panel_4_value');



  cleverStudents_status_number = new valueClass('cleverStudents_status_number');
  cleverStudents_status = new valueClass('cleverStudents_status');

  cleverStudents_st_4_class = new valueClass('cleverStudents_st_4_class');
  cleverStudents_st_3_class = new valueClass('cleverStudents_st_3_class');
  cleverStudents_st_2_class = new valueClass('cleverStudents_st_2_class');
  cleverStudents_st_1_class = new valueClass('cleverStudents_st_1_class');

  cleverStudents_st_1_text1 = new valueClass('cleverStudents_st_1_text1');
  cleverStudents_st_2_text1 = new valueClass('cleverStudents_st_2_text1');
  cleverStudents_st_3_text1 = new valueClass('cleverStudents_st_3_text1');
  cleverStudents_st_4_text1 = new valueClass('cleverStudents_st_4_text1');


  cleverStudents_st_1_image_id = new valueClass('cleverStudents_st_1_image_id');
  cleverStudents_st_2_image_id = new valueClass('cleverStudents_st_2_image_id');
  cleverStudents_st_3_image_id = new valueClass('cleverStudents_st_3_image_id');
  cleverStudents_st_4_image_id = new valueClass('cleverStudents_st_4_image_id');

  cleverStudents_st_1_name = new valueClass('cleverStudents_st_1_name');
  cleverStudents_st_2_name = new valueClass('cleverStudents_st_2_name');
  cleverStudents_st_3_name = new valueClass('cleverStudents_st_3_name');
  cleverStudents_st_4_name = new valueClass('cleverStudents_st_4_name');

  schoolStats_1_value = new valueClass('schoolStats_1_value');
  schoolStats_2_value = new valueClass('schoolStats_2_value');
  schoolStats_3_value = new valueClass('schoolStats_3_value');
  schoolStats_4_value = new valueClass('schoolStats_4_value');

  schoolStats_1_title = new valueClass('schoolStats_1_title');
  schoolStats_2_title = new valueClass('schoolStats_2_title');
  schoolStats_3_title = new valueClass('schoolStats_3_title');
  schoolStats_4_title = new valueClass('schoolStats_4_title');



  footer_panel_1 = new valueClass('footer_panel_1');
  footer_panel_2 = new valueClass('footer_panel_2');
  footer_panel_3 = new valueClass('footer_panel_3');

  tile_1_imageID  = new valueClass('tile_1_imageID');
  tile_1_text = new valueClass('tile_1_text');
  tile_1_link  = new valueClass('tile_1_link');
  tile_2_imageID  = new valueClass('tile_2_imageID');
  tile_2_text  = new valueClass('tile_2_text');
  tile_2_link  = new valueClass('tile_2_link');
  tile_3_imageID = new valueClass('tile_3_imageID');
  tile_3_text  = new valueClass('tile_3_text');
  tile_3_link  = new valueClass('tile_3_link');
  tile_4_imageID = new valueClass('tile_4_imageID');
  tile_4_text = new valueClass('tile_4_text');
  tile_4_link  = new valueClass('tile_4_link');
  tile_5_imageID  = new valueClass('tile_5_imageID');
  tile_5_text  = new valueClass('tile_5_text');
  tile_5_link = new valueClass('tile_5_link');
  tile_6_imageID  = new valueClass('tile_6_imageID');
  tile_6_text  = new valueClass('tile_6_text');
  tile_6_link  = new valueClass('tile_6_link');
  tile_7_imageID  = new valueClass('tile_7_imageID');
  tile_7_text  = new valueClass('tile_7_text');
  tile_7_link  = new valueClass('tile_7_link');
  tile_8_imageID  = new valueClass('tile_8_imageID');
  tile_8_text  = new valueClass('tile_8_text');
  tile_8_link  = new valueClass('tile_8_link');
  tile_9_imageID  = new valueClass('tile_9_imageID');
  tile_9_text  = new valueClass('tile_9_text');
  tile_9_link  = new valueClass('tile_9_link');
  tile_10_imageID  = new valueClass('tile_10_imageID');
  tile_10_text  = new valueClass('tile_10_text');
  tile_10_link  = new valueClass('tile_10_link');
  tile_11_imageID  = new valueClass('tile_11_imageID');
  tile_11_text  = new valueClass('tile_11_text');
  tile_11_link  = new valueClass('tile_11_link');
  tile_12_imageID = new valueClass('tile_12_imageID');
  tile_12_text  = new valueClass('tile_12_text');
  tile_12_link  = new valueClass('tile_12_link');
  tile_1_status  = new valueClass('tile_1_status');
  tile_2_status  = new valueClass('tile_2_status');
  tile_3_status  = new valueClass('tile_3_status');
  tile_4_status  = new valueClass('tile_4_status');
  tile_5_status  = new valueClass('tile_5_status');
  tile_6_status  = new valueClass('tile_6_status');
  tile_7_status  = new valueClass('tile_7_status');
  tile_8_status  = new valueClass('tile_8_status');
  tile_9_status  = new valueClass('tile_9_status');
  tile_10_status  = new valueClass('tile_10_status');
  tile_11_status  = new valueClass('tile_11_status');
  tile_12_status  = new valueClass('tile_12_status');







  public ReadyDataObservable: BehaviorSubject<boolean>;
  ReturnValue: (name) => valueClass;
  ReturnFromDataBase: (table, whereName, where) => Promise<boolean>;
  ReturnFooterPanels: () => Array<any>;

  constructor(templateID: number) {
    this.ReadyDataObservable = new BehaviorSubject(false);
    this.templateID = templateID;
    AngelatemplateConfigClass.prototype.ReturnFromDataBase = returnFromDataBase;
    AngelatemplateConfigClass.prototype.ReturnFooterPanels = ReturnFooterPanelsSimpleFunction;
    AngelatemplateConfigClass.prototype.ReturnValue = returnValueFunction;
  }

}

export class PatiotemplateConfigClass {
  private templateID: number;
  footer_panel_1 = new valueClass('footer_panel_1');
  footer_panel_2 = new valueClass('footer_panel_2');
  footer_panel_3 = new valueClass('footer_panel_3');
  footer_panel_4 = new valueClass('footer_panel_4');
  footer_panel_5 = new valueClass('footer_panel_5');
  footer_panel_6 = new valueClass('footer_panel_6');
  footer_panel_7 = new valueClass('footer_panel_7');
  footer_panel_8 = new valueClass('footer_panel_8');
  footer_panel_9 = new valueClass('footer_panel_9');
  workTime_Pon_p = new valueClass('workTime_Pon_p') ;
  workTime_Pon_k = new valueClass('workTime_Pon_p');
  workTime_Wt_p = new valueClass('workTime_Wt_p');
  workTime_Wt_k = new valueClass('workTime_Wt_p');
  workTime_Sr_p = new valueClass('workTime_Sr_p');
  workTime_Sr_k = new valueClass('workTime_Sr_p');
  workTime_Czw_p = new valueClass('workTime_Czw_p');
  workTime_Czw_k = new valueClass('workTime_Czw_p');
  workTime_Pt_p = new valueClass('workTime_Pt_p');
  workTime_Pt_k = new valueClass('workTime_Pt_p');
  workTime_Sob_p = new valueClass('workTime_Sob_p');
  workTime_Sob_k = new valueClass('workTime_Sob_p');
  workTime_Nie_p = new valueClass('workTime_Nie_p');
  workTime_Nie_k = new valueClass('workTime_Nie_p');
  workTime_position = new valueClass('workTime_position');
  workTime_state = new valueClass('workTime_state');
  notes_image_id = new valueClass('notes_image_id');
  notes_image_state = new valueClass('notes_image_state');
  headPage_title = new valueClass('headPage_title');
  banner_quotation = new valueClass('banner_quotation');
  HeadPage_karuzela_image = new valueClass('HeadPage_karuzela_image');
  clock_state = new valueClass('clock_state');
  clock_position = new valueClass('clock_position');

  Instagram_link = new valueClass('Instagram_link');
  YouTuba_link = new valueClass('YouTuba_link');
  Twitter_link = new valueClass('Twitter_link');
  Facebook_link = new valueClass('Facebook_link');
  Facebook_link_2 = new valueClass('Facebook_link_2');


  notes_1_value = new valueClass('notes_1_value');
  notes_2_value = new valueClass('notes_2_value');
  notes_3_value = new valueClass('notes_3_value');
  notes_4_value = new valueClass('notes_4_state');
  notes_5_value = new valueClass('notes_5_value');
  notes_6_value = new valueClass('notes_6_value');
  notes_7_value = new valueClass('notes_7_value');
  notes_8_value = new valueClass('notes_8_value');

  notes_1_state = new valueClass('notes_1_state');
  notes_2_state = new valueClass('notes_2_state');
  notes_3_state = new valueClass('notes_3_state');
  notes_4_state = new valueClass('notes_4_state');
  notes_5_state = new valueClass('notes_5_state');
  notes_6_state = new valueClass('notes_6_state');
  notes_7_state = new valueClass('notes_7_state');
  notes_8_state = new valueClass('notes_8_state');

  notes_1_mode = new valueClass('notes_1_mode');
  notes_2_mode = new valueClass('notes_2_mode');
  notes_3_mode = new valueClass('notes_3_mode');
  notes_4_mode = new valueClass('notes_4_mode');
  notes_5_mode = new valueClass('notes_5_mode');
  notes_6_mode = new valueClass('notes_6_mode');
  notes_7_mode = new valueClass('notes_7_mode');
  notes_8_mode = new valueClass('notes_8_mode');

  notes_1_position = new valueClass('notes_1_position');
  notes_2_position = new valueClass('notes_2_position');
  notes_3_position = new valueClass('notes_3_position');
  notes_4_position = new valueClass('notes_4_position');
  notes_5_position = new valueClass('notes_5_position');
  notes_6_position = new valueClass('notes_6_position');
  notes_7_position = new valueClass('notes_7_position');
  notes_8_position = new valueClass('notes_8_position');


  links_1_image_id = new valueClass('links_1_image_id');
  links_2_image_id = new valueClass('links_2_image_id');
  links_3_image_id = new valueClass('links_3_image_id');
  links_4_image_id = new valueClass('links_4_image_id');
  links_5_image_id = new valueClass('links_5_image_id');
  links_6_image_id = new valueClass('links_6_image_id');
  links_7_image_id = new valueClass('links_7_image_id');
  links_8_image_id = new valueClass('links_8_image_id');
  links_9_image_id = new valueClass('links_9_image_id');
  links_10_image_id = new valueClass('links_10_image_id');
  links_11_image_id = new valueClass('links_11_image_id');
  links_12_image_id = new valueClass('links_12_image_id');
  links_13_image_id = new valueClass('links_13_image_id');
  links_14_image_id = new valueClass('links_14_image_id');
  links_15_image_id = new valueClass('links_15_image_id');


  links_1_status = new valueClass('links_1_status');
  links_2_status = new valueClass('links_2_status');
  links_3_status = new valueClass('links_3_status');
  links_4_status = new valueClass('links_4_status');
  links_5_status = new valueClass('links_5_status');
  links_6_status = new valueClass('links_6_status');
  links_7_status = new valueClass('links_7_status');
  links_8_status = new valueClass('links_8_status');
  links_9_status = new valueClass('links_9_status');
  links_10_status = new valueClass('links_10_status');
  links_11_status = new valueClass('links_11_status');
  links_12_status = new valueClass('links_12_status');
  links_13_status = new valueClass('links_13_status');
  links_14_status = new valueClass('links_14_status');
  links_15_status = new valueClass('links_15_status');



  links_1_href = new valueClass('links_1_href');
  links_2_href = new valueClass('links_2_href');
  links_3_href = new valueClass('links_3_href');
  links_4_href = new valueClass('links_4_href');
  links_5_href = new valueClass('links_5_href');
  links_6_href = new valueClass('links_6_href');
  links_7_href = new valueClass('links_7_href');
  links_8_href = new valueClass('links_8_href');
  links_9_href = new valueClass('links_9_href');
  links_10_href = new valueClass('links_10_href');
  links_11_href = new valueClass('links_11_href');
  links_12_href = new valueClass('links_12_href');
  links_13_href = new valueClass('links_13_href');
  links_14_href = new valueClass('links_14_href');
  links_15_href = new valueClass('links_15_href');



  LinkToCatalog_state = new valueClass('LinkToCatalog_state');
  LinkToCatalog_id = new valueClass('LinkToCatalog_id');
  LinkToCatalog_position = new valueClass('LinkToCatalog_position');
  LinkToCatalog_href = new valueClass('LinkToCatalog_href');


  private footerPanelsCounter = 9;
  google_map_panel_id = new valueClass('google_map_panel_id');
  google_map_width = new valueClass('google_map_width');
  public ReadyDataObservable: BehaviorSubject<boolean>;
  ReturnValue: (name) => valueClass;
  ReturnFromDataBase: (table, whereName, where) => Promise<boolean>;
  ReturnFooterPanels: () => Array<any>;
  constructor(templateID: number) {
    this.ReadyDataObservable = new BehaviorSubject(false);
    this.templateID = templateID;
    PatiotemplateConfigClass.prototype.ReturnFromDataBase = returnFromDataBase;
    PatiotemplateConfigClass.prototype.ReturnFooterPanels = ReturnFooterPanelsSimpleFunction;
    PatiotemplateConfigClass.prototype.ReturnValue = returnValueFunction;
  }

}


export function ReturnFooterPanelsSimpleFunction(): Array<any> {
  let footerArray = [];
  for (let index = 1; index <= this.footerPanelsCounter; index++) {
      footerArray.push([this.ReturnValue('footer_panel_' + index).value, this.ReturnValue('footer_panel_' + index).name])
  }
  return footerArray;
}









export class SettingsConfigClass {
  private templateID: number;
  image_upload_all_user_permission = new valueClass('image_upload_all_user_permission');
  image_upload_no_user_permission = new valueClass(' image_upload_no_user_permission');
  'widget-lucky-number-max-number' = new valueClass('widget-lucky-number-max-number');
  'widget-lucky-number-value' = new valueClass('widget-lucky-number-value');
  'widget-lucky-number-last-modify' = new valueClass('widget-lucky-number-last-modify');
  template = new valueClass('template');
  theme = new valueClass('theme');
  pageDisable = new valueClass('pageDisable');
  database_version = new valueClass('database_version');
  welcome_panel = new valueClass('welcome_panel');
  welcome_panel_mode = new valueClass('welcome_panel_mode');
  welcome_panel_status = new valueClass('welcome_panel_status');
  welcome_panel_date_from_1 = new valueClass('welcome_panel_date_from_1');
  welcome_panel_date_to_1 = new valueClass('welcome_panel_date_to_1 ');
  welcome_panel_date_from_2 = new valueClass('welcome_panel_date_from_2 ');
  welcome_panel_date_to_2 = new valueClass('welcome_panel_date_to_2 ');
  welcome_panel_value_mode = new valueClass('welcome_panel_value_mode ');
  welcome_panel_image_id = new valueClass('welcome_panel_image_id ');

  greyScale = new valueClass('greyScale');
  greyScale_stop = new valueClass('greyScale_stop');
  greyScale_start = new valueClass('greyScale_start');

  schoolTitleHeadPage = new valueClass('schoolTitleHeadPage');
  schoolName = new valueClass('schoolName');
  shortSchoolDescription  = new valueClass('shortSchoolDescription');
  metaTags  = new valueClass('metaTags');
  schoolAdress  = new valueClass('schoolAdress');
  latidute  = new valueClass('latidute');
  longitude  = new valueClass('longitude');
  mapHeight  = new valueClass('mapHeight');

  bannerImages  = new valueClass('bannerImages');
  bannerHeight  = new valueClass('bannerHeight');
  herbImage  = new valueClass('herbImage');
  herbImageMode  = new valueClass('herbImageMode');
  mapEnable  = new valueClass('mapEnable');


  defaultTheme  = new valueClass('defaultTheme');
  instagram  = new valueClass('instagram');
  twitter  = new valueClass('twitter');
  youTube  = new valueClass('youTube');
  facebook  = new valueClass('facebook');

  telephone  = new valueClass('telephone');
  email  = new valueClass('email');


  public ReadyDataObservable: BehaviorSubject<boolean>;

  ReturnValue: (name) => valueClass;
  ReturnFromDataBase: (table, whereName, where) => Promise<boolean>;
  ReturnFooterPanels: () => Array<any>;
  constructor(templateID: number) {
    this.ReadyDataObservable = new BehaviorSubject(false);
    this.templateID = templateID;
    SettingsConfigClass.prototype.ReturnFromDataBase = returnFromDataBase;
    SettingsConfigClass.prototype.ReturnValue = returnValueFunction;
  }

}














export class LethtemplateConfigClass {
  public templateID:number;
  public  imagesCircles = new valueClass('imagesCircles');
  public ReadyDataObservable: BehaviorSubject<boolean>;
  ReturnFromDataBase: (table, whereName, where) => Promise<boolean>;
  ReturnValue: (name) => valueClass;
  ReturnFooterPanels: () => Array<any>;
  ReturnMultiImage: (name) => Array<number>;
  constructor(templateID: number) {
    this.ReadyDataObservable = new BehaviorSubject(false);
    this.templateID = templateID;
    LethtemplateConfigClass.prototype.ReturnFromDataBase = returnFromDataBase;
    LethtemplateConfigClass.prototype.ReturnValue = returnValueFunction;
    LethtemplateConfigClass.prototype.ReturnFooterPanels = ReturnFooterPanelsFunction;
    LethtemplateConfigClass.prototype.ReturnMultiImage = returnMultiImage;
  }
}


export class BlueSkytemplateConfigClass {

  private templateID: number;
  public bannerImage = new valueClass('bannerImage');
  public herbImage = new valueClass('herbImage');
  public bannerImageAlt = new valueClass('bannerImageAlt');
  public herbImageAlt = new valueClass('herbImageAlt');
  public articleDisplayLimit = new valueClass('articleDisplayLimit');
  private footerPanelsCounter = 8;
  public footer_panel_1_status = new valueClass('footer_panel_1_status');
  public footer_panel_2_status = new valueClass('footer_panel_2_status');
  public footer_panel_3_status = new valueClass('footer_panel_3_status');
  public footer_panel_4_status = new valueClass('footer_panel_4_status');
  public footer_panel_5_status = new valueClass('footer_panel_5_status');
  public footer_panel_6_status = new valueClass('footer_panel_6_status');
  public footer_panel_7_status = new valueClass('footer_panel_7_status');
  public footer_panel_8_status = new valueClass('footer_panel_8_status');
  public footer_panel_1 = new valueClass('footer_panel_1');
  public footer_panel_2 = new valueClass('footer_panel_2');
  public footer_panel_3 = new valueClass('footer_panel_3');
  public footer_panel_4 = new valueClass('footer_panel_4');
  public footer_panel_5 = new valueClass('footer_panel_4');
  public footer_panel_6 = new valueClass('footer_panel_6');
  public footer_panel_7 = new valueClass('footer_panel_7');
  public footer_panel_8 = new valueClass('footer_panel_8');
  public footer_panel_google_maps_id = new valueClass('footer_panel_google_maps_id');
  public map_height = new valueClass('map_height');

  public diary_link = new valueClass('diary_link');
  public diary_status = new valueClass('diary_status');
  public lucky_number_status = new valueClass('lucky_number_status');
  public timer_status = new valueClass('timer_status');
  public timeToHoliday_status = new valueClass('timeToHoliday_status');


  public ReadyDataObservable: BehaviorSubject<boolean>;
  ReturnFromDataBase: (table, whereName, where) => Promise<boolean>;
  ReturnValue: (name) => valueClass;
  ReturnFooterPanels: () => Array<any>;
  ReturnMultiImage: (name) => Array<number>;
  constructor(templateID: number) {
    this.ReadyDataObservable = new BehaviorSubject(false);
    this.templateID = templateID;
    BlueSkytemplateConfigClass.prototype.ReturnFromDataBase = returnFromDataBase;
    BlueSkytemplateConfigClass.prototype.ReturnValue = returnValueFunction;
    BlueSkytemplateConfigClass.prototype.ReturnFooterPanels = ReturnFooterPanelsFunction;
    BlueSkytemplateConfigClass.prototype.ReturnMultiImage = returnMultiImage;
  }
}


export function ReturnFooterPanelsFunction(): Array<any> {
  let footerArray = [];
  for (let index = 1; index <= this.footerPanelsCounter; index++) {
    if (this.ReturnValue('footer_panel_' + index + '_status').value == 'true') {
      footerArray.push([this.ReturnValue('footer_panel_' + index).value, this.ReturnValue('footer_panel_' + index).name])
    }
  }
  return footerArray;
}

export function returnValueFunction(name): valueClass {
  if (this[name] == undefined) {
    console.log('Nie znaleziono szukanego paramteru w obiekcie')
    return new valueClass('error');
  }
  return this[name]
}

export function returnFromDataBase(table, whereName, where): Promise<boolean> {

  return new Promise((resolve, reject) => {
    ApiService.instance.GetFromDataBase(null, table, whereName, where, ['id', 'name', 'value'])
    .then((data: Array<SettingFromDataBaseClass>) => {
        data.forEach(element => {
          if (this[element.name] != undefined) {
            this[element.name].setValue(element.value);
          } else {
            console.info('Config object no param in class: ' + element.name)
          }
        })

        this.ReadyDataObservable.next(true);
        resolve(true)
      }).catch(data => {
       if(this.ReadyDataObservable == undefined){
         console.log("No ReadyDataObservable defined")
       }
        reject(false)
      })

  })
}


export function returnMultiImage(name: string): Array<number> {
  var tmp = null
  tmp = this[name].value_.subscribe(value => {
    console.log(value);
  });


  return [132,323];

}

export class SettingFromDataBaseClass {
  name: string;
  value: string;
  id: number;
}
export class valueClass {
  value: any;
  value_: BehaviorSubject<any>;
  name: string;
  Bvalue: boolean;
  constructor(name: string,) {
    this.value_ = new BehaviorSubject('null')
    this.name = name;
    this.value = 'null';
    this.Bvalue = null;
  }
  setValue(value: any) {
    this.value_.next(value)
    this.value = value;
    this.Bvalue = value == 'true' ? true : false;

  }
}



















export class blueSkySettingClass {
  bannerImage: number;
  bannerImageAlt: string;
  herbImage: number;
  herbImageAlt: string;
  articleDisplayLimit: number;
  constructor() {
    this.bannerImage = 0;
    this.bannerImageAlt = 'błąd';
    this.herbImage = 0;
    this.herbImageAlt = 'błąd';
    this.articleDisplayLimit = null;
  }
  returnValue(name) {
    return this[name]
  }
}
export class blueSkySettingFromDataBaseClass {
  name: string;
  value: string;
}
export class TemplateNameClass {
  id: number;
  name: string;
  theme: Array<TemplateThemeClass>;
  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.theme = [];
  }
}

export class TemplateThemeClass {
  id: number;
  template_id: number;
  name: string;
  constructor(id: number, name: string, template_id: number) {
    this.id = id;
    this.name = name;
    this.template_id = template_id;
  }
}
