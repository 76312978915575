import { Injectable, OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CookiesService } from '../cookies/cookies.service';
import { Router } from '@angular/router';
import { FennecApiSecurityClass, TokenClass } from 'src/app/object/fennecApiClass';
import { UpdateSecurityService } from './update.security.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private tokenObject = new BehaviorSubject<TokenClass>(null);
  public webSiteCode = null;
  public userName = "none";
  static instance: SecurityService;
  constructor(
    private _cookies: CookiesService,
    private _api: ApiService,
    private _router: Router,
    private _updateSecurityService: UpdateSecurityService
  ) {
    SecurityService.instance = this;
  }
  public safeReturnTokenObjectTokenValue() {
    if(this.tokenObject.value != null){
      return this.tokenObject.value.token;
    } else {
      return null;
    }
  }
  public returnTokenObject(): BehaviorSubject<TokenClass> {
    if (this.tokenObject == null) {
       this.logOut();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: 'Security error: logOut !!!',
        showConfirmButton: false,
        timer: 1500
      });
      throwError('TokenObject NULL')
      return new BehaviorSubject<TokenClass>(null);
    }
    return this.tokenObject;
  }
  public returnTokenObjectObservable(): Observable<TokenClass> {
    return this.tokenObject;
  }
  public checkCookies(): boolean {
    if (this._cookies.CheckCookies('token') && this._cookies.CheckCookies('access') && this._cookies.CheckCookies('tokenLifeTime')) {
      this.userName =  this._cookies.GetCookies('userName');
      const tokenTemp = this._cookies.GetCookies('token');
      this.webSiteCode = this._cookies.GetCookies('webSiteCode');
      const accessTemp = this._cookies.GetCookies('access');
      const tokenLifeTimeTemp = this._cookies.GetCookies('tokenLifeTime');
      const endTokenLifeDateTemp = this._cookies.GetCookies('endTokenLifeDate');
      const tokenClassTemp = new TokenClass(tokenLifeTimeTemp, endTokenLifeDateTemp, tokenTemp, accessTemp, 'Token from cookiess');
      this.tokenObject.next(tokenClassTemp);
      return true;
    } else {
      return false;
    }
  }
  public logOut(): Promise<string> {

    return new Promise((resolve, reject) => {
      if (this.tokenObject.value == null) {
      reject('NoUser');
      }
      this._api.LogOut(this.tokenObject.value.token).then(value => {

          this._cookies.DeleteCookis('token');
          this._cookies.DeleteCookis('webSiteCode');
          this._cookies.DeleteCookis('access');
          this._cookies.DeleteCookis('tokenLifeTime');
          this._cookies.DeleteCookis('userName');
          this._cookies.DeleteCookis('endTokenLifeDate');
          this._router.navigate(['/']);
          this.tokenObject.next(null);
          resolve('Success');
        }).catch(value => {
          this._cookies.DeleteCookis('token');
          this._cookies.DeleteCookis('access');
          this._cookies.DeleteCookis('tokenLifeTime');
          this._cookies.DeleteCookis('userName');
          this._cookies.DeleteCookis('webSiteCode');
          this._cookies.DeleteCookis('endTokenLifeDate');
          this.tokenObject.next(null);
          this._router.navigate(['/']);
          reject('mediumSuccess');

      });
    });
  }
  private updateTokenLifeTimeInit(): void {
    var tmp = this.tokenObject.value;
    const end_datetime = new Date(new Date().getTime() + (1000 * tmp.tokenLifeTime));// tslint:disable-line
    tmp.endTokenLifeDate = end_datetime;
    this.tokenObject.next(tmp);
    this._cookies.CreateCookies('token', tmp.token, end_datetime);
    this._cookies.CreateCookies('access', tmp.access, end_datetime);
    this._cookies.CreateCookies('tokenLifeTime', tmp.tokenLifeTime, end_datetime);
    this._cookies.CreateCookies('endTokenLifeDate', tmp.endTokenLifeDate, end_datetime);
    this._cookies.CreateCookies('userName', this.userName, end_datetime);
    this._updateSecurityService.update.next(false);
  }

  public logOutInit() {
 this._updateSecurityService.securityLogOut.subscribe(data=>{
    if(data == true){
      this.securityLogOut("Błąd zapytania do bazy danych");
    }
  })
  }
  private logOutAnalizer(security: FennecApiSecurityClass): void {

    if (String(security.accessGranted) == 'false') {
      this.securityLogOut("Zapytanie do bazy danych nie dostało uprawnień");
    }
  }
  public dataBaseTokenReturnAnalizerInit() {
    this._updateSecurityService.securityTokenObject.subscribe(value => {
     // console.log(value)
      if (value != null) { // pominięcie pustego zwrócenia wartośći
        if (String(value.security.token_Access) == "true") {// kiedy token zwrócićprzyznanie dostępu
          this.updateTokenLifeTimeInit();
          this.logOutAnalizer(value.security);
        }
      }
    })
  }
  public AutoLogOutInit(): void {
    setInterval(() => {
      //console.log(Math.round((new Date(this._cookies.GetCookies('endTokenLifeDate')).getTime() - new Date().getTime()) / (1000))+"sec")
      if(!this.checkCookies() && this.tokenObject.value != null){ // jeżeli ciasteczka wygasły
          this._cookies.DeleteCookis('token');
          this._cookies.DeleteCookis('webSiteCode');
          this._cookies.DeleteCookis('access');

          this._cookies.DeleteCookis('tokenLifeTime');
          this._cookies.DeleteCookis('endTokenLifeDate');
          this._router.navigate(['/']);
          this.tokenObject.next(null);
          Swal.fire({
           toast: true,
           position: 'top-end',
           icon: 'info',
           title: 'Przekroczono limit czasu bezczynności',
           showConfirmButton: false,
           timer: 7000
         })
      } else{
      }
    }, 1000);
  }
  public securityLogOut(reason = "no"): void {
    //! !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.logOut().then(data=>{

      }).catch(data=>{

      });
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: 'Security error: logOut !!! .',
      showConfirmButton: false,
      timer: 1500
    });
    throw ('Security error: logOut !!! : ' + reason); // tslint:disable-line: no-string-throw
  }
}
