import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleObjectClass } from '../object/articleClass';
import { galleryPageStorageClass } from '../object/galleryPageClass';
import { subPageObjectClass } from '../object/subPageClass';
import { LethtemplateConfigClass, SettingsConfigClass } from '../object/templateClass';
import {footerObjectClass} from './../object/footer.class'
import { TemplateRouterClass } from './../object/templateRouter.class';
import { ConfigFileService } from './config-file.service';
import { GeneralService } from './general.service';
@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  public footerObject = new footerObjectClass();
  public subPagesObject = new subPageObjectClass();
  public galleryObject = new galleryPageStorageClass();
  public articlesObject = new ArticleObjectClass(this._router,this._ActivatedRoute);
  public settings_ =  new SettingsConfigClass(0);
  public LethTemplateSettings = new LethtemplateConfigClass(8);
  public TemplateRouterObject = new TemplateRouterClass();



  public apiUrl;
  public apiUrlFile;

  public activeTemplate;

  constructor(
    private _ConfigFileService: ConfigFileService,
    private _ActivatedRoute: ActivatedRoute,
    private _router: Router,
    private _generalService:GeneralService
  ) {
    this.SettingsInit();
    this.apiUrl = this._ConfigFileService.ConfigObject.ApiLinkGetImage;
  this.apiUrlFile = this._ConfigFileService.ConfigObject.ApiLinkGetFile;
  }
  SettingsInit(){
    this.settings_.ReturnFromDataBase('config', [], [])
    .then(data => {
    }).catch(data => {
    });
  }
  TemplateSettingsInit(){
    switch (this._ConfigFileService.ConfigObject.template) {
      case "8":
        this.LethTemplateSettings.ReturnFromDataBase('template_settings', ['template_id'], [String(this.LethTemplateSettings.templateID)]).then(data=>{
          console.log(  this.LethTemplateSettings)
        }).catch(data=>{
          console.log(data)
        });
        break;
    }

  }
  FooterInit(){

    this.footerObject.footerDataBaseInit();
  }
  SubPagesInit(){
    this.subPagesObject.SubPagesInit();
  }
  GalleryInit(){
    this.galleryObject.returnFormDataBase();
  }
  ArticleInit(){
    this.articlesObject.articleInit();
  }
  ArticleReset(){
    this.articlesObject = new ArticleObjectClass(this._router,this._ActivatedRoute);
    this.ArticleInit();
  }
  SubPagesReset(){
    this.subPagesObject = new subPageObjectClass();
    this.SubPagesInit();
  }




  elementAction(element) {
    switch (element.mode) {
      case 'href':    case 'link':
        window.open(element.menuModeValue);//, '_blank'
        break;
      case 'download':
        window.open(this._generalService.apiUrlFile + element.menuModeValue);//, '_blank'
        break;
      case 'subPage':
        this._router.navigate(['/' + this.TemplateRouterObject.ReturnSubPage() + '/', element.menuModeValue]);
        break;
      case 'article':
        this._router.navigate(['/' + this.TemplateRouterObject.ReturnArticle() + '/', element.menuModeValue]);
        break;
      case 'galleryList':
        this._router.navigate(['/' + this.TemplateRouterObject.ReturnGalleryList()]);
        break;
      case 'Articlecategory':
        this._router.navigate(['/' + this.TemplateRouterObject.ReturnArticleCategory() + '/', element.menuModeValue]);
        break;
      case 'pageMap':
        this._router.navigate(['/' + this.TemplateRouterObject.ReturnPageMap()]);
        break;
      default:
        break;
    }
  }
  elementAriaLabel(element) {
    switch (element.mode) {
      case 'href':    case 'link':
        return "Link do strony: " + element.name;
      case 'download':
        return "Link do pliku: " + element.name;
      case 'subPage':
        return "Link do podstrony: " + element.name;
      case 'article':
        return "Link do artykułu: " + element.name
      case 'galleryList':
        return "Link do listy galerii";
      case 'Articlecategory':
        return "Link do listy artykułów z kategorii: " + element.menuModeValue
      case 'pageMap':
        return "Link do mapy strony";
      default:
        break;
    }
  }

}
