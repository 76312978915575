import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigFileService {
  public ConfigObject:configFileClass = null;
  static instance: ConfigFileService;
  constructor(
    private _http: HttpClient
  ) {
    ConfigFileService.instance = this;
  }
Load() :Promise<any>  {
      const promise = this._http.get('./assets/appConfig.json')
        .toPromise()
        .then((data:configFileClass) => {
          this.ConfigObject = data;
          return data;
        });
      return promise;
}
}
export class configFileClass {
  ApiLink:string;
  ApiLinkGetFile:string;
  ApiLinkGetImage:string;
  homeApiUrl:string;
  webSiteID:string;
  template:string;
  reCaptchaKey:string;
  pageDisabled:string;
  defaultTheme:string;
  theme:string;
}
