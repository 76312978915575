import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeService } from 'src/app/services/font-awesome.service';
import { ArticlesClass } from 'src/app/object/articleClass';
@Component({
  selector: 'app-predefined-article-information',
  templateUrl: './predefined-article-information.component.html',
  styleUrls: ['./predefined-article-information.component.scss']
})
export class PredefinedArticleInformationComponent implements OnInit {
  @Input()  article: ArticlesClass;
  @Input()  categoryLink: ArticlesClass;
  @Input()  skipToCategory: string;
  @Input()  CategoryID: string;
  constructor(
    public _fontAwesome:FontAwesomeService) { }

  ngOnInit(): void {
   // console.log(this.article)
  }

}
