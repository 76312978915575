import { timingSafeEqual } from "crypto";
import Swal from "sweetalert2";
import { ApiService } from "../services/api/api.service";
import { SecurityService } from "../services/security/security.service";
export class footerObjectClass {
  private footerInit = false;
  public footers: Array<footerClass> = []
  private refresh = false;
  public buttonActive: boolean = true;
  resetData() {
    this.footerInit = false;
    this.footers = [];
    this.refresh = true;
    this.footerDataBaseInit();

  }


  setActiveButton() {
    this.buttonActive = false;
  }
  returnFooterPanels(): Array<footerClass> {
    var tmp: Array<footerClass> = [];
    this.footers.forEach(a => {
      if (a.enabled == true) {
        tmp.push(a);
      }
    })
    return tmp;
  }
  footerDataBaseInit() {

    this.footerInit = true;
    this.footers = [];
    var i = 0;
    ApiService.instance.GetFromDataBase(null, 'footers', [], [], [])
      .then(data => {
        data.forEach((element: footerClass) => {
          var tmp = new footerClass(i++, element.id, element.title, element.body, element.enabled, element.map);
          this.footers.push(tmp);
        });
        if (this.refresh) {
          this.refresh = false;
          Swal.close();
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Dane odświeżone',
            showConfirmButton: false,
            timer: 3000
          });
        }
      }).catch(data => {
        Swal.close();
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Ładowanie panelów stopki zwróciło błąd połączenia z bazą danych',
          showConfirmButton: false,
          timer: 3000
        });
      })
  }
  addFooterPanel() {
    var toSend = {};
    toSend['title'] = "brak";
    toSend['body'] = "brak";
    toSend['enabled'] = "0";
    ApiService.instance.addToDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), toSend, 'footers')
      .then(data => {
        this.footerInit = false;
        this.footerDataBaseInit();
      }).catch(data => {
      });
  }

  returnPanelWithID(id): footerClass {
    let a = this.footers.filter(a => {
      return a.id == id;
    })
    return a[0];
  }

  updateInDataBase() {
    this.buttonActive = true;
    var sub = [];
    this.footers.forEach(a => {
      if (a.toDelete == true) {
        sub.push(new Promise<boolean>((resolve, reject) => {
          sub.push(ApiService.instance.deleteInDataBase(SecurityService.instance.safeReturnTokenObjectTokenValue(), 'footers', ['id'], [String(a.id)])
            .then(data => {
              resolve(true);
            }).catch(data => {
              reject(false);
            })
          )
        })
        )
      }
      if (a.dirty == true) {
        var toSend = {};
        toSend['title'] = a.title;
        toSend['body'] = a.body;
        toSend['enabled'] = a.enabled == true?1:0;
        toSend['map'] = a.map == true?1:0;
        sub.push(new Promise<boolean>((resolve, reject) => {
          ApiService.instance.updateInDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), toSend, 'footers', ['id'], [String(a.id)])
            .then(data => {
              resolve(true);
            }).catch(data => {
              reject(false);
            });
        })
        );
      };
    });
    Promise.all(sub).then(a => {
      Swal.close();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: 'Aktualizacja danych udana',
        showConfirmButton: false,
        timer: 3000
      });
      this.resetData();
    }).catch(a => {
      Swal.close();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: 'Aktualizacja danych nie udana',
        showConfirmButton: false,
        timer: 3000
      });
      this.resetData();
    })
  }


  footerSizeControler(a = 30) {
    setTimeout(() => {
      this.footers.forEach(panel => {
        if (panel.enabled == true) {
          const i = document.getElementById('footer_panel_' + panel.count);
          if (i != null) {
            const p = i.innerHTML
            if (p != null) {
              var z = p.match(/efennec_[0-9]{1,}image/);
              //     console.log(z)
              if (z != null) {
                //    console.log(z)
                z.forEach(a => {
                  document.getElementById(a).classList.add("footerPanelImageSizeRepair");
                });
              }
            }
          }
        }
        if (panel.count % 2 == 0 && panel.enabled == true) {
          if (document.getElementById('footer_panel_' + panel.count) != null && document.getElementById('footer_panel_' + (panel.count + 1)) != null) {
            if (document.getElementById('footer_panel_' + panel.count).offsetHeight < document.getElementById('footer_panel_' + (panel.count + 1)).offsetHeight) {
              document.getElementById('footer_panel_' + panel.count).style.minHeight = document.getElementById('footer_panel_' + (panel.count + 1)).offsetHeight + 'px';
            } else {
              document.getElementById('footer_panel_' + (panel.count + 1)).style.minHeight = document.getElementById('footer_panel_' + panel.count).offsetHeight + 'px';
            }
          }
        }
      });
      if (a < 1500) {
        a += a / 10;
      }
      this.footerSizeControler(a);
    }, a);
  }


}

export class footerClass {
  id: number;
  title: string;
  body: string;
  enabled: boolean;
  map: boolean;
  dirty: boolean;
  toDelete: boolean;
  count: number;
  htmlID: string;
  constructor(count: number, id: number, title: string, body: string, enabled: boolean, map: boolean) {
    this.toDelete = false;
    this.id = id;
    this.dirty = false;
    this.title = title;
    this.body = body;
    this.enabled = enabled;
    this.map = map;
    this.count = count;
    this.htmlID = "footer_panel_" + count;;
  }
  setDisable(): void {
    this.enabled = false;
    this.dirty = true;
  }
  setToDelete(): void {
    this.toDelete = true;
  }
  setEnable(): void {
    this.dirty = true;
    this.enabled = true;
  }
  setMap(mode): void {
    this.dirty = true;
    this.map = mode;
  }
  setBody(value): void {
    this.body = value;
    this.dirty = true;
  }
  setTitle(value): void {
    this.title = value;
    this.dirty = true;
  }
  cleanDirty(): void {
    this.dirty = false;
  }
}
