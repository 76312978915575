import { ImagesStorageService } from '../../../services/ImageStorage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-image-view',
  templateUrl: './big-image-view.component.html',
  styleUrls: ['./big-image-view.component.scss']
})
export class BigImageViewComponent implements OnInit {

  constructor(
    public _activeModal :NgbActiveModal,
    public _galleryService: ImagesStorageService
  ) { }

  ngOnInit(): void {

  }

}
