import { EmptyComponent } from './user/empty/empty.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateGuardService } from './services/security/templateGuard.service';
import { PageDisableComponent } from './user/page-disable/page-disable.component';
import { PageDisableGuardService } from './services/security/pageDisableGuard.service';

const routes: Routes = [

  /*{
    path: '_',
    // wywołanie Strażnika który pobiera number szablonu z bazy danych, a następnie wywołuje odpowieni szablon
   // canActivate: [TemplateGuardSwitcherService],
  },*/
  {
    path: '',
    loadChildren: () => import('./administrator/lazy-administrator.module').then(m => m.LazyModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./administrator/lazy-administrator.module').then(m => m.LazyModule)
  },
  /*{
    path: 'blueSky',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [1] }, // id szablonu
    loadChildren: () => import('./user/templates/blueSky/lazy-blueSky.module').then(m => m.BlueSkyTemplateLazyModule)
  },
  {
    path: 'orangeSky',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [2] }, // id szablonu
    loadChildren: () => import('./user/templates/orangeSky/lazy-orangeSky.module').then(m => m.OrangeSkyTemplateLazyModule)
  },
  {
    path: 'bunny',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [3] }, // id szablonu
    loadChildren: () => import('./user/templates/bunny/bunny-lazy-routing.module').then(m => m.BunnyTemplateLazyRoutingModule)
  },
  {
    path: 'angela',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [4] }, // id szablonu
    loadChildren: () => import('./user/templates/angela/angela-lazy-routing.module').then(m => m.AngelaTemplateLazyRoutingModule)
  },
  {
    path: 'patio',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [5] }, // id szablonu
    loadChildren: () => import('./user/templates/patio/patio-lazy-routing.module').then(m => m.PatioTemplateLazyRoutingModule)
  },
  {
    path: 'baks',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [6] }, // id szablonu
    loadChildren: () => import('./user/templates/baks/baks-lazy-routing.module').then(m => m.BaksTemplateLazyRoutingModule)
  },
  {
    path: 'ara',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [7] }, // id szablonu
    loadChildren: () => import('./user/templates/ara/ara-lazy-routing.module').then(m => m.AraTemplateLazyRoutingModule)
  },
  {
    path: 'leth',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [8] }, // id szablonu
    loadChildren: () => import('./user/templates/leth/leth-lazy-routing.module').then(m => m.LethTemplateLazyRoutingModule)
  },
  {
  path: 'enif',
    // zabezpieczenie przed wywołaniem innego szablonu niż ten wpisany w bazie danych
    canActivate: [TemplateGuardService, PageDisableGuardService],
    data: { templateID: [9] }, // id szablonu
    loadChildren: () => import('./user/templates/enif/enif-lazy-routing.module').then(m => m.EnifTemplateLazyRoutingModule)
},*/
  {
    path: '',
    // redirectTo:'_',
    // wywołanie Strażnika który pobiera number szablonu z bazy danych, a następnie wywołuje odpowieni szablon
   // canActivate: [TemplateGuardService],
   // component: EmptyComponent,
   // pathMatch: 'full',
    component: EmptyComponent,

  },
  {

    path: 'pageDisable',
    component: PageDisableComponent
  },
 {
    path: '**',
    redirectTo: 'pageNotFound'
  },
  {
    path: 'pageNotFound',
    component: PageNotFoundComponent
  },

];

@NgModule({
  declarations:[

  ],
  imports: [
    RouterModule.forRoot(routes/*,{ enableTracing: true }*/)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
