import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeService } from 'src/app/services/font-awesome.service';
import { visitClass, VisitCounterService } from 'src/app/services/visit-counter.service';

@Component({
  selector: 'app-predefined-widget-vertical-panel-v1',
  templateUrl: './predefined-widget-vertical-panel-v1.component.html',
  styleUrls: ['./predefined-widget-vertical-panel-v1.component.scss']
})
export class PredefinedWidgetVerticalPanelV1Component implements OnInit {

  visit: visitClass = new visitClass;
  constructor(
    public _fontService: FontAwesomeService,
    private _visit: VisitCounterService
  ) { }

  ngOnInit(): void {
    this._visit.returnVisitDate().then(data=>{
      this.visit.setData(data.today,data.yesterday,data.week,data.month,data.year,data.all_);
    });
  }
}
