import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ImagesStorageService } from 'src/app/services/ImageStorage.service';

@Component({
  selector: 'app-predefined-gallery-list',
  templateUrl: './predefined-gallery-list.component.html',
  styleUrls: ['./predefined-gallery-list.component.scss']
})
export class PredefinedGalleryListComponent implements OnInit {
  @Input()  galleryPath: string;
  constructor(
    public _generalService: GeneralService,
    public _ImagesStorage: ImagesStorageService,
  ) { }
  ngOnInit(): void {
  }

  number(a){
    return Number(a)
  }
}
