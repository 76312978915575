import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageNamePipe'
})
export class ImageNamePipe implements PipeTransform {
  constructor() { }
  transform(value: string) {
    const tmp = value.split('_')
    const tmpDotted = value.split('.')
    const filetype = tmpDotted[1];
    const filename  = tmp[0];
   return filename +'.'+ filetype;
  }


}
