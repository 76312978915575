import { Data } from '@angular/router';

export class FennecApiClass {
  generalCode: number;
  generalErrorStatus: boolean;
  detailedCode: number;
  serwerTime: Date;
  generalMessage: string;
  security: FennecApiSecurityClass;
  data: any;
  status: number;
}
export class FennecApiSecurityClass {
  accessGranted: string;
  accessLevel: number;
  checkedTable: string;
  generalTablePermissionsOutPut: boolean;
  token_Access: boolean;
  token_extendet: FennecApiSecurityToken_extendetClass;
}
export class FennecApiSecurityToken_extendetClass {
  access: boolean;
  dateOfTokenEndLife: Date;
  difference: number;
  differenceForUser: string;
  errorCode: number;
  lifeTime: boolean;
  message: string;
  time: Data;
  tokenSearch: boolean;
}

export class TokenClass {
  tokenLifeTime: number;
  endTokenLifeDate: Date;
  token: string;
  id: number;

  access: boolean;
  message: string;
  constructor(tokenLifeTime: number, endTokenLifeDate: Date, token: string, access: boolean, message: string) {
    this.tokenLifeTime = tokenLifeTime;
    this.endTokenLifeDate = endTokenLifeDate;
    this.token = token;
    this.access = access;
    this.message = message;
  }
}
/*
export class ImagesClass {
  id: number;
  image_name: string;
  image_size: number;
  patch: string;
  patchArray: Array<string>;
  alt: string;
  uploader_id: number;
  create_time: Date;
  ImageAsArray: Array<any>;
  ImageAsArrayCounter: number;
  title: string;
  constructor(id: number, image_name: string, image_size: number, patch: string, uploader_id: number, create_time: Date, alt: string, title: string) {
    this.id = id;
    this.alt = alt;
    this.image_name = image_name;
    this.image_size = image_size;
    this.patch = patch;
    this.uploader_id = uploader_id;
    this.create_time = create_time;
    this.title = title;
  }
}*/



export class FilesClass {
  id: number;
  name: string;
  size: number;
  patch: string;
  patchArray: Array<string>;
  uploader_id: number;
  create_time: Date;
  patch_id:number;
  ImageAsArray: Array<any>;
  ImageAsArrayCounter: number;
  constructor(id: number, image_name: string, image_size: number, patch: string, uploader_id: number, create_time: Date,patch_id:number) {
    this.id = id;
    this.name = image_name;
    this.size = image_size;
    this.patch = patch;
    this.uploader_id = uploader_id;
    this.create_time = create_time;
    this.patch_id = patch_id;
  }
}
