import { SecurityService } from './../services/security/security.service';
import { ApiService } from './../services/api/api.service';
import Swal from 'sweetalert2';

export class ThemeVariablesObjectClass {
    array: Array<themeVariableClass> = [];
    template_id: number;
    theme_id: number;
    disableButton = true;
    constructor(template_id: number, theme_id: number) {
        this.template_id = template_id;
        this.theme_id = theme_id;
        this.array = [];
    }
    SaveData(){
        var pro = [];
        this.array.forEach(element => {
           if(element.dirty){
             pro.push(element.updateInDataBase())
           }
        });
        Promise.all(pro).then(image => {
            this.disableButton = true
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Wartości zaktualizowane',
              showConfirmButton: false,
              timer: 3000
            });
          })
          .catch(() => {
            Swal.close();
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: 'Błąd aktualizacji wartości',
              showConfirmButton: false,
              timer: 3000
            });
          })
    }

    RefreshData(){
        this.array = [];
        this.returnColorForTheme()
    }
    setDirty(){
       this.disableButton=false;
    }
    returnColorForTheme(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            ApiService.instance.GetFromDataBase(/*SecurityService.instance.safeReturnTokenObjectTokenValue()*/null,
                'template_theme_variable', ['template_id'], [String(this.template_id)], []).then((data: Array<themeVariableClass>) => {
                    data.forEach(element => {

                        this.array.push(new themeVariableClass(element['id'], this.theme_id, element['variable_name'],element.description));
                    });
                    ApiService.instance.GetFromDataBase(/*SecurityService.instance.safeReturnTokenObjectTokenValue()*/null,
                        'template_theme_settings', ['theme_id'], [String(this.theme_id)], []).then((variableData: Array<themeVariableClass>) => {
                            variableData.forEach(value => {
                                this.array.find(aaaa => {
                                    if (aaaa.variable_id == value.variable_id) {
                                        aaaa.setValue(value['id'], value['value'])
                                        return
                                    }
                                })
                            });
                            resolve(true)
                        }).catch(a => {
                            reject(false);
                        });
                }).catch(() => {
                    reject(false);
                });
        });
    }
    deleteVariable(id){
        this.array.find(element=>{
            if(element.variable_id == id){
                element.deleteVariable().then(d=>{
                this.RefreshData();
                return
                });
            }
        })
    }
    addVariableToDataBase(variable,description): Promise<boolean> {
        return new Promise((resolve, reject) => {
            JSON['template_id']= this.template_id
            JSON['variable_name'] = variable;
            JSON['description'] = description;
            ApiService.instance.addToDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), JSON, 'template_theme_variable').then(data=>{
                this.RefreshData();
                Swal.close();
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: 'Zmienna dodana',
                  showConfirmButton: false,
                  timer: 3000
                });
                resolve(true)
            }).catch(data=>{
                Swal.close();
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'error',
                  title: 'Błąd dodawania zmiennej',
                  showConfirmButton: false,
                  timer: 3000
                });
                reject(false);
            })
            delete JSON['template_id'];
            delete JSON['variable_name'];
            delete JSON['description'];
        });
    }

}




export class themeVariableClass {
    value_id: number;
    variable_id: number;
    theme_id: number;
    variableName: string;
    variablevalue: string;
    template_id: number;
    description:string;
    descriptionDirty:boolean;
    dirty: boolean
    hasBadValue: boolean
    hasValue:boolean;
    transparent: number;
    color:string;

    constructor(variable_id: number, theme_id: number, variableName: string,description:string) {
        this.variablevalue = null;
        this.dirty = false;
        this.variable_id = variable_id;
        this.variableName = variableName;
        this.description = description;
        this.theme_id = theme_id;
        this.transparent = 255;
        this.hasValue = false;
        this.variablevalue = "";
        this.descriptionDirty = false;
        this.hasBadValue = false;
    }
    variableNameNoDynamic(): string{
        return this.variableName.slice(10)
    }
    setColor(color){
        this.color = color;
        this.dirty = true;
        this.variablevalue = this.color +  Number(this.transparent).toString(16);
    }
    setTransparent(transparent){
        this.transparent = transparent;
        this.dirty = true;
        this.variablevalue = this.color +  Number(transparent).toString(16);
    }
    liteSetValue(value){
        this.variablevalue = value;
        this.color  = this.variablevalue.slice(0, 7);
        this.transparent = parseInt(this.variablevalue.slice(7, 9),16);
        if(isNaN(this.transparent)){
            this.transparent = 255;
        }
        this.dirty = true;
    }
    setDescription(val:string){
        this.descriptionDirty = true;
        this.description = val
    }

    updateDescription(){

        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
            JSON['description'] =  this.description;

            // tslint:disable-next-line: max-line-length
            ApiService.instance.updateInDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(),
                JSON, 'template_theme_variable', ['id'], [String(this.variable_id)]).then((data: any) => {
                    Swal.close();
                    Swal.fire({
                      toast: true,
                      position: 'top-end',
                      icon: 'success',
                      title: 'Opis dodany',
                      showConfirmButton: false,
                      timer: 3000
                    });
                    this.descriptionDirty = false;
                    resolve(true)
                }).catch(() => {
                    reject(false);
                });
            delete JSON['description'];
        });
    }
    deleteVariable(){
        return new Promise((resolve, reject) => {
        Swal.fire({
            title: 'Jesteś pewny ?',
            text: "Czy na pewno!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Tak usuń!'
          }).then((result) => {
            if (result.isConfirmed) {
                ApiService.instance.deleteInDataBase(SecurityService.instance.safeReturnTokenObjectTokenValue(),
                'template_theme_variable',['id'],[String(this.variable_id)]).then((data: any) => {
                    Swal.close();
                    Swal.fire({
                      toast: true,
                      position: 'top-end',
                      icon: 'success',
                      title: 'Zmienna usunięta',
                      showConfirmButton: false,
                      timer: 3000
                    });
                    resolve(true)
                    this.descriptionDirty = false;
                }).catch(() => {
                    reject(false);
                });
            }
          })
        });

    }
    setValue(id: number, variableValue: string = '') {
        this.value_id = id;
        this.variablevalue = variableValue;
        this.color  = this.variablevalue.slice(0, 7);
        this.transparent = parseInt(this.variablevalue.slice(7, 9),16);
        if(isNaN(this.transparent)){
            this.transparent = 255;
        }
        this.hasValue = true;
     }
    createVariable(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            JSON['variable_id'] = this.variable_id;
            JSON['theme_id'] = this.theme_id;
            ApiService.instance.addToDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(), JSON, 'template_theme_settings').then(data=>{
                this.hasBadValue = true;
                resolve(true)
            }).catch(data=>{
                reject(false);
            })
            delete JSON['variable_id'];
            delete JSON['theme_id'];
        });
    }
    updateInDataBase(): Promise<boolean> {
        console.log( this.variablevalue)
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
            JSON['value'] = this.variablevalue;

            // tslint:disable-next-line: max-line-length
            ApiService.instance.updateInDatabase(SecurityService.instance.safeReturnTokenObjectTokenValue(),
                JSON, 'template_theme_settings', ['id'], [String(this.value_id)]).then((data: any) => {
                    resolve(true)
                }).catch(() => {
                    reject(false);
                });
            delete JSON['value'];
        });
    };
}
