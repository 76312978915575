
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PipeService {

  public randomStaticNumber = new BehaviorSubject(Math.round(Math.random() * 1337220006926))
  constructor() { }

  public NewSafeNumber() {
    this.randomStaticNumber.next(Math.round(Math.random() * 1337220006926));
  }
}
