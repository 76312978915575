import { ImagesClass, ImageStorage } from '../object/gallery.class';
import { ApiService } from './api/api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigFileService } from './config-file.service';

@Injectable({
  providedIn: 'root'
})
export class ImagesStorageService {
  public galleryStorage = new BehaviorSubject<ImageStorage>(new ImageStorage(this._ConfigFileService.ConfigObject.ApiLinkGetImage));
  bigImageViewSrc: string;

  constructor(
    private _ConfigFileService: ConfigFileService,
    private _api: ApiService,

  ) {

   }

  getImageAllInformation(id): Promise<ImagesClass>{
    return new Promise((resolve, reject) => {
      this._api.GetFromDataBase(null,'images',['id'],[String(id)],[]).then(data=>{
        resolve(data)
      }).catch(data=>{
      console.log('Zdjęcie nie znalezione')
       });
    });
  }

}
