import { Pipe, PipeTransform } from '@angular/core';
import { articleFunctionCLass } from '../object/articleClass';


@Pipe({
  name: 'linkRepair'
})
export class LinkRepairPipe implements PipeTransform {
  constructor(){}
  public transform(value: string, type: string) {
    switch (type) {
      case 'fromDataBase':
        return articleFunctionCLass.text_files_converter(value, 'fromDataBase');
      default:
        break;
    }
  }

}

