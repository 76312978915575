<section class="closeSection">
    <button type="button" class="close" aria-label="Close" (click)="_activeModal.dismiss('Cross click')">
        <span>x</span>
    </button>
</section>
<section class="text" *ngIf="_clientDataService.settings_.welcome_panel_value_mode.value == 'text'">
    <p [innerHTML]="_clientDataService.settings_.welcome_panel.value | safe_variable: 'html' ">welcome-panel works!
    </p>
</section>
<section class="image"  *ngIf="_clientDataService.settings_.welcome_panel_value_mode.value == 'image'" >
  <img
    [src]="_ImageStorage.galleryStorage.value.returnImage(_clientDataService.settings_.welcome_panel_image_id.value).src"
    [alt]="_ImageStorage.galleryStorage.value.returnImage(_clientDataService.settings_.welcome_panel_image_id.value).alt"
    [title]="_ImageStorage.galleryStorage.value.returnImage(_clientDataService.settings_.welcome_panel_image_id.value).title">
</section>
