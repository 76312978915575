<section class="content">
  <section class="panel">
    <a class="panelHover">
      <div class="left">
        <fa-icon
          class="icons2"
          [icon]="_fontService.faChalkboardTeacher"
        ></fa-icon>
      </div>
      <div class="right">
        <h3>E-dziennik</h3>
      </div>
    </a>
  </section>



  <section class="panel">
    <a class="panelHover" [routerLink]="['/ara/galleryList']">
      <div class="left">
        <fa-icon class="icons2" [icon]="_fontService.faImages"></fa-icon>
      </div>
      <div class="right">
        <h3>Galeria</h3>
      </div>
    </a>
  </section>


  <section class="panel">
    <a class="panelHover">
      <div class="left">
        <fa-icon class="icons2" [icon]="_fontService.faFileAlt"></fa-icon>
      </div>
      <div class="right">
        <h3>RODO</h3>
      </div>
    </a>
  </section>
  <section class="panel">
    <a class="panelHover">
      <div class="left">
        <fa-icon class="icons2" [icon]="_fontService.faFileAlt"></fa-icon>
      </div>
      <div class="right">
        <h3>Statut szkoły</h3>
      </div>
    </a>
  </section>
  <section class="panel">
    <a class="panelHover">
      <div class="left">
        <fa-icon class="icons2" [icon]="_fontService.faFileAlt"></fa-icon>
      </div>
      <div class="right">
        <h3>Deklaracja dostępności</h3>
      </div>
    </a>
  </section>

  <section class="panel" >
    <div class="top">
      <h3>Licznik odwiedzin</h3>
    </div>
    <div class="visitCounter">
      <fa-icon [icon]="_fontService.faUsers"></fa-icon>
      <table>
        <tr>
          <td>Dzisiaj:</td>
          <td>{{ visit.today }}</td>
        </tr>
        <tr>
          <td>Wczoraj:</td>
          <td>{{ visit.yesterday }}</td>
        </tr>
        <tr>
          <td>W tym tygodniu:</td>
          <td>{{ visit.week }}</td>
        </tr>
        <tr>
          <td>W tym miesiącu:</td>
          <td>{{ visit.month }}</td>
        </tr>
        <tr>
          <td>W tym roku:</td>
          <td>{{ visit.year }}</td>
        </tr>
        <tr>
          <td>Całość:</td>
          <td>{{ visit.all_ }}</td>
        </tr>
      </table>
    </div>
  </section>


</section>
