import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mathPipe'
})
export class MathPipe implements PipeTransform {
  constructor() { }
  transform(value: any, type: string) {
    switch (type) {
      case 'round': return Math.round(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }


}
