import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SwitchThemeService {
  insertedElement: HTMLElement;
  id = '';
  styleArray = [];
  constructor() {
    // this.loadTheme();
  }

  loadTheme(selectedTheme, id): void {
    this.id = 'secundSCSS_' + id;
    import(
      /* webpackChunkName: "[request]" */
      `../../assets/style/${selectedTheme}.js`
    )
      .then((s) => s.default)
      .then(this.insertToDom);
  }
  insertToDom = (content: string) => {
    if (document.getElementById(this.id) != null) {
      document.getElementById(this.id).remove();
    } else {
      this.styleArray.push(this.id);
    }
    const element = document.createElement('style');
    element.textContent = content;
    element.id = this.id;
    document.head.appendChild(element);
  }
  removeTheme(id): void {
    const newid = 'secundSCSS_' + id;
    document.getElementById(newid).remove();
    this.styleArray = this.styleArray.filter(value => {
      return (value !== newid);
    });
  }
  returnThemeInUse(): Array<string> {
    return this.styleArray;
  }

}

