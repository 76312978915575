import { WelcomePanelComponent } from './user/modal/welcome-panel/welcome-panel.component';
import { ConfigFileService } from './services/config-file.service';
import { PageDisableGuardService } from './services/security/pageDisableGuard.service';
import { PageDisableComponent } from './user/page-disable/page-disable.component';
import { ImagesStorageService } from './services/ImageStorage.service';
import { GalleryImageBigViewComponent } from './user/modal/gallery-image-big-view/gallery-image-big-view.component';
import { BigImageViewComponent } from './user/modal/big-image-view/big-image-view.component';
import { GeneralTemplateService } from './services/general-template.service';
import { EmptyComponent } from './user/empty/empty.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TemplateGuardService } from './services/security/templateGuard.service';
import { RouterOutletService } from './services/router-outlet.service';
import { WcagService } from './services/wcag.service';
import { SwitchThemeService } from './services/switch-theme.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LocationStrategy, HashLocationStrategy, registerLocaleData} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipeService } from './pipes/pipe.service';
import { GuardService } from './services/security/guard.service';
import { SharedModule } from './shared.module';
import { PipeSharedModule } from './PipeShared.module';
import { ApiService } from './services/api/api.service';
import { HttpService } from './services/api/http.service';
import { GeneralService } from './services/general.service';
import { VisitCounterService } from './services/visit-counter.service';
import { ClientDataService } from './services/client-data.service';
import { FontAwesomeService } from './services/font-awesome.service';
import { SecurityService } from './services/security/security.service';
import { CookiesService } from './services/cookies/cookies.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpdateSecurityService } from './services/security/update.security.service';
import { BrowserModule } from '@angular/platform-browser';
import { GalleryAdvancedViewComponent } from './user/modal/gallery-advanced-view/gallery-advanced-view.component';
import localePl from '@angular/common/locales/pl';

registerLocaleData(localePl);
export function appInit(ConfigFileService: ConfigFileService) { //ładowanie pliku konficuracyjnego
  return () => ConfigFileService.Load();
}

export function appInit2(SecurityService: SecurityService) { //ładowanie pliku konficuracyjnego
  return () => SecurityService.checkCookies();
}





@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    EmptyComponent,
    BigImageViewComponent,
    GalleryImageBigViewComponent,
    PageDisableComponent,
   GalleryAdvancedViewComponent,
    WelcomePanelComponent,
  ],
  entryComponents: [
    WelcomePanelComponent,
    BigImageViewComponent,
    GalleryAdvancedViewComponent,
    ],
  exports:[

  ],
  imports: [
    NgbModule,
    SharedModule,
    PipeSharedModule,
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { // ładowanie pliku ustawień przed starterm strony
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigFileService]
    },
    { // ładowanie pliku ustawień przed starterm strony
      provide: APP_INITIALIZER,
      useFactory: appInit2,
      multi: true,
      deps: [SecurityService]
    },
    ConfigFileService,
    PipeService,
    GuardService,
    SwitchThemeService,
    ApiService,
    HttpService,
    FontAwesomeService,
    SecurityService,
    GeneralService,
    WcagService,
    TemplateGuardService,
    GeneralTemplateService,
    PageDisableGuardService,
    UpdateSecurityService,
    ImagesStorageService,
    CookiesService,
    RouterOutletService,
    VisitCounterService,
    ClientDataService
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
//provide: LocationStrategy,
